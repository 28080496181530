import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor() {
  }


  /**
   * Filter array of objects by according to filter properties. Returns filtered array
   *
   * @param array
   * @param filter
   */
  public filterByObject(array, filter): Array<any> {
    return array.filter(item => {
      const keys = Object.keys(filter);

      if (!filter || !keys.length) {
        return true;
      }

      return keys.reduce((accumulator, key) => {
        if (!accumulator) {
          return false;
        }

        if (!isNullOrUndefined(filter[key]) && filter[key] !== '') {
          return filter[key] === item[key];
        } else {
          return true;
        }
      }, true);
    });
  }

  /**
   * Returns array of unique object values from array of objects by key
   *
   * Example:
   *  uniqueValuesByKey('type', [
   *    {type: 'deposit', amount: 1},
   *    {type: 'withdraw', amount: 2},
   *    {type: 'deposit', amount: 5},
   *  ])
   *
   * Output:
   *  ['deposit', 'withdraw']
   *
   * @param key
   */
  public uniqueValuesByKey(key, array): Array<any> {
    const values = [];

    array.forEach((item) => {
      if (!isNullOrUndefined(item[key]) && !values.includes(item[key])) {
        values.push(item[key]);
      }
    });

    return values;
  }

  /**
   * Returns object of key => value pairs
   *
   * Example:
   *  keyFromValuePairs('code', 'subunits', [
   *    {code: "EUR", subunits: 100},
   *    {code: "USD", subunits: 100},
   *    {code: "BTC", subunits: 100000000},
   *  ])
   *
   * Output:
   *  {
   *    EUR: 100,
   *    USD: 100,
   *    BTC: 100000000
   *  }
   *
   * @param key
   * @param keyValue
   * @param array
   */
  public keyFromValuePairs(key, keyValue, array): any {
    const keyValuePairs = {};

    array.forEach((item) => {
      if (!isNullOrUndefined(item[key]) && !isNullOrUndefined(item[keyValue])) {
        keyValuePairs[item[key]] = item[keyValue];
      }
    });

    return keyValuePairs;
  }

  /**
   * Returns array of objects that `key` value not in usedValues
   *
   * Examle:
   *  excludeUsedByKey('period', ['day', 'month'], [
   *    {period: 'day', value: 1},
   *    {period: 'month', value: 2},
   *    {period: 'year', value: 3},
   *  )
   *
   * Output:
   *  [
   *    {period: 'year', value: 3}
   *  ]
   *
   * @param key
   * @param usedValues
   * @param array
   */
  public excludeUsedByKey(key, usedValues, array): Array<any> {
    return array.filter(item => usedValues.indexOf(item[key]) === -1);
  }

  /**
   * Returns array of objects that `key` value in usedValues
   *
   * Example:
   *  excludeUsedByKey('period', ['day', 'month'], [
   *    {period: 'day', value: 1},
   *    {period: 'month', value: 2},
   *    {period: 'year', value: 3},
   *  )
   *
   * Output:
   *  [
   *    {period: 'day', value: 1},
   *    {period: 'year', value: 3},
   *  ]
   *
   * @param key
   * @param usedValues
   * @param array
   */
  public includeUsedByKey(key, usedValues, array): Array<any> {
    return array.filter(item => usedValues.indexOf(item[key]) !== -1);
  }

  /**
   * Returns object in which key is value of 'key' attribute and value is current item
   *
   * Example:
   *  valueAsKeyObject('slug', [
   *    {game: 'Origami', externalId: 14, slug: 'origami'},
   *    {game: 'Big Bad Wolf', externalId: 17, slug: 'big-bad-wolf'},
   *    {game: 'Vikings', externalId: 9, slug: 'vikings'},
   *  );
   *
   * Output:
   *  {
   *    origami: {game: 'Origami', externalId: 14, slug: 'origami'},
   *    big-bad-wolf: {game: 'Big Bad Wolf', externalId: 17, slug: 'big-bad-wolf'},
   *    vikings: {game: 'Vikings', externalId: 9, slug: 'vikings'}
   *  }
   *
   * @param key
   * @param array
   */
  public valueAsKeyObject(key, array): any {
    const result = {};

    array.map(item => {
      result[item[key]] = item;
    });

    return result;
  }

  /**
   * Returns array sorted by order Array and provided field
   *
   * Example:
   *  sortByArrayOrder([5, 4, 7], 'id', [
   *    {id: 4, name: 'Big bot crew'},
   *    {id: 5, name: 'Bird on a wire},
   *    {id: 7, name: 'Big bad wolf'},
   *  ]);
   *
   * Output:
   *  [
   *    {id: 5, name: 'Bird on a wire},
   *    {id: 4, name: 'Big bot crew'},
   *    {id: 7, name: 'Big bad wolf'},
   *  ]
   *
   * @param order
   * @param field
   * @param list
   */
  public sortByArrayOrder(order: Array<any>, field: string, list: Array<any>): Array<any> {
    const result = [];

    order.forEach(item => {
      list.forEach(listItem => {
        if (listItem[field] === item) {
          result.push(listItem);
          return;
        }
      });
    });

    return result;
  }


  /**
   * Returns array sorted by provided property
   *
   * Example:
   *  sortAlphabeticallyByProp([
   *    {name: 'Alex', age: 33},
   *    {name: 'Carl', age: 33},
   *    {name: 'Bob', age: 33}
   *  ], 'name');
   *
   * Output:
   *  [
   *    {name: 'Alex', age: 33},
   *    {name: 'Bob', age: 33},
   *    {name: 'Carl', age: 33},
   *  ]
   *
   * @param array
   * @param field
   */
  public sortByProp(array: Array<any>, field: string): Array<any> {
    return array.sort((a, b) => {
      let prev = a[field];
      let next = b[field];

      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        prev = a[field].toLowerCase();
        next = b[field].toLowerCase();
      }

      if (prev < next) {
        return -1;
      } else if (prev > next) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public orderBySegment(input, order, key) {
    return input.sort((a, b) => {
      const aValue = a[key], bValue = b[key];
      const aIndex = order.indexOf(aValue), bIndex = order.indexOf(bValue);
      if (aIndex === -1)
        return 1;
      else if (bIndex === -1)
        return -1;
      else
        return aIndex - bIndex;
    });
  }
}
