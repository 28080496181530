import { PlatformService } from '../../services/platform.service';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { TabItemComponent } from './tab-item.component';
import { GlobalEventsService } from '../../services/global-events.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    NgTemplateOutlet
],
})
export class TabsComponent implements AfterContentInit, OnDestroy {

  /**
   * Default active first tab
   */
  @Input('defaultActive') defaultActive: boolean = true;

  @ViewChild('selector', {static: true}) protected selector: ElementRef<HTMLElement>;

  @ContentChildren(TabItemComponent) tabs: QueryList<TabItemComponent>;

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _platform: PlatformService,
    private _el: ElementRef<HTMLElement>,
    private _globalEvent: GlobalEventsService
  ) {

  }

  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);
    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      if (this.defaultActive) {
        this.selectTab(this.tabs.first);
      }
      this._globalEvent.resize$.pipe(
        untilDestroyed(this)
      ).subscribe(() => {
        this._resolveSelector();
      });
    }
  }

  ngOnDestroy() {
  }

  selectTab(currentTab: any) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab: TabItemComponent) => {
      tab.active = false;
    });
    // activate the tab the user has clicked on.
    currentTab.active = true;
    this.select.emit(currentTab);
    this._resolveSelector();
  }

  /**
   * Resolve selector position
   */
  private _resolveSelector() {
    if (this._platform.isBrowser) {
      requestAnimationFrame(() => {
        const selector: HTMLElement = this._el.nativeElement.querySelector('.tabs__header .selector');
        const active: HTMLElement = this._el.nativeElement.querySelector('.tabs__header .active');
        if (active) {
          const activeWidth = active.offsetWidth;
          selector.style.left = active.offsetLeft + 'px';
          selector.style.width = activeWidth + 'px';
        }
      });
    }
  }

}
