import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationCenterService} from '../notification-center.service';
import {WebSocketService} from "../../../services/web-socket.service";
import {PlatformService} from '../../../services/platform.service';
import {ISockets} from '../notification-center.interface';
import { TranslatePipe } from '../../../shared/translation/translate.pipe';
import { NotificationCenterItemComponent } from '../notification-center-item/notification-center-item.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss'],
    standalone: true,
    imports: [NgClass, NotificationCenterItemComponent, TranslatePipe]
})

export class NotificationCenterComponent implements OnInit, OnDestroy {

  @Input('lists') lists: ISockets[];

  constructor(
    public notification: NotificationCenterService,
    public ws: WebSocketService,
    private _platform: PlatformService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }
}
