import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { merge} from 'rxjs';
import { NgClass } from '@angular/common';
import { FormControlsModule } from '../../../../../../../core/modules/form-controls/form-controls.module';
import { InputNumberDirective } from 'ngx-unificator/directives';
import {
  CurrencySelectorStandaloneComponent
} from '../../../../../../../core/shared/standalone/currency-selector-standalone/currency-selector-standalone.component';
import { TranslatePipe } from '../../../../../../../core/shared/translation/translate.pipe';
import { FormsErrorHandlerService } from '../../../../../../../core/services/forms-error-handler.service';
import { UserService } from '../../../../../../../core/services/user/user.service';
import { UserPaymentSsV2Service } from '../../../../../../../core/services/user/user-payment-ss-v2.service';
import { LotteryService } from '../../../../../../../core/services/lottery.service';

@UntilDestroy()
@Component({
  selector: 'app-amount-selector',
  templateUrl: './amount-selector.component.html',
  styleUrls: ['./amount-selector.component.scss'],
  standalone: true,
  imports: [NgClass, FormControlsModule, FormsModule, InputNumberDirective, ReactiveFormsModule, CurrencySelectorStandaloneComponent, TranslatePipe]
})
export class AmountSelectorComponent implements OnInit, OnDestroy {

  @Output() amountValue: EventEmitter<number | string> = new EventEmitter<number | string>();

  /**
   * List of predefined amounts
   */
  @Input('valueList') valueList: Array<number>;

  /**
   * Amount form control
   */
  @Input('control') control: AbstractControl = new UntypedFormControl();

  /**
   * Min value
   */
  @Input('min') min: number;

  /**
   * Max value
   */
  @Input('max') max: number;

  /**
   * Action Type
   */
  @Input('action') action: string;

  /**
   * List of errors for current control
   */
  errors: Array<string> = [];

  /**
   * For correct select working
   */
  currencyControl: UntypedFormControl = new UntypedFormControl(null);

  constructor(
    private _formErrors: FormsErrorHandlerService,
    public user: UserService,
    private _payment: UserPaymentSsV2Service,
    private _cd: ChangeDetectorRef,
    private _lottery: LotteryService,

  ) { }

  ngOnInit() {
    if (!this.valueList) {
      throw new Error('You should provide \'valueList\' for Amount selector component');
    }
    this._resolveValidators();
    this._inputChangeHandle();
    if (this.control?.value) {
      this._lottery.setDepositAmount(this.control?.value)
    }
  }

  ngOnDestroy(): void {
  }

  /**
   * Set min/max validators
   *
   */
  private _resolveValidators() {
    const validators = [Validators.required];
    if (this.min) {
      validators.push(Validators.min(this.min));
    }
    if (this.max) {
      validators.push(Validators.max(this.max));
    }
    this.control.setValidators(validators);
  }

  /**
   * Select amount by clicking on button
   *
   * @param value
   */
  selectAmount(value: number) {
    this.control.markAsTouched();
    this.control.setValue(value);
    this._cd.detectChanges();
  }

  /**
   * Handle form control change
   *
   * @private
   */
  private _inputChangeHandle() {
    this.errors = this._formErrors.errors(this.control);
    merge(
      this.control.statusChanges,
      this.control.valueChanges
    ).pipe(
      untilDestroyed(this),
      tap((value) => {

        if (isFinite(value)) {
          this._lottery.setDepositAmount(Number(value));
        }
        this.amountValue.next(this.control.value);
        this.errors = this._formErrors.errors(this.control);
        this._cd.detectChanges();
      })
    ).subscribe();
  }

  public onBlur(e) {
    e.target.blur();
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    if (!$event || $event === this.user.currentCurrency.currency) {
      return;
    }
    this.user.changeCurrencyAcc($event);
  }
}
