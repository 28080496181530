<app-select [formControl]="currencyControl" class="select" (change)="changeAccountCurrency($event)">
  <app-select-header class="select__header">{{ selectedCurrency || user?.currentCurrency?.currency }}</app-select-header>
  <app-select-dropdown class="select__dropdown">
    @for (item of user?.accountListCompatibility; track item) {
      @if (user.currentCurrency?.currency !== item?.currency) {
        <app-select-option class="select__option" [value]="item?.currency">
          {{item?.currency}}
        </app-select-option>
      }
    }
  </app-select-dropdown>
</app-select>
