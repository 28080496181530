@if (offer$ | async; as offer) {
  <div class="offer">
    <div class="offer__text">
      <div class="offer__title" [innerHTML]="offer?.Title | safe: 'html'"></div>
      <img [src]="offer?.ImageRedesignMob" alt="Offer image" class="offer__img mobile">
      <div (click)="onCodeClick($event)" class="offer__description" [innerHTML]="offer?.Description | safe: 'html'"></div>
      <div class="offer__actions">
        <div (click)="onDepositClick()" class="btn btn--filled-primary big">{{ 'btn.deposit' | translate }}</div>
        <div class="offer__details" (click)="detailsClick.next(null)" [ngClass]="{'text-gradient': rtl.isAr}">
          {{ 't.details' | translate }}
          <div class="offer__details_chevron"></div>
        </div>
      </div>
    </div>
    <img [src]="offer?.ImageRedesign" alt="Offer image" class="offer__img desktop">
  </div>
}
