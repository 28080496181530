import { Injectable } from '@angular/core';
import { register, Hooks } from 'register-service-worker';
import { PlatformService } from './platform.service';
import {GlobalEventsService} from './global-events.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  private _deferredPrompt = null;
  private _isEnabled = null;

  constructor(
    private _platform: PlatformService,
    private _events: GlobalEventsService
  ) {
    this._beforeInstallPrompt();
  }

  get pwaEnabled() {
    return this._isEnabled;
  }

  public registerServiceWorker(): void {
    if (!this._platform.isBrowser) { return; }
    const serviceWorkerPath = `/sw.js`;
    const options: Hooks = {
      registrationOptions: { scope: './' },
      ready: (registration) => {
        console.log('Service worker is active.');
      },
      registered: (registration) => {
      },
      cached: (registration) => {
      },
      updatefound: (registration) => {
      },
      updated: (registration) => {
      },
      offline: () => {
        console.error('No internet connection found. App is running in offline mode.');
      },
      error: (error) => {
        console.error('Error during service worker registration:', error);
      }
    };
    register(serviceWorkerPath, options);
  }

  private _beforeInstallPrompt() {
    if (this._platform.isBrowser) {
      this._events.beforeinstallprompt$.subscribe((e: any) => {
          e.preventDefault();
          this._deferredPrompt = e;
          this._isEnabled = true;
        }
      );
    }
  }

  /**
   * Install PWA app
   */
  public addAppToHomeScreen() {
    this._deferredPrompt.prompt();
    this._deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this._isEnabled = false;
      } else {
        console.log('User dismissed the A2HS prompt');
        this._isEnabled = true;
      }
    });
  }
}
