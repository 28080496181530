import { Component, ContentChildren, ElementRef, OnInit, QueryList } from '@angular/core';
import { SelectService } from '../select.service';
import { SelectOptionComponent } from '../select-option/select-option.component';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],
    standalone: true
})
export class SelectDropdownComponent implements OnInit {

  @ContentChildren(SelectOptionComponent) options: QueryList<SelectOptionComponent>;

  constructor(
    public select: SelectService,
    public el: ElementRef
  ) { }

  ngOnInit() {
  }

}
