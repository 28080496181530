import { Injectable } from '@angular/core';
import { BasePromo } from '../base-promo';
import { EventType, WelcomePromoModal } from '../base-promo.interface';

@Injectable({
  providedIn: 'root'
})
export class AskgamblersService extends BasePromo {

  eventName = 'askgamblers';

  constructor() {
    super();
  }

  public openHuntModal(type: EventType) {
  }

  public checkActivatedBonuses(data: WelcomePromoModal) {}
}
