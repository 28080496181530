import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {AccordionDirective} from '../../directives/accordion.directive';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {filter, tap} from 'rxjs/operators';
import {LanguageService} from '../../../services/language/language.service';

import { TranslatePipe } from '../../translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';

@UntilDestroy()
@Component({
  selector: 'app-bottom-terms',
  templateUrl: './bottom-terms.component.html',
  styleUrls: ['./bottom-terms.component.scss'],
  standalone: true,
  imports: [
    TranslatePipe,
    AccordionDirective,
    SafePipe
],
})
export class BottomTermsComponent implements AfterViewInit {
  @ViewChild(AccordionDirective, {static: true}) private _accordion: AccordionDirective;

  @Input() terms: any;
  @Input() accordionTitle = 'labl.t&c';
  @Input() closeAfterChangeLang: boolean;

  constructor(
    private _lang: LanguageService,
  ) { }

  ngAfterViewInit() {
    this._lang.langChange$.pipe(
      untilDestroyed(this),
      filter(() => Boolean(this._accordion)),
      tap(() => this._accordion.isOpen ? this._accordion.close() : null)
    ).subscribe();
  }

  public open() {
    this._accordion.open();
  }
}
