import { Pipe, PipeTransform } from '@angular/core';
import { isCryptoAcc } from '../../services/common-data.service';
import { DecimalPipe } from '@angular/common';

/**
 * Resolve correct format for currencies
 */
@Pipe({
    name: 'currencyFormat',
    standalone: true
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(
    private _number: DecimalPipe
  ) {
  }

  transform(value: any, currency: string): any {
    switch (true) {
      case isCryptoAcc(currency) && value !== 0:
        return value;
      default:
        return this._number.transform(value, '0.2');
    }
  }

}
