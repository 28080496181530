"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Payment = void 0;
var Payment;
(function (Payment) {
  var Action;
  (function (Action) {
    Action["Deposit"] = "deposit";
    Action["Cashout"] = "cashout";
  })(Action = Payment.Action || (Payment.Action = {}));
  var MethodType;
  (function (MethodType) {
    MethodType["Cards"] = "cards";
    MethodType["Direct"] = "direct";
    MethodType["Crypto"] = "crypto";
    MethodType["BankTransfer"] = "bank_transfer";
    MethodType["Voucher"] = "voucher";
  })(MethodType = Payment.MethodType || (Payment.MethodType = {}));
  var ComplementaryPaymentMethod;
  (function (ComplementaryPaymentMethod) {
    ComplementaryPaymentMethod["MercuryoCrypto"] = "mercuryo_crypto";
    ComplementaryPaymentMethod["MercuryoFiat"] = "mercuryo_fiat";
  })(ComplementaryPaymentMethod = Payment.ComplementaryPaymentMethod || (Payment.ComplementaryPaymentMethod = {}));
})(Payment = exports.Payment || (exports.Payment = {}));