import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { SsSocketChanel } from './ss-socket-chanel';
import { SsWebSocket } from './ss-web-socket';
import { SsWebSocketsConfig, SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel } from './ss-web-sockets.types';

@Injectable()
export class SsWebSocketsService {

  /**
   * Web sockets config
   */
  private _config: SsWebSocketsConfig;

  /**
   * Chanel connections list
   */
  private _connections: Map<SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel, SsSocketChanel> = new Map();

  /**
   * Emits when connection ready to use
   */
  private _connectionReady: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Socket connection
   */
  private _connection: SsWebSocket;

  /**
   * Returns chanel connection
   *
   * @param chanel
   */
  public connect(chanel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel): Observable<any> {
    if (this._connections.has(chanel)) {
      return this._connections.get(chanel).message;
    }

    return this._connectionReady.pipe(
      first(),
      tap(() => this._connections.set(chanel, new SsSocketChanel(chanel, this._connection))),
      switchMap(() => this._connections.get(chanel).message)
    );
  }

  /**
   * Init lib
   *
   * @private
   */
  public init(config: SsWebSocketsConfig) {
    if (!this._connection) {
      this._config = config;
      this._connection = new SsWebSocket(this._config);
      this._connectionReady.next(true);
    }
  }

  /**
   * Disconnect socket
   */
  public disconnect() {
    this._connections.forEach(ws => ws['_connection'].close());
    this._connections = new Map<SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel, SsSocketChanel>();
    this._connection = null;
  }
}
