import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../translation/translation.service';

@Pipe({
    name: 'processingTime',
    standalone: true
})
export class ProcessingTimePipe implements PipeTransform {

  constructor(
    private _translation: TranslationService
  ) {}


  transform(value: any): string {
    if (value?.type === 'instant') {
      return this._translation.translate('t.instant');
    }
    if (value && typeof value === 'object') {
      return `${value.minDays}-${value.maxDays} ` + this._translation.translate('labl.days');
    }

    return value;
  }

}
