import { environment } from 'src/environments/environment';

export function createABTestDebugMessage(storage: any, abTestName: string, message: string) {
  if (environment.production && !storage?.get('AB_TEST_DBUG')) {
    return;
  }
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(2, '0').slice(0, 2);
  console.log(
    '%c%s%c%s%c%s%c%s',
    'color: #da70d6; background: #212836;',
    `\n ABTest: ${abTestName} `,
    'color: yellow; background: #212836;',
    `\n Time: ${hours}:${minutes}:${seconds}.${milliseconds} `,
    'color: #71b1fe; background: #212836;',
    '\n Message: ',
    'color: #23c668; background: #212836;',
    `${message} `,
  );
}
