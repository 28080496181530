import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { UserService } from './user/user.service';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Payment } from 'payments-lib-types';

export const STREAMER = '1152';

export interface TransactionsList {
  type?: string;
  statusCode?: number;
  message?: string;
  data: {
    list: TransactionListItem[];
  };
}

export interface TransactionListItem {
  transactionId: number;
  managerStatus: string;
}

export interface PostRequest {
  transactionId: number;
  type: string;
  amount: number;
  currency: string;
  paymentSystem: string;
}

@Injectable({
  providedIn: 'root'
})
export class CmsTransactionsService {

  /**
   * State of user by streamer
   * @private
   */
  private _streamer: boolean;

  /**
   * Custom global headers
   * @private
   */
  private _customHeaders: any;

  constructor(
    private _cmsApi: CmsApiService,
    private _user: UserService
  ) {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      tap(() => {
        this._streamer = this._user.info.statuses.some((status) => status.id === STREAMER);
        this._customHeaders = {
          'Content-Type': 'application/json',
          'UID': this._user.info.id.toString()
        };
      })
    ).subscribe();
  }

  /**
   * Access to streamer state
   */
  get isStreamer(): boolean {
    return this._streamer;
  }

  /**
   * Find and change transactions status according transaction CMS statuses
   * @param transactionsListCMS
   * @param transactionsList
   */
  mappedCashoutTransactions(transactionsListCMS: TransactionListItem[], transactionsList: any[]) {

    const map = new Map();

    if (transactionsListCMS) {
      transactionsListCMS.forEach(({ transactionId, managerStatus }) => map.set(transactionId, managerStatus));
      return transactionsList.map((transaction) => {
         return map.has(transaction.id) ?
           { ...transaction, status: map.get(transaction.id)} :
           { ...transaction };
        }
      );
    } else {
      return transactionsList;
    }

  }

  /**
   * Post cashout transaction to CMS
   * @param response
   * @param paymentAction
   */
  postTransaction(response: any, paymentAction: Payment.Action) {

    const requestData: PostRequest = {
      transactionId: response.payment.id,
      type: response.payment.action,
      amount: response.payment.amount_cents / 100,
      currency: response.payment.currency,
      paymentSystem: response.payment.payment_system
    };

    if (paymentAction === Payment.Action.Cashout && this.isStreamer) {
      this._cmsApi.postTransactionIntoCMS(requestData, {
        headers: this._customHeaders
      }).subscribe();
    }

  }

  /**
   * Get transactions from CMS
   * @private
   */
  getTransactions(): Observable<TransactionsList> {
    if (this.isStreamer) {
     return this._cmsApi.transactionListFromCMS(null, {
        headers: this._customHeaders
      });
    }
  }
}
