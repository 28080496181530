import { Injectable } from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, tap} from 'rxjs/operators';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';
import { Payment, PaymentsMethod } from '../../vendor/ss-payments-v2/ss-payment-types';
import {UserService} from './user.service';
import {GeoApifyService} from '../geoapify.service';
import {FormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserPaymentSsV2Service {

  /**
   * Protected access to payment method list source
   */
  public paymentMethodsList$: ReplaySubject<PaymentsMethod[]> = new ReplaySubject(1);

  public currentPaymentAction: Payment.Action = Payment.Action.Deposit;


  public citiesSuggestion: Array<any> = [];
  public addressesSuggestion: Array<any> = [];
  public zipCodeSuggestion: Array<any> = [];
  public isSecondDepositStep = false;
  public isDepositAction: boolean = false;


  /**
   * If true - hide player fields in payment form (It is used when there was an error during the deposit or cashout, but the player fields was saved)
   * @private
   */
  private _hidePlayerFieldsInPaymentForm: boolean;

  constructor(
    private _ssPayments: SsPaymentsV2Service,
    private _user: UserService,
  ) {
  }

  get hidePlayerFieldsInPaymentForm() { return this._hidePlayerFieldsInPaymentForm;}
  set hidePlayerFieldsInPaymentForm(value) { this._hidePlayerFieldsInPaymentForm = value}


  /**
   * Update current payment methods list
   *
   * @param currency
   * @public
   */
  public updatePaymentMethodsList(currency: string): void {
    if (!currency) {
      this.paymentMethodsList$.next(null);
      return;
    }

    this._ssPayments.fetchMethods(currency, this.currentPaymentAction).pipe(
      tap(list => this.paymentMethodsList$.next(list))
    ).subscribe();
  }

  public listenSuggestion(form: FormGroup, geoApify: GeoApifyService) {
    const subscribeToFieldChanges = (fieldName: string, type: any, filterFn: any, suggestionHandler: any, debounce: number = 300) => {
      const field = form?.get(fieldName);
      if (field) {
        field.valueChanges.pipe(
          untilDestroyed(this),
          debounceTime(debounce),
          distinctUntilChanged(),
          filter(value => value && value.length >= 3),
          switchMap((value: string) =>
            geoApify.getPredictions({
              type,
              text: value,
              filter: `countrycode:${this._user?.info?.country?.toLowerCase() || form?.get('country')?.value?.toLowerCase() || ''}`,
            })
          ),
          map((suggestions: any) => filterFn(suggestions)),
          tap(suggestions => suggestionHandler(suggestions))
        ).subscribe();
      }
    };
    /**
     * Handle city field
     */
    if (form?.get('city')) {
      subscribeToFieldChanges('city', 'city', geoApify.filterCities.bind(geoApify), suggestions => {
        this.citiesSuggestion = suggestions;
      });
    }

    /**
     * Handle address field
     */
    if (form?.get('address')) {
      subscribeToFieldChanges('address', 'street', geoApify.filterAddress.bind(geoApify), suggestions => {
        this.addressesSuggestion = suggestions;
      });
    }

    if (form?.get('postal_code')) {
      form.get('postal_code').valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        pairwise(),
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value[1] && Math.abs(value[1]?.length - value[0]?.length) < 2),
        switchMap(value =>
          geoApify.getPredictions({
            type: 'postcode',
            text: value[1],
            filter: `countrycode:${this._user?.info?.country?.toLowerCase() || form?.get('country')?.value?.toLowerCase() || ''}`,
          })
        ),
        map(suggestions => geoApify.filterAddress(suggestions)),
        tap(suggestions => {
          this.zipCodeSuggestion = suggestions;
        })
      ).subscribe();
    }
  }

  /**
   * Select suggested value
   * @param suggestion
   * @param input
   */
  public selectSuggestion(form: FormGroup, suggestion: any,  input: string) {
    switch (input) {
      case 'city':
        if (form?.get('address').value) {
          form?.get('address').setValue(null);
          form?.get('address').markAsUntouched();
        }
        form?.get('city').setValue(suggestion.city, { emitEvent: false });
        form?.get('postal_code').setValue(suggestion.postcode, { emitEvent: false });
        this.citiesSuggestion = [];
        break;
      case 'address':
        form?.get('address').setValue(suggestion.street, { emitEvent: false });
        if (suggestion.postcode) {
          form?.get('postal_code').setValue(suggestion.postcode, { emitEvent: true });
        }
        this.addressesSuggestion = [];
        break;
      case 'postal_code':
        form?.get('postal_code').setValue(suggestion.postcode, { emitEvent: false });
        this.zipCodeSuggestion = [];
        break;
    }
  }

}
