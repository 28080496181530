import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ITournament } from '../../tournaments/tournaments.interface';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { BasePromo } from '../base-promo';
import { BonusStage } from '../../../core/services/user/data/user-bonuses.data';
import { HalloweenPromoPage } from './halloween.interface';
import { BonusesStatuses } from '../../../core/services/groups.service';
import { GameCategory } from '../../../core/services/games/game-category';
import { EventType, WelcomePromoModal } from '../base-promo.interface';

export const PROMO_KEYS = {
  BONUS_WHEEL_FS: 'HALLOWEEN',
};

export let WHEEL_BONUSES: { prize: number, image: string }[] = [];

/**
 * Enum defining different actions related to Easter promotion
 */
export enum HalloweenAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
  GAMES = 'games',
  HUNT = 'hunt'
}

@Injectable({
  providedIn: 'root',
})
export class HalloweenService extends BasePromo {


  /**
   * Configuration for different actions and their corresponding routes
   */
  private _actionRoutesConfig = {
    [HalloweenAction.GET_BONUS]: {
      key: null,
      url: '/profile/account',
      authUrl: ['profile', 'account'],
    },
    [HalloweenAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [HalloweenAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [HalloweenAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/account/deposit',
      authUrl: ['account', 'deposit'],
    },
    [HalloweenAction.PROMO_AUTH]: {
      key: 'promo-auth',
      url: '/promo/halloween',
      authUrl: null,
    },
    [HalloweenAction.GAMES]: {
      key: null,
      url: '/games/halloween-games',
      authUrl: ['games', 'halloween-games'],
    },
    [HalloweenAction.HUNT]: {
      key: null,
      url: '/profile/account?tab=missions',
      authUrl: ['profile', 'account'],
    },
  };

  public eventName = 'halloween';

  /**
   Observable representing the Easter promotion data
   */
  public promo$: Observable<HalloweenPromoPage> = this._getPromo$();

  /**
   * Observable representing current tournaments related to Easter
   */
  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  /**
   * User claimed all welcome bonuses
   */
  public isUserCanClaimAllBonuses: boolean;

  /**
   * User already activate today lootbox
   */
  public isUserAlreadyActivateBox: boolean;

  /**
   * Is promo loaded
   */
  public isLoadedPromo: boolean;

  /**
   * Current fs prize
   */
  public currentPrize: any;

  public currentWelcomeBonus: { cash: any, fs: any; lootbox: any };

  /**
   * Lootbox promo
   */
  public lootboxPromoBonus: any;

  public LAST_BONUS_ID = 'last-bonus';

  public LAST_WELCOME_BONUS_SPINNED = '--last-welcome-bonus-spinned';

  public lastWelcomeId: string;

  public secretPrizeDegree = 5760;

  public isWelcomePrize: boolean;

  constructor() {
    super();
  }

  get isWelcomeBonusExist(): boolean {
    return Boolean(this.currentWelcomeBonus.fs) ||
      Boolean(this.currentWelcomeBonus.cash) ||
      Boolean(this.currentWelcomeBonus.lootbox);
  }

  get isUserCatchHuntGroup(): boolean {
    return this.huntModalData && this.group.isExistGroup(this.huntModalData?.BonusGroupCatch);
  }

  public async openHuntModal(type: EventType) {
    const component = await import('../../../core/modal-v2/components/lazy/halloween-hunt-modal/halloween-hunt-modal.component');
    await this.modals.openLazy(component?.HalloweenHuntModalComponent, {
      data: {
        size: this.eventList.length,
        data: this.huntModalData,
      },
      template: 'CLEAR',
    });
  }

  public checkActivatedBonuses(data: WelcomePromoModal): Observable<any> {
    return this.bonuses.halloweenActivatedBonusesFs$.pipe(
      map((activatedBonusesFs) => {
        return !Boolean(activatedBonusesFs.length);
      }),
      first(),
      tap(async (isShouldOpened) => {
        if (isShouldOpened) {
          const { HalloweenWelcomeModalComponent } = await import(
            '../../../core/modal-v2/components/lazy/promo-modal/halloween-welcome-modal/halloween-welcome-modal.component',
            );
          await this.modals.openLazy(HalloweenWelcomeModalComponent, { data, template: 'CLEAR' });
          this.cookie.set('welcome-modal-promo', 'true', 1, '/');
        }
      }),
    );
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory$(): Observable<any> {
    return this.cmsApi.gameList({ category: GameCategory.HALLOWEEN_GAMES })
      .pipe(
        map(list => this.array.toChunks(
          this.games.gameListMapper(
            this.array.checkAndCloneArray(list.data.gameList, this.breakpoint.belowSmallDesktop ? 4 : 5)),
          this.breakpoint.belowSmallDesktop ? 4 : 5)),
      );
  }

  public isAbleToSpinLootbox(): boolean {
    return Boolean(this.currentWelcomeBonus.lootbox) ||
      this.isUserCanClaimAllBonuses && this.cookie.check(this.LAST_WELCOME_BONUS_SPINNED) ||
      this.lootboxPromoBonus;
  }

  public handleWelcomeSpin(isLootbox: boolean) {
    this.lastWelcomeId = !isLootbox
      ? this.currentWelcomeBonus.cash?.id || this.currentWelcomeBonus.fs?.id
      : this.currentWelcomeBonus.lootbox?.id;

    this.cookie.set(this.LAST_BONUS_ID, this.lastWelcomeId);

    if (this.isUserCanClaimAllBonuses) {
      this.cookie.set(this.LAST_WELCOME_BONUS_SPINNED, '1');
    }
  }

  /**
   * Function to handle different actions related to Easter promotion
   * @param action The action to perform
   * @param bonusCode Optional bonus code
   * @param successUrl Optional success URL
   */
  public async onAction(
    action: HalloweenAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.href,
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const { key, url, authUrl } = routeConfig;

    switch (action) {
      case HalloweenAction.PROMO_AUTH:
      case HalloweenAction.TOURNAMENTS:
      case HalloweenAction.GO_TO_BONUSES:
      case HalloweenAction.GET_BONUS:
      case HalloweenAction.GAMES:
      case HalloweenAction.HUNT:
        await this._handleActionRoute(key, url, authUrl);
        break;
      case HalloweenAction.GO_TO_DEPOSIT:
        await this._handleActionRoute(
          key,
          url,
          authUrl,
          { successUrl, bonusCode },
          { queryParams: { successUrl, bonusCode } },
        );
        break;
    }
  }

  /**
   * Function to handle routing for different actions
   * @param key The key for the action
   * @param url URL for non-authenticated users
   * @param authUrl URL for authenticated users
   * @param urlData Additional data for URL
   * @param extras Additional navigation options
   */
  private async _handleActionRoute(key: string, url: string, authUrl: string[], urlData?: any, extras?: any) {
    const urlParams = { key, url, authUrl, urlData, extras };
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      await this.user.authUser();
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  /**
   * Private method to retrieve tournaments related to Easter.
   * @returns An Observable emitting tournaments related to Easter.
   */
  private _getTournaments$(): Observable<any> {
    return this.tournaments.list({ with_game_list: 1 }).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments.now.filter(tournament => {
        return tournament.slug.includes('halloween');
      })),
    );
  }

  /**
   * Function to fetch Easter promotion data
   * @returns Observable of EasterPageTypes
   */
  private _getPromo$(): Observable<HalloweenPromoPage> {
    this.isLoadedPromo = false;
    this.isUserCanClaimAllBonuses = this.group.isExistGroup(BonusesStatuses.ALL_WELCOME_OFFERS_USED);
    if (this.isUserCanClaimAllBonuses) {
      this.cookie.delete(this.LAST_BONUS_ID);
    }
    return this.user.auth$.pipe(
      switchMap((auth) => this._fetchPromoData$(auth).pipe(
        map(([promo, lootbox, fs, bonuses]) =>
          this._processPromoData$(promo, lootbox, fs, bonuses)),
        tap((list) => {
          this._updateUserBonusInfo(list);
          setTimeout(() => this.isLoadedPromo = true, 500);
        }),
      )),
    );
  }

  /**
   * Private method to update user bonus information based on the provided list.
   * @param list The list containing user bonus information.
   */
  private _updateUserBonusInfo(list: any): void {
    this.currentWelcomeBonus = {
      cash: this._getFilteredCashWelcomeBonuses(list?.bonuses),
      fs: this._getFilteredFreespinsWelcomeBonuses(list?.fs),
      lootbox: this._getFilteredWelcomeLootbox(list?.lootbox),
    };

    const lastBonus = +this.cookie.get(this.LAST_BONUS_ID);

    if (this._checkLastWelcomeSpin(lastBonus)) {
      this.currentWelcomeBonus = {
        cash: undefined,
        fs: undefined,
        lootbox: undefined,
      };
    }

    this.lootboxPromoBonus = this._getFilteredLootboxBonuses(list?.lootbox);
    this.isUserAlreadyActivateBox = this._checkActivatedTodayLootbox(list?.lootbox);

    WHEEL_BONUSES = list?.Prizes;
  }

  private _checkLastWelcomeSpin(lastBonus) {
    return this.currentWelcomeBonus.fs?.id === lastBonus || this.currentWelcomeBonus.cash?.id === lastBonus
      || this.currentWelcomeBonus.lootbox?.id === lastBonus
      || (this.isUserCanClaimAllBonuses && this.cookie.check(this.LAST_WELCOME_BONUS_SPINNED));
  }

  private _getFilteredLootboxBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(lootbox => lootbox.lootboxTitle.trim().toLowerCase().includes('landing') &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredWelcomeLootbox(arrayToCheck: any[]) {
    return arrayToCheck?.filter(lootbox => lootbox.isWelcome &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => (bonus.stage === BonusStage.ISSUED) && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]) {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.HANDLE_BETS && bonus.isWelcome)
      .sort((a, b) => b.created_at - a.created_at)[0];
  }

  private _checkActivatedTodayLootbox(arrayTcCheck: any[]) {
    return arrayTcCheck?.some(l => l.stage === BonusStage.ACTIVATED && l.lootboxTitle.trim().toLowerCase().includes('landing') &&
      this.time.isTodayDate(l.created_at));
  }

  /**
   * Function to process fetched Easter promotion data
   * @param promo Array of EasterPageTypes
   * @param lootbox
   * @param fs Array of free spins
   * @param bonuses
   * @returns Processed Easter promotion data
   */
  private _processPromoData$(promo: HalloweenPromoPage[], lootbox: any[], fs: any[], bonuses: any[]): any {
    return promo?.map(promoItem => ({
      ...promoItem,
      fs,
      lootbox,
      bonuses,
      Prizes: this._convertDataPrizes([...Object.values(promoItem?.Prizes)]),
      HowItWorks: [...Object.values(promoItem?.HowItWorks)],
      HuntHowItWorks: [...Object.values(promoItem?.HuntHowItWorks)],
      unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
      publishAt: this.time?.toLocalDate(promoItem?.publishAt),
    })).map(item => ({
      ...item,
    }))[0];
  }

  /**
   * Function to fetch Halloween promotion data from API
   * @param auth
   */
  private _fetchPromoData$(auth: boolean): Observable<[HalloweenPromoPage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({ slug: this.eventName }),
      auth ? this.lootboxService.loadUserLootBox() : of([]),
      auth ? this.bonuses.freeSpinsList() : of([]),
      auth ? this.bonuses.bonusList() : of([]),
    ]);
  }

  private _convertDataPrizes(data: { prize: string, image: string }[]) {
    return data.map(item => {
      return {
        prize: item.prize === 'secret' ? item.prize : +item.prize,
      };
    });
  }
}
