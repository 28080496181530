import { Directive, ElementRef, Renderer2, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[elementOnLoad]',
    standalone: true
})
export class HtmlElementOnloadDirective implements AfterViewInit {

  @Output() elementLoaded = new EventEmitter<HTMLElement>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.elementLoaded.emit(this.el.nativeElement);
  }

}
