import { Input, Directive, inject } from '@angular/core';
import { TimeService } from '../services/time.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';

@UntilDestroy()
@Directive()
export class BaseTimerComponent  {

  @Input() timeLeft: Date;

  public timeLive: any;

  public time: TimeService = inject(TimeService);

  constructor() {
  }

  public resolveTimer() {
    if (this.timeLeft) {
      this.time.currentDate$()
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.timeLive = this.time.timeDiff(typeof this.timeLeft === 'string' ? new Date(this.timeLeft) : this.timeLeft);
        });
    } else {
      this.timeLive = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        daysLabel: 't.days',
        hoursLabel: 't.hrs',
        minLabel: 't.mins',
        secLabel: 't.secs'
      };
    }
  }

}
