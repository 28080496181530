<div class="form">
  <div class="form__title" [innerHTML]="'t.migration-terms-desc' | translate | safe:'html'"></div>
  <div class="tabs">
    <app-tabs>
      @if (currentTab === Tab.TERMS) {
        <app-tab-item #tabTandC [tabTitle]="'labl.t&c' | translate">
          <div>
            @if (terms$ | async; as terms) {
              <div class="tabs__content">
                <div class="text" [innerHTML]="terms[0]?.Content | safe:'html'"></div>
              </div>
            } @else {
              <app-preloader></app-preloader>
            }
            <ng-template #termsLoading>
              <app-preloader></app-preloader>
            </ng-template>
          </div>
        </app-tab-item>
      }
      @if (currentTab === Tab.POLICY) {
        <app-tab-item #tabPP [tabTitle]="'link.pp' | translate">
          <div>
            @if (policy$ | async; as policy) {
              <div class="tabs__content">
                <div class="text" [innerHTML]="policy[0]?.Content | safe:'html'"></div>
              </div>
            } @else {
              <app-preloader></app-preloader>
            }
            <ng-template #policyLoading>
              <app-preloader></app-preloader>
            </ng-template>
          </div>
        </app-tab-item>
      }
    </app-tabs>
    <!--    <div class="tabs__header">-->
    <!--      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.TERMS}" (click)="currentTab = Tab.TERMS">{{'labl.t&c' | translate}}</div>-->
    <!--      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.POLICY}" (click)="currentTab = Tab.POLICY">{{'link.pp' | translate}}</div>-->
  <!--    </div>-->

  <!--    <div [hidden]="currentTab !== Tab.TERMS">-->
  <!--      <div class="tabs__content" *ngIf="terms$ | async as terms; else termsLoading">-->
  <!--        <div class="text" [innerHTML]="terms[0]?.Content | safe:'html'"></div>-->
<!--      </div>-->

<!--      <ng-template #termsLoading>-->
<!--        <app-preloader></app-preloader>-->
<!--      </ng-template>-->
<!--    </div>-->

<!--    <div [hidden]="currentTab !== Tab.POLICY">-->
<!--      <div class="tabs__content" *ngIf="policy$ | async as policy; else policyLoading">-->
<!--        <div class="text" [innerHTML]="policy[0]?.Content | safe:'html'"></div>-->
<!--      </div>-->

<!--      <ng-template #policyLoading>-->
<!--        <app-preloader></app-preloader>-->
<!--      </ng-template>-->
<!--    </div>-->
</div>

<div class="submit-container">
  @if (!loading) {
    <button class="btn btn--filled-secondary" (click)="decline()">{{'btn.decline' | translate}}</button>
    <button class="btn btn--filled-primary" (click)="accept()">{{'btn.accept' | translate}}</button>
  }
  @if (loading) {
    <app-preloader></app-preloader>
  }
</div>
</div>
