@switch (type) {
  @case (bonusTypes.WELCOME) {
    <ng-container *ngTemplateOutlet="welcomeBonus"></ng-container>
  }
  @case (bonusTypes.DEPOSIT) {
    <ng-container *ngTemplateOutlet="depositBonus"></ng-container>
  }
  @case (bonusTypes.RELOAD) {
    <ng-container *ngTemplateOutlet="reloadBonus"></ng-container>
  }
  @case (bonusTypes.LOOTBOX) {
    <ng-container *ngTemplateOutlet="lootbox"></ng-container>
  }
  @case (bonusTypes.DAILY) {
    <ng-container *ngTemplateOutlet="dailyBonus"></ng-container>
  }
  @case (bonusTypes.CASH) {
    <ng-container *ngTemplateOutlet="cashBonus"></ng-container>
  }
  @case (bonusTypes.FREESPINS) {
    <ng-container *ngTemplateOutlet="freespinsBonus"></ng-container>
  }
  @case (bonusTypes.BLOG) {
    <ng-container *ngTemplateOutlet="blogBonus"></ng-container>
  }
}

<!-- START WELCOME BONUS -->
<ng-template #welcomeBonus>
  <div class="bonus welcome" [ngClass]="{active: bonus?.active, used: bonus?.used}">
    <div class="bonus__info">
      <div class="bonus__info_text">
        <ng-container [ngTemplateOutlet]="label"></ng-container>
        <div class="title">
          <div>
            <span [innerHTML]="bonus?.Title | safe: 'html'"></span>
            @if (!bonus?.active && !bonus?.used) {
              <i class="icon-lock"></i>
            }
          </div>
        </div>
        <div class="prize" [innerHTML]="(bonus?.Description) | safe: 'html'"></div>
        @if (bonus?.SecondDescription) {
          <div class="subprize"
               [innerHTML]="bonus?.SecondDescription | safe: 'html'"></div>
        }
      </div>
      <img [src]="arabic.isAr ? bonus?.ImageArabic : bonus?.Image" [alt]="pageTitle + ' - Spinsamurai casino'">
    </div>
    @if (bonusAmount.length && isPartnerStag) {
      <div class="bonus__info__amount">
        <div class="bonus__info__amount--text">
          {{ 't.choose-your-dep-amount' | translate }}
        </div>
        <app-tabs #tab [defaultActive]="true">
          @for (amount of bonusAmount; track amount) {
            <app-tab-item #amountItems [tabTitle]="amount"
            ></app-tab-item>
          }
        </app-tabs>
      </div>
    }
    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        @if (bonus?.active) {
          <button routerLink="/deposit"
                  class="btn btn--filled-primary"
                  [ngClass]="{'reduce-margin': bonusAmount.length}">{{ (buttonTitle || 't.get-bonus') | translate }}
          </button>
        }
        <div class="details" (click)="showDetailsModal(bonus)">{{ 't.details' | translate }}</div>
      </div>
    }
  </div>
</ng-template>
<!-- END WELCOME BONUS -->

<!-- START Deposit BONUS -->
<ng-template #depositBonus>
  <div class="bonus deposit active">
    <div class="bonus__info">
      <div class="bonus__info_text">
        <span class="bonus__label" [innerHTML]="'labl.available' | translate | safe: 'html'"></span>
        <div class="title">
          <div [innerHTML]="(bonus?.bonus?.title || bonus?.freespins?.title) | safe: 'html'"></div>
        </div>
        <div class="prize">
          @if (bonus?.bonus) {
            {{ bonus?.bonus?.attributes?.bonus_amount_percent }}&#37;
            @if (bonus?.bonus?.attributes?.bonus_amount_max) {
              {{ 't.up-to' | translate }} {{ user.info?.currency_symbol }}&nbsp;{{ bonus?.bonus?.attributes?.bonus_amount_max[user.info?.currency] }}
            }
            @if (bonus?.freespins) {
              <span
              > + {{ bonus?.freespins?.attributes?.freespins_count }} {{ 't.free-spins' | translate }}</span>
            }
          } @else if (bonus?.freespins) {
            {{ bonus?.freespins?.attributes?.freespins_count }} {{ 't.free-spins' | translate }}
          }
        </div>
        @if (bonus?.SecondDescription) {
          <div class="subprize"
               [innerHTML]="bonus?.SecondDescription | safe: 'html'"></div>
        }
      </div>
      <img [src]="bonus?.Image || bonus?.bonus?.imageUrl || bonus?.imgSrc" [alt]="pageTitle + ' - Spinsamurai casino'">
    </div>

    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        <button routerLink="/deposit"
                class="btn btn--filled-primary">{{ (buttonTitle || 't.get-bonus') | translate }}
        </button>
      </div>
    }
  </div>
</ng-template>
<!-- END WELCOME BONUS -->

<!-- START RELOAD BONUS -->
<ng-template #reloadBonus>
  <div class="bonus reload active">
    <div class="bonus__info">
      <div class="bonus__info_text">
        <div class="title">
          <div [innerHTML]="bonus?.Title | safe: 'html'"></div>
          @if (!bonus?.active && bonus?.isFridayBonus) {
            <i class="icon-lock"></i>
          }
        </div>
        <div class="prize" [innerHTML]="bonus?.Description | safe: 'html'"></div>
        @if (bonus?.SecondDescription) {
          <div class="subprize"
               [innerHTML]="bonus?.SecondDescription | safe: 'html'"></div>
        }
        @if (bonus?.games?.length) {
          @if (bonus?.games; as games) {
            <div class="games">{{ 't.on' | translate }}
              @for (game of games; track game; let last = $last) {
                <span (click)="gamesLauncher.openGameByExternalId(game)" class="link link--redesign"
                >{{ game?.name }}{{ last ? '' : ', ' }}</span>
              }
            </div>
          }
        }
      </div>
      <img [src]="bonus?.Image" [alt]="pageTitle + ' - Spinsamurai casino'">
    </div>

    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        @if (user.auth) {
          @if (!bonus.active && !bonus?.activeLoyaltyBonus?.active) {
            @if (bonus?.isFridayBonus) {
              <div class="bonus__actions_locked">
                <i class="icon-lock"></i>
                <span>{{ 't.unlock-bonus-friday' | translate }}</span>
              </div>
            } @else {
              <div class="bonus__actions_locked" (click)="onLockedTextClick($event)">
                <i class="icon-lock"></i>
                <span [innerHTML]="bonus?.unlockMessage | safe: 'html'"></span>
              </div>
            }
          } @else {
            @if (bonus?.isLoyaltyBonus) {
              <!--            <button *ngIf="bonus?.isLoyaltyBonus" class="btn btn&#45;&#45;filled-primary" routerLink="/loyalty-program">{{ 't.choose-your-path' | translate }}</button>-->
              @if (bonus?.activeLoyaltyBonus?.active) {
                <button class="btn btn--filled-primary"
                        (click)="onActivate.next(bonus?.activeLoyaltyBonus)">{{ 'btn.activate' | translate }}
                </button>
              }
            }
            @if (!bonus?.isLoyaltyBonus) {
              <button class="btn btn--filled-primary"
                      (click)="onBonusClick()">{{ 't.get-bonus' | translate }}
              </button>
            }
          }
        } @else {
          @if (bonus?.isLoyaltyBonus) {
            <button class="btn btn--filled-primary"
                    routerLink="/loyalty-program">{{ 't.choose-your-path' | translate }}
            </button>
          }
          @if (!bonus?.isLoyaltyBonus) {
            <button class="btn btn--filled-primary">{{ 't.get-bonus' | translate }}</button>
          }
        }
        <div class="details" (click)="reloadDetailsClick(bonus)">{{ 't.details' | translate }}</div>
      </div>
    }
  </div>
</ng-template>
<!-- END RELOAD BONUS -->

<!-- START BLOG BONUS -->
<ng-template #blogBonus>
  <div class="bonus reload active">
    <div class="bonus__info">
      <div class="bonus__info_text">
        <div class="title">
          <div [innerHTML]="bonus?.BonusTitle | safe: 'html'"></div>
        </div>
        <div class="prize" [innerHTML]="bonus?.BonusDesc | safe: 'html'"></div>
      </div>
      <img [src]="bonus?.BonusImage" [alt]="pageTitle + ' - Spinsamurai casino'">
    </div>

    @if (bonus.BonusButtonName) {
      <div class="bonus__actions">
        @if (user.auth) {
          <button class="btn btn--filled-primary"
                  [routerLink]="bonus.BonusButtonRoute">{{ bonus?.BonusButtonName }}
          </button>
        }
        @if (!user.auth) {
          <button class="btn btn--filled-primary"
                  (click)="user.authUser()">{{ user.authKeyTranslate | translate }}
          </button>
        }
        @if (bonus?.BonusLinkName) {
          <div class="details" [routerLink]="bonus?.BonusLinkRoute">
            {{ bonus?.BonusLinkName }}
          </div>
        }
      </div>
    }
  </div>
</ng-template>
<!-- END BLOG BONUS -->

<!-- START DAILY BONUS -->
<ng-template #dailyBonus>
  @if (bonus) {
    <div class="daily">
      <div class="daily__top">
        <div class="daily__text">
          <div class="daily__top-title" [innerHTML]="bonus?.Title | safe: 'html'"></div>
          <div class="daily__top-desc" [innerHTML]="bonus?.Description | safe: 'html'"></div>
        </div>
        <div class="daily__top-img">
          <img
            [attr.data-src]="arabic.isAr ? '/assets/img/bonuses/page/v2/daily-bonus-arabic.png' : '/assets/img/bonuses/page/v2/daily-bonus.png'"
            [alt]="pageTitle + ' - Spinsamurai casino'">
        </div>
        <button (click)="onBonusClick()" class="btn btn--filled-primary daily__top-btn show-desktop">
          {{ 'btn.lets-go' | translate }}
        </button>
      </div>
      <div class="daily__bottom">
        <div class="daily__bottom-steps">
          <div class="step">
            <img [attr.data-src]="'/assets/img/jackpot/works/1.png'" [alt]="pageTitle + ' - Spinsamurai casino'">
            <span class="step__custom-margin">{{ 't.daily-first-step' | translate }}</span>
          </div>
          <div class="step">
            <img [attr.data-src]="'/assets/img/jackpot/works/2.png'" [alt]="pageTitle + ' - Spinsamurai casino'">
            <span>{{ 't.daily-second-step' | translate }}</span>
          </div>
          <div class="step">
            <img [attr.data-src]="'/assets/img/jackpot/works/3.png'" [alt]="pageTitle + ' - Spinsamurai casino'">
            <span>{{ 't.daily-third-step' | translate }}</span>
          </div>
        </div>
        <button
          (click)="onBonusClick()"
          class="btn btn--filled-primary daily__top-btn show-desktop"
        >
          {{ 'btn.lets-go' | translate }}
        </button>
      </div>
    </div>
  }
</ng-template>
<!-- END DAILY BONUS -->

<!-- START CASH BONUS -->
<ng-template #cashBonus>
  <div class="bonus cash">
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <ng-container [ngTemplateOutlet]="label"></ng-container>
          <div class="bonus__title" [innerHTML]="'t.cash-bonus' | translate | safe: 'html'"></div>
          <div class="bonus__amount">
            {{ bonus?.currency_symbol }} {{ bonus?.amount }}
          </div>
        </div>
        <div class="bonus__top_right">
          <img src="/assets/img/deposit-bonus/test-img/cash.png" [alt]="pageTitle + ' - Spinsamurai casino'">
        </div>
      </div>
      <div class="bonus__bottom">
        <div class="bonus__wager">
          {{ 'labl.amount' | translate }}:
          {{ bonus?.amount_wager_cents / 100 | number:'1.2-2' }} {{ bonus?.currency_symbol }} /
          {{ bonus?.amount_wager_requirements | number:'1.2-2' }} {{ bonus?.currency_symbol }}
          ({{ bonus?.wager | number:'1.2-2' }}%)
        </div>
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__timer"
          >
            <app-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-timer>
          </div>
        } @else {
          <div class="finished">
            {{ 't.finished' | translate }}
            : {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
          </div>
        }
      </div>
    </div>
    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        <button class="btn btn--cancel" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
        @if ((bonus?.stage === BonusStage.ISSUED || bonus?.stage === BonusStage.HANDLE_BETS) && bonus?.activatable) {
          <button
            class="btn btn--filled-primary" (click)="onActivate.next(bonus)">{{ 'btn.activate' | translate }}
          </button>
        }
      </div>
    }
  </div>
</ng-template>
<!-- END CASH BONUS -->

<!-- START FREESPINS BONUS -->
<ng-template #freespinsBonus>
  <div class="bonus freespins">
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <ng-container [ngTemplateOutlet]="label"></ng-container>
          <div class="bonus__title" [innerHTML]="'t.free-spins' | translate | safe: 'html'"></div>
          <div class="bonus__amount" [innerHTML]="bonus?.freespins_total | safe: 'html'"></div>
        </div>
        @if (bonus?.games) {
          @if (bonus?.games?.length) {
            <div class="bonus__top_right">
              <a [routerLink]="['/play', bonus?.games[0]?.slug]">
                <img [src]="bonus?.games[0]?.imgSrc" [alt]="pageTitle + ' - Spinsamurai casino'">
              </a>
            </div>
          }
        }
      </div>
      <div class="bonus__bottom">
        @if (bonus?.games?.length) {
          <div class="bonus__game">
            <span>{{ !arabic.isAr ? '' : ':' }}{{ 'labl.game' | translate }}{{ arabic.isAr ? '' : ':' }}</span>
            @for (game of bonus?.games | slice: 0:10; let last = $last; track game) {
              <span class="bonus__game--link" [routerLink]="['/play', game?.slug]">
                  {{ game?.name }}{{ last ? '' : ', ' }}
                </span>
            }
          </div>
        }
        @if (bonus?.wager) {
          <div class="bonus__wager">{{ 't.wager' | translate }}: {{ bonus?.wager }}x</div>
        }
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__timer"
          >
            <app-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-timer>
          </div>
        } @else {
          <div class="finished">
            {{ 't.finished' | translate }}
            : {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
          </div>
        }
      </div>
    </div>
    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        <button class="btn btn--cancel" (click)="onCancel.next(bonus)">{{ 't.cancel' | translate }}</button>
        @if (bonus?.stage === BonusStage.ISSUED || bonus?.stage === BonusStage.HANDLE_BETS) {
          <button
            class="btn btn--filled-primary" (click)="onActivate.next(bonus)">{{ 'btn.activate' | translate }}
          </button>
        }
        @if (bonus?.games$ | async; as games) {
          @if (bonus?.stage === BonusStage.ACTIVATED && games?.length && games[0]?.slug) {
            <button
              class="btn btn--filled-primary"
              [routerLink]="['/play', games[0]?.slug]">{{ 'btn.play' | translate }}
            </button>
          }
        }
      </div>
    }
  </div>
</ng-template>
<!-- END FREESPINS BONUS -->

<!-- START LOOTBOX BONUS -->
<ng-template #lootbox>
  <div class="bonus lootbox">
    <div class="bonus__info">
      <div class="bonus__top">
        <div class="bonus__top_left">
          <ng-container [ngTemplateOutlet]="label"></ng-container>
          <div class="bonus__title" [innerHTML]="bonus?.title | translate | safe: 'html'"></div>
        </div>
        <div class="bonus__top_right">
          <img src="/assets/img/deposit-bonus/test-img/lootbox.png" [alt]="pageTitle + ' - Spinsamurai casino'">
        </div>
      </div>
      <div class="bonus__bottom">
        @if (bonus?.cardDesc) {
          <div class="bonus__desc" [innerHTML]="bonus?.cardDesc | translate | safe: 'html'"></div>
        }
        @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
          <div class="bonus__timer"
          >
            <app-timer [timeLeft]="bonus?.valid_until || bonus?.activatable_until"></app-timer>
          </div>
        } @else {
          <div class="finished">
            {{ 't.finished' | translate }}
            : {{ (bonus?.valid_until || bonus?.activatable_until) | date: 'd-M-yyyy HH:mm' }}
          </div>
        }
      </div>
    </div>
    @if (!(bonus?.stage === BonusStage.FINISHED || bonus?.stage === BonusStage.EXPIRED)) {
      <div class="bonus__actions">
        <button class="btn btn--cancel" (click)="onCancelLootbox.next(bonus?.id)">{{ 't.cancel' | translate }}</button>
        <button class="btn btn--filled-primary" (click)="onOpenLootbox.next(bonus)">{{ 'btn.open' | translate }}
        </button>
      </div>
    }
  </div>
</ng-template>
<!-- END LOOTBOX BONUS -->

<ng-template #label>
  @if (bonus?.frontend_label) {
    <span class="bonus__label"
          [innerHTML]="bonus?.frontend_label | translate | safe: 'html'"
          [ngClass]="{
      active: bonus?.stage === BonusStage.ACTIVATED || bonus?.stage === BonusStage.HANDLE_BETS,
      expired: bonus?.stage === BonusStage.EXPIRED,
      finished: bonus?.stage === BonusStage.FINISHED,
    }"
    ></span>
  }
</ng-template>

