import { Injectable } from '@angular/core';
import {CmsApiService} from './api/cms-api.service';
import {CmsContentMapperService} from './cms-content-mapper.service';
import { CookieService } from 'ngx-unificator/services';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {ModalService} from '../modal-v2/modal.service';

export enum SYS_NOTIFICATION_TYPES {
  SYSTEM = 'system',
  PERSONAL = 'personal'
}

@Injectable({
  providedIn: 'root'
})
export class SysNotificationsService {

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _cookies: CookieService,
    private _modals: ModalService
  ) { }

  /**
   * Get sys notification by slug
   *
   * @param slug
   */
  public getSysNotificationBySlug(slug: string) {
    return this._api.sysNotificationItem({slug}).pipe(
      map((res) => {
        if (res.data && res.data.item) {
          return res.data.item;
        }
      }),
      catchError(() => {
        return [];
      })
    );
  }

  /**
   * Get sys notification list
   */
  public getSysNotificationList(type: SYS_NOTIFICATION_TYPES) {
    return this._api.sysNotificationList({type}).pipe(
      map(response => this._mapper.mapCmsData((response.data && response.data.list || []), {
        id: 'id',
        slug: 'slug',
        publishAt: 'publishAt.date'
      })),
      catchError(() => of([]))
    );
  }

  /**
   * Checked system notification on page
   *
   * @param sysNotification - system notification data from cms
   * @param pageSlug - slug of page where notification displays, use for cookie name
   */
  public async checkSysNotification(sysNotification, pageSlug: string) {
    if (sysNotification) {
      const cookieKey = `sys-notify-${pageSlug}-${sysNotification.slice(-1)[0].name.toLowerCase().split(' ').join('-')}`;
      if (!this._cookies.get(cookieKey)) { // if we haven't displayed this popup yet
        const notifyData = this._mapper.mapCmsData(sysNotification).slice(-1)[0];
        const component = await import('../../core/modal-v2/components/lazy/sys-notification/sys-notification.component');
        await this._modals.openLazy(component?.SysNotificationComponent, {
          data: notifyData
        });
        const prevCookie = Object.keys(this._cookies.getAll()).find((key) => key.includes(`sys-notify-${pageSlug}`));
        if (prevCookie) {
          this._cookies.delete(prevCookie, '/', (<any> window).location.hostname); // Clear cookies for previous popup for this page
        }
        this._cookies.set(cookieKey, 'true',  999, '/', (<any> window).location.hostname); // Set cookie that we already displayed this popup
      }
    }
  }
}
