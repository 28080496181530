import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { SelectService } from '../select.service';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
    standalone: true
})
export class SelectOptionComponent {

  /**
   * Value for current option
   */
  @Input('value') value: any;

  /**
   * Click handler
   */
  @HostListener('click') onClick() {
    this._select.writeValue(this.value, true);
  }

  constructor(
    public el: ElementRef,
    private _select: SelectService
  ) { }

}
