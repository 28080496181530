import { PaymentsLib, Results, Form, RedirectRoutes, Payment, LibError, Arguments, MethodDepositCryptoAddressField, MethodBannerField } from 'payments-lib-types';
import { CommissionType } from 'payments-lib-types/types/terms-of-service';

export type CustomPaymentsLibConfig = { _isLoaded: boolean };
export type PaymentsLibType = Partial<PaymentsLib & CustomPaymentsLibConfig>;
export type PaymentsMethod = Partial<Omit<Payment.Method, 'brand'> & {
  brand?: string;
  provider?: string;
  currency?: string;
  ourImg?: string;
  type?: string;
  originBrand?: string;
  isBiggerLogo?: boolean;
}>;

export type PaymentsMethodField = Partial<{
    fieldName: string; // Field identifier
    proposedValue: string | null; // Proposed value for input
    translationKey: string | null;
    label: string | null;
    placeholder: string | null;
    type: string;
    href: string | null;
    address: string | null;
    limits?: {
      min: number | null;
      max: number | null;
    };
    options: Form.Option[];
    htmlContent: string;
    identifier: string;
    secondaryFieldName: string;
    secondaryOptionsMapByMainValue: Record<string, Form.Option[]>;
    secondaryTranslationKey: string | null;
    secondaryLabel: string | null;
    secondaryPlaceholder: string | null;
    valueStep: number | null;
    protocols: string[];
    readonly: boolean;
    addressByProtocol: Record<string, MethodDepositCryptoAddressField>;
    bannersByProtocol: Record<string, MethodBannerField[]>;
    custom_label?: any;
    bottomDescription?: any;
    topDescription?: any;
    hint?: any;
    bottomPlaceholder?: any;
    hidePlaceholder?: any;
}>;

export type Commissions =
  | {
  type: CommissionType.Percent;
  percent: number; // Deposit percent commission
}
  | {
  type: CommissionType.RangePercent;
  lowerBoundPercent: number; // Deposit lower bound commission percent
  upperBoundPercent: number; // Deposit upper bound commission percent
}
  | {
  type: CommissionType.Fixed;
  cents: number; // Deposit fixed commission in cents
}
  | { type: CommissionType.None } | any;

export type SavedProfile = {
  id: string;
  title: string;
  isRemoveAvailable: boolean;
};

export enum CustomPaymentType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
  BUY_CRYPTO = 'buy-crypto'
}

export enum FastasiaAbTestLogo {
  VERSION_PHP_B = '/assets/svg/payment-methods-new/fastasia_php_b.svg',
  VERSION_VND_A = '/assets/svg/payment-methods-new/fastasia_vnd_a.svg',
  VERSION_VND_B = '/assets/svg/payment-methods-new/fastasia_vnd_b.svg',
  VERSION_THB = '/assets/svg/payment-methods-new/fastasia_thb.svg'
}


export { Results, RedirectRoutes, LibError, Payment, Arguments, Form };
