import {Component, Input, OnInit} from '@angular/core';
import {NotificationCenterService} from '../notification-center.service';
import {GamesService} from '../../../services/games/games.service';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LanguageService} from '../../../services/language/language.service';
import {SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel} from '../../../vendor/ss-web-sockets/ss-web-sockets.types';
import {ISockets} from '../notification-center.interface';
import { SafePipe } from '../../../shared/pipes/safe.pipe';
import { TranslatePipe } from '../../../shared/translation/translate.pipe';
import { SetImgAltDirective } from '../../../shared/directives/set-img-alt.directive';


@Component({
    selector: 'app-notification-center-item',
    templateUrl: './notification-center-item.component.html',
    styleUrls: ['./notification-center-item.component.scss'],
    standalone: true,
    imports: [SetImgAltDirective, TranslatePipe, SafePipe]
})
export class NotificationCenterItemComponent implements OnInit {

  @Input() notify: ISockets;

  constructor(
    public notification: NotificationCenterService,
    private _games: GamesService,
    private _router: Router,
    private _language: LanguageService,
  ) {
  }

  get Winners() { return SsWebSocketsPublicChanel.LATEST_WINNERS }
  get FreeSpins() { return SsWebSocketsPrivateChanel.FREESPINS_CHANGED }
  get Bonuses() { return SsWebSocketsPrivateChanel.BONUSES_CHANGED }
  get CompPoints() { return SsWebSocketsPrivateChanel.COMP_POINTS }
  get Groups() { return SsWebSocketsPrivateChanel.GROUPS_UPDATES }
  get Payments() { return SsWebSocketsPrivateChanel.PAYMENTS_CHANGED }
  get Lootboxes() { return SsWebSocketsPrivateChanel.LOOTBOXES_CHANGED }
  get Tournaments() { return SsWebSocketsPrivateChanel.TOURNAMENTS_STATUSES }
  get GameLimits() { return SsWebSocketsPrivateChanel.GAME_LIMITS }

  ngOnInit() {
  }

  public openGame(gameUrl: string) {
    this._games.getGameByExternalId(gameUrl).pipe(
      tap(game => game ? this._router.navigate(['/', this._language.current, 'play', game.slug]) : null)
    ).subscribe();
  }

  handleRedirect(link: string) {
    if (link) {
      this._router.navigateByUrl(link);
    }
    this.notification.close();
  }
}
