
import { Pipe, PipeTransform } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CmsApiService } from '../../services/api/cms-api.service';

@Pipe({
    name: 'generateQrBase64',
    standalone: true,
})
export class GenerateQrBase64Pipe implements PipeTransform {

  private cachedData: Observable<string>;
  private cachedUrl = '';

  constructor(
    private _api: CmsApiService,
  ) {
  }

  transform(value: string): Observable<string> {
    if (this.cachedUrl === value) {
      return this.cachedData;
    }

    this.cachedUrl = value;
    this.cachedData = this._api.generateQr({ text: value }).pipe(
      map(response => {
        return response?.data?.qr;
      }),
      catchError(error => {
        return of(`Error: ${ error.message }`);
      }),
    );

    return this.cachedData;
  }

}
