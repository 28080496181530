<div>
  <div class="container">
    <div class="header-blog">
      <h2 class="header-blog__title">{{ 't.blog' | translate }}</h2>
    </div>
  </div>
  <app-tabs (select)="onSelectTab($event)" [defaultActive]="false" #tab>
    <app-tab-item #news [id]="'news'" [tabTitle]="'t.blog-news' | translate"></app-tab-item>
    <app-tab-item #promotions [id]="'promotions'" [tabTitle]="'t.blog-promotions' | translate"></app-tab-item>
  </app-tabs>

  <div class="articles-grid">
    @for (list$ of lists; track list$) {
      @if (list$ | async; as list) {
        @if (list?.list?.length) {
          @for (article of list?.list; track article) {
            <div class="articles-grid__item">
              @if (user.auth) {
                <app-blog-article-preview [article]="article"></app-blog-article-preview>
              } @else {
                @if (!article?.OnlyAuth) {
                  <app-blog-article-preview [article]="article"></app-blog-article-preview>
                }
              }
            </div>
          }
        } @else {
          <div class="no-results">{{'t.no-result' | translate}}</div>
        }
      } @else {
        @for (article of [1,2,3,4,5]; track article) {
          <div class="articles-grid__item">
            <app-blog-article-preview></app-blog-article-preview>
          </div>
        }
      }
    }
  </div>


  @if (loadMoreVisible) {
    <div class="actions">
      <button class="btn btn--filled-primary big" (click)="updateOffset()">{{ 't.load-more' | translate }}</button>
    </div>
  }
</div>
