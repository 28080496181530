<div class="popup">
  <div class="popup__title">{{'t.game-not-available' | translate}}</div>
  <div class="popup__text">
    @if (!modal.options?.data?.currencyList) {
      {{'t.game-not-found' | translate}}
    }

    @if (modal.options?.data?.currencyList) {
      @if (!isSupported) {
        <div class="popup__text-block">{{'t.game-not-support-currency' | translate}} ({{ user?.info?.currency }})</div>
      } @else {
        <div class="popup__text-block">{{'t.play-with-currency' | translate}} ({{ user?.info?.currency }})</div>
      }
      @if (!isSupported) {
        <div class="popup__text-block">{{'t.supports-only' | translate}}:
          <br>
            @for (currency of modal.options?.data?.currencyList; track currency; let i = $index) {
              <strong>@if (i !== 0) {
                <span>,</span>
              } {{ currency }}</strong>
            }
          </div>
        }
        <div class="popup__text-block">
          {{'t.choose-available-currency' | translate}}:
          <app-select
            [formControl]="currencyControl"
            (change)="changeAccountCurrency($event)"
            class="select"
            >
            <app-select-header class="select__header">
              {{user.currentCurrency?.currency}}
            </app-select-header>
            <app-select-dropdown class="select__dropdown">
              @for (currency of modal.options?.data?.currencyList; track currency) {
                <app-select-option class="select__option" [value]="currency">
                  {{currency}}
                </app-select-option>
              }
            </app-select-dropdown>
          </app-select>
        </div>
      }
    </div>
    <div class="popup__actions">
      @if (!isSupported) {
        <button class="btn big btn--primary" (click)="onOkClick()">Ok</button>
      }
      @if (isSupported) {
        <button class="btn big btn--primary" (click)="onPlayClick()">{{'btn.play-n' | translate}}</button>
      }
    </div>
  </div>
