"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentMethodBrand = exports.PaymentMethodProvider = void 0;
var PaymentMethodProvider;
(function (PaymentMethodProvider) {
  PaymentMethodProvider["Accentpay"] = "accentpay";
  PaymentMethodProvider["Apco"] = "apco";
  PaymentMethodProvider["AllSecure"] = "allsecure";
  PaymentMethodProvider["AppsNMobile"] = "appsnmobile";
  PaymentMethodProvider["AstropayCard"] = "astropay_card";
  PaymentMethodProvider["AstropayDirect"] = "astropay_direct";
  PaymentMethodProvider["BankTransfer"] = "bank_transfer";
  PaymentMethodProvider["BitcoinPayment"] = "bitcoin_payment";
  PaymentMethodProvider["CardPay"] = "card_pay";
  PaymentMethodProvider["Certus"] = "certus";
  PaymentMethodProvider["Coinspaid"] = "coinspaid";
  PaymentMethodProvider["CoinspaidPlugNPay"] = "coinspaid_plug_and_pay";
  PaymentMethodProvider["Devcode"] = "devcode";
  PaymentMethodProvider["DevcodePopup"] = "devcode_popup";
  PaymentMethodProvider["Finteqhub"] = "finteqhub";
  PaymentMethodProvider["FinteqhubSeamless"] = "finteqhub_seamless";
  PaymentMethodProvider["FlutterWave"] = "flutterwave";
  PaymentMethodProvider["Hipay"] = "hipay";
  PaymentMethodProvider["Ikajo"] = "ikajo";
  PaymentMethodProvider["Mbp"] = "mbp";
  PaymentMethodProvider["Ogone"] = "ogone";
  PaymentMethodProvider["PayNPlay"] = "pay_n_play";
  PaymentMethodProvider["Payhubz"] = "payhubz";
  PaymentMethodProvider["PaymentCenter"] = "payment_center";
  PaymentMethodProvider["Payop"] = "payop";
  PaymentMethodProvider["PayStack"] = "paystack";
  PaymentMethodProvider["Paysafecard"] = "paysafecard";
  PaymentMethodProvider["Payzen"] = "payzen";
  PaymentMethodProvider["Qaicash"] = "qaicash";
  PaymentMethodProvider["Skrill"] = "skrill";
  PaymentMethodProvider["Trustly"] = "trustly";
  PaymentMethodProvider["Utorg"] = "utorg";
  PaymentMethodProvider["VivaPayments"] = "vivapayments";
  PaymentMethodProvider["Wirecard"] = "wirecard";
  PaymentMethodProvider["Zotapay"] = "zotapay";
  PaymentMethodProvider["Mercuryo"] = "mercuryo";
})(PaymentMethodProvider = exports.PaymentMethodProvider || (exports.PaymentMethodProvider = {}));
var PaymentMethodBrand;
(function (PaymentMethodBrand) {
  PaymentMethodBrand["AstropayCard"] = "astropay_card";
  PaymentMethodBrand["AstropayBank"] = "astropaybank";
  PaymentMethodBrand["Creditcard"] = "creditcard";
  PaymentMethodBrand["CoinspaidPlugNPay"] = "coinspaid_plug_and_pay";
  PaymentMethodBrand["Paysafecard"] = "paysafecard";
  PaymentMethodBrand["Idebit"] = "idebit";
  PaymentMethodBrand["Skrill"] = "skrill";
  PaymentMethodBrand["SticPay"] = "sticpay";
  PaymentMethodBrand["Neteller"] = "neteller";
  PaymentMethodBrand["Apco"] = "apco";
  PaymentMethodBrand["Mifinity"] = "mifinity";
  PaymentMethodBrand["Funanga"] = "funanga";
  PaymentMethodBrand["Venuspoint"] = "venuspoint";
  PaymentMethodBrand["Bank"] = "bank";
  PaymentMethodBrand["BankTransfer"] = "bank_transfer";
  PaymentMethodBrand["Sofort"] = "sofort";
  PaymentMethodBrand["Pugglepay"] = "pugglepay";
  PaymentMethodBrand["Ideal"] = "ideal";
  PaymentMethodBrand["Instadebit"] = "instadebit";
  PaymentMethodBrand["Interac"] = "interac";
  PaymentMethodBrand["Neosurfvoucher"] = "neosurfvoucher";
  PaymentMethodBrand["Neosurf"] = "neosurf";
  PaymentMethodBrand["Zimpler"] = "zimpler";
  PaymentMethodBrand["Bankiban"] = "bankiban";
  PaymentMethodBrand["Upaycard"] = "upaycard";
  PaymentMethodBrand["Pay4Fun"] = "pay4fun";
  PaymentMethodBrand["Paypal"] = "paypal";
  PaymentMethodBrand["PayRetailers"] = "payretailers";
  PaymentMethodBrand["Webredirect"] = "webredirect";
  PaymentMethodBrand["Redirectdeposit"] = "redirect_deposit";
  PaymentMethodBrand["Royalpayvoucher"] = "royalpayvoucher";
  PaymentMethodBrand["Macropay"] = "macropay";
  PaymentMethodBrand["Siru"] = "siru";
  PaymentMethodBrand["Muchbetter"] = "muchbetter";
  PaymentMethodBrand["Jeton"] = "jeton";
  PaymentMethodBrand["Flexepin"] = "flexepin";
  PaymentMethodBrand["Skrillqco"] = "skrillqco";
  PaymentMethodBrand["Piastrix"] = "piastrix";
  PaymentMethodBrand["Boleto"] = "boleto";
  PaymentMethodBrand["Realdeposits"] = "realdeposits";
  PaymentMethodBrand["Euteller"] = "euteller";
  PaymentMethodBrand["Cardpay"] = "cardpay";
  PaymentMethodBrand["Coinspaid"] = "coinspaid";
  PaymentMethodBrand["Qiwi"] = "qiwi";
  PaymentMethodBrand["Trustly"] = "trustly";
  PaymentMethodBrand["OnlineBankTransfer"] = "online_bank_transfer";
  PaymentMethodBrand["Card"] = "card";
  PaymentMethodBrand["Bankintl"] = "bankintl";
  PaymentMethodBrand["Banklocal"] = "banklocal";
  PaymentMethodBrand["Gate2WayWallet"] = "gate2waywallet";
  PaymentMethodBrand["MercuryoCrypto"] = "mercuryo_crypto";
  PaymentMethodBrand["MercuryoFiat"] = "mercuryo_fiat";
  PaymentMethodBrand["Visa"] = "visa";
  PaymentMethodBrand["Mastercard"] = "mastercard";
  PaymentMethodBrand["ApplePay"] = "applepay";
  PaymentMethodBrand["EmerchantpayCardAcquirer"] = "emerchantpay~card-acquirer";
  PaymentMethodBrand["BankDomestic"] = "bankdomestic";
  PaymentMethodBrand["FixiPay"] = "fixipay";
})(PaymentMethodBrand = exports.PaymentMethodBrand || (exports.PaymentMethodBrand = {}));