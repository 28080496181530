import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { Router } from '@angular/router';
import { CmsApiService } from '../api/cms-api.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { PlatformService } from '../platform.service';
import { HostRequestsInterceptor } from '../../interceptors/host-requests.interceptor';
import { first, tap } from 'rxjs/operators';
import { TranslationService } from '../../shared/translation/translation.service';
import {ModalsFromUrlService} from '../../modal-v2/modals-from-url.service';
import {ModalService} from '../../modal-v2/modal.service';
import {ModalsFromUrlList} from '../../modal-v2/modals-from-url-list';

/**
 * Game mode list
 */
export enum GameMode {
  DEMO = 'demo',
  REAL = 'real'
}

const gamesSlugForAlwaysOpenRegisterModal: string[] = ['pilot-cup'];

@Injectable({
  providedIn: 'root'
})
export class GamesLauncherService {

  /**
   * Is game launched now
   */
  public gameLaunched: boolean;

  constructor(
    private _user: UserService,
    private _language: LanguageService,
    private _modalsFromUrl: ModalsFromUrlService,
    private _modal: ModalService,
    private _router: Router,
    private _cmsApi: CmsApiService,
    private _toast: ToastMessageService,
    private _platform: PlatformService,
    private _hostInterceptor: HostRequestsInterceptor,
    private _translate: TranslationService
  ) { }

  /**
   * Returns iframe source for provided game
   *
   * @param game
   * @param demo
   */
  public gameSource(game: any, demo: boolean = false): {url: string, mode: GameMode} {

    const idObject = this.resolveGameId(game, demo);

    return {
      url: this._hostInterceptor.overrideUrl(`${environment.ss_host}/${this._language.current}/games/${game.externalId}/${idObject.id}`),
      mode: idObject.mode
    };
  }

  /**
   * Returns external id for provided game
   *
   * @param game
   * @param demo
   * @private
   */
  public resolveGameId(game: any, demo: boolean = false): {id: string, mode: GameMode} {
    if (!this._user.auth || demo || !this._user.info.currency || !game.externalData.real[this._user.info.currency]) {
      return {
        id: game.externalData.demo,
        mode: GameMode.DEMO
      };
    } else {
      return {
        id: game.externalData.real[this._user.info.currency].id,
        mode: GameMode.REAL
      };
    }
  }

  /**
   * Check if game available for current user
   *
   * @param game
   */
  public checkGameAvailability(game): boolean {
    if (game) {
      if (!this._user.auth && game.nodemo) {
        this._translate.getUserTranslates$().pipe(
          first(),
          tap(() => {
            this._modalsFromUrl.controller.open(this._user.isUserAlreadyRegistered && !this._alwaysOpenModalRegistration(game) ?
              ModalsFromUrlList.LOGIN :
              ModalsFromUrlList.REGISTRATION
            );
          })
        ).subscribe();
        return false;
      }

      if (this._user.auth && !game.externalData.real[this._user.info.currency]) {
        this._gameNotAvailablePopup({
          currencyList: Object.keys(game.externalData.real),
          game: game.name,
          gameSlug: game.slug
        });
        return false;
      }

      if (!this._user.auth && game.live) {
        this._router.navigateByUrl('/register').then();
      }

      return true;
    }

    this._gameNotAvailablePopup({
      game: game.name,
      gameSlug: game.slug
    });
    return false;
  }

  /**
   * Show game not available popup
   *
   * @private
   */
  private _gameNotAvailablePopup(data: object = {}) {
    this._router.navigateByUrl('/games').then(() => {
      this._modal.open('GAME_NOT_AVAILABLE', {
        data
      });
    });
  }

  /**
   * Open game by external id
   *
   * @param game
   */

  public openGameByExternalId(game) {
    this._cmsApi.gameList({'external_id[]': (game.identifier || game.externalId)}).subscribe(e => {
      if (e.data.gameList) {
        this._router.navigateByUrl('/play/' + e.data.gameList[0].slug, {
          state: {
            game: e.data.gameList[0]
          }
        });
      } else {
        this._router.navigateByUrl('/games');
      }
    });
  }

  /**
   * Send Game open data for auto top (if user is auth)
   */
  public postGameOpen(gameId) {
    if (this._user.auth) {
      this._cmsApi.postGameOpen({user_id: this._user.info.id, game: gameId}, {country_short: this._user.info.country}).subscribe();
    }
  }

  /**
   * If true always open register modal
   * @param game
   * @private
   */
  private _alwaysOpenModalRegistration(game): boolean {
    return gamesSlugForAlwaysOpenRegisterModal.some(slug => slug === game.slug);
  }
}
