import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CommonDataService, CountryList$ } from '../../../services/common-data.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { tap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { TranslatePipe } from '../../translation/translate.pipe';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';
import { AsyncPipe, NgClass } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss'],
    standalone: true,
    imports: [SetImgAltDirective, FormsModule, ClickOutsideDirective, NgClass, ReactiveFormsModule, AsyncPipe, TranslatePipe]
})
export class CountrySelectorComponent implements OnInit {

  @Output() callingCode: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Is country list open
   */
  private _open: boolean;

  /**
   * Country list after filter
   */
  public filteredCountryList$: ReplaySubject<any[]> = CountryList$;

  /**
   * Search form controller
   */
  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    query: new UntypedFormControl('')
  });

  @Input() countryName: boolean;

  /**
   * Country control
   */
  @Input() control: AbstractControl;

  /**
   * Arrow icon state
   */
  @Input() showArrow: boolean;

  constructor(
    public data: CommonDataService
  ) { }

  ngOnInit() {
    this._handleSearch();
  }

  get isOpen(): boolean {
    return this._open;
  }

  /**
   * Open countries list
   */
  open() {
    this._open = true;
  }

  /**
   * Close countries list
   */
  close() {
    this._open = false;
  }

  /**
   * Select country
   *
   * @param country
   */
  select(country: any) {
    this.close();
    this.control.setValue(country.short);
    this.callingCode.emit(country.callingCode);
    this.showArrow = false;
  }

  /**
   * Handle search form and filter countries
   *
   * @private
   */
  private _handleSearch() {
    this.searchForm.get('query').valueChanges.pipe(
      untilDestroyed(this),
      tap(value => this.filteredCountryList$.next(
        this.data.countryList.filter(country => country.name.toLowerCase().includes(value.toLowerCase()))
      ))
    ).subscribe();
  }

}
