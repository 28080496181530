import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[InputNumber]',
    standalone: true
})
export class InputNumberDirective implements AfterViewInit {
  constructor(
    private _el: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    const regEx = new RegExp('[0-9,.]');
    this._el.nativeElement.addEventListener('keypress', (event) => {
      const char = event.key !== undefined ? event.key : String.fromCharCode(event.keyCode);
      if (!regEx.test(char)) {
        event.preventDefault();
      }
    });
  }
}
