import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, first, tap} from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {TabsComponent} from '../../../core/modules/tabs/tabs.component';
import {TabItemComponent} from '../../../core/modules/tabs/tab-item.component';
import {UserService} from '../../../core/services/user/user.service';
import {BlogService} from '../blog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlatformService} from '../../../core/services/platform.service';
import { AbTestNewService } from '../../../core/ab-test/ab-test.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { BlogArticlePreviewComponent } from '../blog-article-preview/blog-article-preview.component';
import { AsyncPipe } from '@angular/common';
import { AB_TEST_LIST } from '../../../core/ab-test/ab-test.data';


export enum BlogCategory {
  PROMOTIONS = 3,
  NEWS = 4,
}

export const NEW_USERS_TEST_DESKTOP_SLUG = 'new-users';
export const NEW_USERS_TEST_MOBILE_SLUG = 'new-users-mobile';

@UntilDestroy()
@Component({
    selector: 'app-blog-articles-list',
    templateUrl: './blog-articles-list.component.html',
    styleUrls: ['./blog-articles-list.component.scss'],
    standalone: true,
    imports: [TabsComponent, TabItemComponent, BlogArticlePreviewComponent, AsyncPipe, TranslatePipe]
})
export class BlogArticlesListComponent implements OnInit, OnDestroy {

  @ViewChild('tab') tab: TabsComponent;
  @ViewChild('news') news: TabItemComponent | any;
  @ViewChild('promotions') promotions: TabItemComponent;

  /**
   * Required template fields for rendering articles list
   */
  private readonly _requiredTemplateFields: Array<string> = ['Title', 'Description', 'Image', 'PreviewImage', 'Video', 'OnlyAuth'];

  /**
   * Default articles limit per request
   */
  private readonly _limit = 5;

  /**
   * Current offset
   */
  private _offset = 0;

  /**
   * Is load more button visible
   */
  private _loadMoreVisible: boolean;

  /**
   * Blog articles lists from CMS
   */
  public lists: Array<Observable<any>> = [];

  private _activeTab: string;

  constructor(
    public user: UserService,
    private _blog: BlogService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _platform: PlatformService,
    private _abTestNew: AbTestNewService,
    private _device: DeviceDetectorService
  ) { }

  /**
   * Access to _loadMoreVisible from template
   */
  get loadMoreVisible(): boolean {
    return this._loadMoreVisible;
  }

  ngOnInit() {
    this._handleQuery();
    this._initCurrentTab();
  }

  ngOnDestroy(): void {
  }

  /**
   * Update current articles offset
   */
  public updateOffset() {
    this._offset++;
    this.searchArticles(this._activeTab);
  }

  private _handleQuery() {
    this._route.queryParams.pipe(
      untilDestroyed(this),
      tap(data => {
        if (data?.modal) {
          return;
        }
        if (!data?.type) {
          this.onSelectTab({id: 'news'});
          this._initCurrentTab();
        } else {
          this.onSelectTab({id: data?.type});
        }
        this.lists = [];
        this._offset = 0;
        this._activeTab = !data?.type ? 'news' : data?.type;
        this.searchArticles(data?.type);
      }),
    ).subscribe();
  }

  /**
   * Search articles by provided filter
   */
  public searchArticles(data) {
    this.lists.push(this._blog.list({
      'field[]': this._requiredTemplateFields,
      type: data === 'news' ? BlogCategory.NEWS : BlogCategory.PROMOTIONS,
      limit: this._limit,
      offset: this._limit * this._offset
    }).pipe(
      filter((data) => {
        if (!this.user.auth) {
          if (data?.list) {
            data.list = data?.list?.filter((e) => !e?.OnlyAuth);
          }
        }
        return newUsersTest(data, this._abTestNew, this._device);
      }),
      tap(list => {
        if (!!list) {
          this._loadMoreVisible = list?.countTotal > this._limit * (this._offset + 1);
        }
      })
    ));
  }

  onSelectTab($event: any) {
    this._router.navigate([], {replaceUrl: true, queryParams: {type: $event?.id}});
  }
  private _initCurrentTab() {
    if (this._platform.isBrowser) {
      this._route.queryParams.pipe(
        first(),
        tap((data) => {
          setTimeout(() => {
            this.tab.selectTab(data?.type === 'promotions' ? this.promotions : this.news);
          }, 400);
        })
      ).subscribe();
    }
  }
}

export function newUsersTest(data, abTestService: AbTestNewService, deviceService: DeviceDetectorService) {

  if (data?.list) {
    data.list = data?.list?.filter((e) => {
      if (e.slug.includes(NEW_USERS_TEST_DESKTOP_SLUG)  && AB_TEST_LIST.DEVSS5757.resolvedValue !== AB_TEST_LIST.DEVSS5757.abTestGroupIds.V2) {
        return false;
      } else if (deviceService.isDesktop() && e.slug === NEW_USERS_TEST_MOBILE_SLUG) {
        return false;
      } else { return !(deviceService.isMobile() && e.slug === NEW_USERS_TEST_DESKTOP_SLUG); }
    });
  }

  return data;
}
