"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubmitStatus = void 0;
var SubmitStatus;
(function (SubmitStatus) {
  SubmitStatus["Unknown"] = "unknown";
  /** After receiving this status, you need to display notification */
  SubmitStatus["Notify"] = "notify";
  /** Receiving this status means that user will be redirected to payment system page */
  SubmitStatus["UserRedirected"] = "user_redirected";
  /** Receiving this status means that payment system page will be opened inside iframe */
  SubmitStatus["IframeOpened"] = "iframe_opened";
  /** After receiving this status, you need to reload the getMethodFields method to get updated payment data. */
  SubmitStatus["OutdatedMethodFields"] = "outdated_method_fields";
})(SubmitStatus = exports.SubmitStatus || (exports.SubmitStatus = {}));