import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import {switchMap, tap} from 'rxjs/operators';
import {EnvironmentService} from '../environment.service';
import {SsPaymentsV2Service} from "../../vendor/ss-payments-v2/ss-payment.service";

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}

interface IMask {
  country: string;
  mask: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserPhoneService {

  private _hasVerifiedPhone: boolean;

  private _prefix: string;

  private _mask: IMask;

  constructor(
    private _api: SsApiService,
    private _env: EnvironmentService,
    private _ssPayments: SsPaymentsV2Service,
  ) {
    if (!this._prefix) {
      this._env.env$.pipe(
        tap((env) => {
          this._prefix = `+${ env.data.country.callingCode }`;
        })
      ).subscribe();
    }
  }

  get verified(): boolean {
    return this._hasVerifiedPhone;
  }

  get prefix(): string {
    return this._prefix;
  }

  set Prefix(value) {
    this._prefix = value;
  }

  set Mask(value) {
    this._mask = value;
  }

  get mask(): IMask {
    return this._mask;
  }

  /**
   * Returns list of player phone numbers
   */
  getPhoneList(): Observable<any> {
    return this._api.playerPhone().pipe(
      tap(list => this._hasVerifiedPhone = list.some(phone => phone.verified_status === PhoneStatus.VERIFIED)),
    );
  }

  /**
   * Add user phone
   *
   * @param data
   */
  addPhone(data: object): Observable<any> {
    return this._api.postPlayerPhone({
      phone: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Delete user phone
   */
  deletePhone(): Observable<any> {
    return this._api.deletePlayerPhone().pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Verify player [hone
   *
   * @param code
   */
  verifyPhone(code: string): Observable<any> {
    return this._api.playerPhoneConfirm({
      phone: {
        code
      }
    });
  }

  /**
   * Request verification code
   */
  resendVerificationCode(): Observable<any> {
    return this._api.playerPhoneResendVerificationCode();
  }
}
