@if (socialPromo$ | async; as promo) {
  <div class="social">
    <div class="social__inner">
      <div class="left">
        <div class="social__inner_title">{{promo[0]?.Title}}</div>
        <div class="social__inner_description">{{promo[0]?.Description}}</div>
        <div class="social__inner_actions">
          @if (promo[0]?.DiscordBtnLink; as url) {
            <a [href]="url">
              <img [src]="arabic.isAr ? '/assets/img/social/discord-arabic.png' : '/assets/img/social/discord.png'" alt="discord" class="discord">
            </a>
          }
          @if (promo[0]?.TelegramBtnLink; as url) {
            <a [href]="url">
              <img [src]="arabic.isAr ? '/assets/img/social/telegram-arabic.png' : '/assets/img/social/telegram.png'" alt="telegram" class="telegram">
            </a>
          }
          @if (promo[0]?.TwitterBtnLink; as url) {
            <a [href]="url">
              <img [src]="arabic.isAr ? '/assets/img/social/twitter-arabic.png' : '/assets/img/social/twitter.png'" alt="twitter" class="twitter">
            </a>
          }
          @if (promo[0]?.InstagramBtnLink; as url) {
            <a [href]="url">
              <img [src]="arabic.isAr ? '/assets/img/social/instagram-arabic.png' : '/assets/img/social/instagram.png'" alt="instagram" class="instagram">
            </a>
          }
        </div>
      </div>
      <div class="right">
        <img class="telegram-img" [src]="promo[0]?.Img" alt="telegram">
      </div>
    </div>
  </div>
}

