<div class="deposit_container" [ngClass]="{'deposit_container__crypto': isCryptoSelected}">
  <div class="deposit_container__header">
    <div class="deposit_container__title">
      <img src="/assets/img/categories/deposit.png" alt="deposit">
      {{'btn.deposit' | translate}}
    </div>
    <i class="deposit_container__close icon-close" (click)="modal.close()"></i>
  </div>


  <app-payment-ss [action]="Action"
                  [isModal]="true"
  ></app-payment-ss>
</div>
