import { Injectable } from '@angular/core';
import { CUSTOM_ROUTES, ignoredHosts, redirectRoutes, SS_LIB_CONFIG } from './ss-payment-config';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { CustomPaymentType, Payment, PaymentsLibType, PaymentsMethod, Results } from './ss-payment-types';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../../services/platform.service';
import { WindowService } from '../../services/window.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

declare var PaymentsAPI: PaymentsLibType;

/**
 * Uses for add class in template
 */
const biggerLogos = [
  'mastercard', 'muchbetter', 'creditcard', 'combined', 'fastasia', 'volt_bankuberweisung', 'mifinity_au',
  'einterac', 'pay4fun', 'boleto', 'boleto_pix', 'transferencia_bancaria', 'banrisul', 'astropay_direct', 'mifinity_sepa',
  'sb', 'bb', 'funanga', 'applepay', 'interac_finteqhub', 'm_eps_aut', 'm_sofort', 'jsofort_aut',  'jsofort_deu', 'jgiropay_deu',
  'jtrustly_deu', 'm_giropay', 'fixipay', 'bank_transfer', 'inpay', 'etransfer'
];

const smallerLogosMobile = [
  'm_giropay', 'jgiropay_deu',
];

/**
 * The function `interceptPaymentSavedAccounts` intercepts XMLHttpRequests to a specific API endpoint
 * and extracts saved account information to pass to a callback function.
 * @param {CallableFunction} callback - The `callback` parameter in the `interceptPaymentSavedAccounts`
 * function is a function that will be called with the list of saved accounts retrieved from the
 * response when a payment request is made.
 * @returns The `originalSend.apply(this, arguments)` statement is being returned in the
 * `interceptPaymentSavedAccounts` function.
 */
const interceptPaymentSavedAccounts = (callback: CallableFunction) => {
  const originalSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function(body) {
    this.addEventListener('readystatechange', function() {
      if (this.readyState === 4 && this.responseURL.includes('https://api-paymentiq-io.softswiss.net')) {
        const savedAccList = this.response?.methods?.map(e => e?.accounts)?.flat() || [];
        callback(savedAccList);
      }
    });
    return originalSend.apply(this, arguments);
  };
};

@Injectable({ providedIn: 'root' })
export class SsPaymentsV2Service {

  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _savedAccMap = new Map<string, any>();

  constructor(
    private _platform: PlatformService,
    private _window: WindowService,
  ) {

  }

  /**
   * Load api library script
   */
  loadApiLibrary() {
    this._initApiLibraryConfig();
  }

  /**
   * Set Library config & lazy load second library part
   */
  private async _initApiLibraryConfig() {
    SS_LIB_CONFIG.serverUrl = this._resolveHost();
    PaymentsAPI?.config(SS_LIB_CONFIG)?.then(() => {
      if (PaymentsAPI._isLoaded && this._platform.isBrowser) {
        interceptPaymentSavedAccounts((savedAccList) => {
          this._resolveSavedAccMap(savedAccList);
        });
        this._ready$.next(true);
      }
    });
  }

  /**
   * Fetch payment methods list by currency & action
   * @param currency
   * @param action
   */
  public fetchMethods(currency: string, action: Payment.Action): Observable<PaymentsMethod[]> {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.getMethods({ currency, paymentAction: action }))),
      map((list: any) => list.map((method: any) => {
        method.currency = currency;
        method.brand = method.id.split('_')[method.id.split('_').length - 1];
        method.provider = method.id.split('_')[0];
        setTimeout(() => {
          method.savedProfiles = method?.savedProfiles?.map((acc: any) => {
            if (this._savedAccMap.has(acc?.id)) {
              const cardHolder = this._savedAccMap.get(acc?.id)?.cardHolder || null;
              const expiryDate = this._savedAccMap.get(acc?.id)?.expiryDate || null;
              acc.cardHolder = cardHolder;
              acc.expiryDate = expiryDate;
            }
            return acc;
          });
        }, 100);
        return method;
      })),
      map((list: PaymentsMethod[]) => list.map(method => ({
        ...method,
        ourImg: this._resolveOurImage(method),
        isBiggerLogo: biggerLogos.some(brand => method.brand.includes(brand)),
        isSmallerLogoMob: smallerLogosMobile.some(brand => method.brand.includes(brand))
      })))
    );
  }

  /**
   * Get fields for current method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public getCurrentMethodFields(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string = '') {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(
        PaymentsAPI.getMethodFields({
          id,
          currency,
          paymentAction,
          savedProfileId,
        }),
      )),
      map(method => this._resolveMethodFields(method)),
    );
  }

  public getConversionMethods(currency: string, paymentAction: Payment.Action) {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(
        PaymentsAPI.getConversionMethods({
            currency,
            paymentAction,
          },
        ),
      )),
    );
  }

  /**
   * Resolve current method fields, add translate key
   * @param method
   * @returns
   */
  private _resolveMethodFields(method: Results.GetMethodFieldsResult) {
    if (method.amountField) {
      method.amountField.translationKey = 'labl.' + method.amountField.translationKey;
    }
    method.methodFields = method.methodFields.map(e => {
      e.translationKey = 'labl.' + e.translationKey;
      return e;
    });
    method.playerFields = method.playerFields.map((e: any) => {
      e.fieldName = e.field;
      e.translationKey = 't.' + e?.field?.replace('_', '-');
      return e;
    }) as any[];
    return method;
  }

  /**
   * Deletes user's saved profile for the specific method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public deleteSavedAcc(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string) {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(
        PaymentsAPI.deleteSavedProfile({
          id,
          currency,
          paymentAction,
          savedProfileId,
        }),
      )),
    );
  }

  /**
   * Responsible for processing the transaction, redirecting to the payment system, and opening a modal for additional data
   * @param id
   * @param currency
   * @param paymentAction
   * @param paymentType
   * @param savedProfileId
   * @param methodFieldsData
   * @param playerFieldsData
   * @returns
   */
  public submitForm(
    id: string,
    currency: string,
    paymentAction: Payment.Action,
    paymentType: CustomPaymentType,
    methodFieldsData: { amount: any; },
    savedProfileId: string = '',
    playerFieldsData = null,
  ){
    SS_LIB_CONFIG['redirectRoutes'] = {
      ...redirectRoutes(paymentAction, paymentType),
      ...Object.keys(CUSTOM_ROUTES).reduce((acc, key) => {
        if (CUSTOM_ROUTES[key]) {
          acc[key] = CUSTOM_ROUTES[key];
        }
        return acc;
      }, {})
    };
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.config(SS_LIB_CONFIG))),
      switchMap(() => fromPromise(PaymentsAPI.submit({
        id,
        currency,
        paymentAction,
        amountValue: methodFieldsData.amount,
        savedProfileId,
        methodFieldsData,
        playerFieldsData,
      } as any))),
    );
  }

  public resetCache() {
    return fromPromise(PaymentsAPI.resetCache());
  }


  /**
   * Resolve payment host for mirrors
   * @returns
   */
  private _resolveHost() {
    return this._platform.isBrowser &&
    !ignoredHosts.some(item => item?.includes(this._window.nativeWindow.location.host)) ?
      this._window.nativeWindow.location.origin?.replace('stage.', '')?.replace('https://www.', 'https://') :
      environment.ss_host;
  }

  /**
   * The function `_resolveSavedAccMap` populates a map with account objects from a list if the account
   * ID is not already present in the map.
   * @param {any[]} savedAccList - The `savedAccList` parameter is an array of objects representing saved
   * accounts.
   */
  private _resolveSavedAccMap(savedAccList: any[]) {
    savedAccList.forEach(acc => {
      if (!this._savedAccMap.has(acc?.accountId)) {
        this._savedAccMap.set(acc?.accountId, acc);
      }
    });
  }

  /**
   * Returns payment method logo URL from our assets
   *
   * @param method
   * @private
   */
  private _resolveOurImage(method: PaymentsMethod): string {
    return method.type === 'crypto'
      ? `/assets/svg/payment-methods-new/${this._proccessCryptoImage(method.id)}_${method.brand}.svg`
      : `/assets/svg/payment-methods-new/${method.provider}-${method.brand}.svg`;
  }

  private _proccessCryptoImage(id: string) {
    return id.split('_coinspaid').slice(-2)[0].split('_').pop();
  }


}
