import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, concat, defer, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {GlobalEventsService} from './global-events.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  private _pageVisible$: Observable<boolean> = concat(
    defer(() => of(!this._document.hidden)),
    this._events.visibilityChangeEvent$.pipe(
      map(e => !this._document.hidden)
    )
  );

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _events: GlobalEventsService
  ) {

  }

  public get pageIsVisible$() {
    return this._pageVisible$;
  }

}
