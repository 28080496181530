import {Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { LotteryService } from '../../../../../../core/services/lottery.service';
import { UserService } from '../../../../../../core/services/user/user.service';
import { CommonDataService } from '../../../../../../core/services/common-data.service';
import {
  FormCheckboxToggleComponent
} from '../../../../../../core/modules/form-controls/form-checkbox-toggle/form-checkbox-toggle.component';
import { TranslatePipe } from '../../../../../../core/shared/translation/translate.pipe';

@Component({
    selector: 'app-lottery-deposit',
    templateUrl: './lottery-deposit.component.html',
    styleUrls: ['./lottery-deposit.component.scss'],
    standalone: true,
  imports: [
    AsyncPipe,
    FormCheckboxToggleComponent,
    TranslatePipe,
  ],
})
export class LotteryDepositComponent implements OnInit {

  public countTickets$: Observable<any> = this.lottery.checkLotteryCountTickets$();

  public lotteryStatus: boolean;

  public isCryptoCurrency: boolean;

  constructor(
    public lottery: LotteryService,
    public user: UserService,
    public common: CommonDataService
  ) {
    this.lottery.updateLotteryStatus$.subscribe(status => {
      this.lotteryStatus = !!status;
    });
  }

  ngOnInit() {
    this.isCryptoCurrency = this.common.isCryptoCurrency(this.user.currentCurrency.currency);
  }

  public changeLottery(e) {
    e ? this.lottery.selectLottery() : this.lottery.unSelectLottery();
  }
}
