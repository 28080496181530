<div class="sign-in-container">
  <div class="wrapper">
    <i class="close icon-close" (click)="onClose()"></i>
    <form class="form" [formGroup]="controller.form" (ngSubmit)="controller.submitForm()">
      <div class="form__title form__title--main">{{'btn.login' | translate}}</div>
      <div>
        <app-form-input class="input-item" [control]="controller.input('email')" [label]="'labl.email' | translate">
          <input class="input input--simple" type="text" name="email" formControlName="email" setValueAutofill>
        </app-form-input>

        <app-form-input class="input-item has-icon" [control]="controller.input('password')" [label]="'labl.pass' | translate">
          <i class="icon icon-password password-toggle" [ngClass]="{'icon--ios': body.IOS}"></i>
          <input class="input input--simple" type="password" name="password" PasswordShow formControlName="password" setValueAutofill>
        </app-form-input>
      </div>

      <div class="submit-container">
        <button class="btn btn--filled-primary" [loading]="controller.loading" type="submit">{{'btn.login' | translate}}</button>
      </div>

      <app-social-auth></app-social-auth>

      <div class="bottom-links">
        <div class="bottom-links__item">
          <span class="link " (click)="openRestorePasswordModal()">{{'t.forgot-pass' | translate}}</span>
          <span>
            <span> {{ 't.or-no-acc' | translate }} </span>
            <span class="link " (click)="openRegistrationPopop()">{{ 'btn.singup' | translate }}</span>
          </span>
        </div>
      </div>
    </form>
    @if (installApp.showInstallAppRegisterBlock && installApp.isEnableNativeApp) {
      <div class="install-app">
        <img src="/assets/img/icons/512.png" alt="Install app logo" class="install-app__img">
        <div class="install-app__text">{{ 't.all-games-bonuses-app' | translate }}</div>
        <button [routerLink]="['/',this.lang.current, 'mobile-app']" class="btn btn--filled-primary" (click)="installApp.closeRegisterBlock()" [routerLink]="['/', 'mobile-app']"  type="button">{{'t.install' | translate}}</button>
        <i class="close icon-close" (click)="installApp.closeRegisterBlock()"></i>
      </div>
    }
  </div>
</div>
