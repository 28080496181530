export interface LoyaltyStatusData {
  id: string;
  name: string;
  image: string;
  image_active: string;
  points: string;
  subStatuses: SubStatus[];
  bonus?: string;
  minLoos?: string;
  maxCashBack?: string;
  minDep?: string;
  maxAmount?: string;
  wager?: string;
  reward?: string;
  notify_channel?: string;
  uid?: string;
}

export interface SubStatus {
  points: number;
}

export const LoyaltyStatusesCommon: LoyaltyStatusData[] = [
  {
    id: 'woodensword',
    name: 'lt.status-1',
    image: '/assets/img/statuses/sword_na.png',
    image_active: '/assets/img/statuses/sword.png',
    points: '0-499',
    bonus: 'lt.status-1-bonus',
    subStatuses: [],
    reward: 'lt.status-1-bonus'
  },
  {
    id: 'nunchaku',
    name: 'lt.status-2',
    image: '/assets/img/statuses/nunchaku.png',
    image_active: '/assets/img/statuses/nunchaku_active.png',
    points: '500-999',
    bonus: 'lt.status-2-bonus',
    subStatuses: [],
    reward: 'lt.status-2-bonus'
  },
  {
    id: 'Ronin',
    name: 'lt.status-3',
    image: '/assets/img/statuses/non-active.png',
    image_active: '/assets/img/statuses/active_2.png',

    points: '1000-1999',
    bonus: 'lt.status-3-bonus',
    subStatuses: [],
    reward: 'lt.status-3-bonus'
  },
  {
    id: 'Kenin',
    name: 'lt.status-4',
    image: '/assets/img/statuses/non-active-2.png',
    image_active: '/assets/img/statuses/active.png',
    points: '2000-3999',
    bonus: 'lt.status-4-bonus',
    subStatuses: [],
    reward: 'lt.status-4-bonus'
  }
];

export const LoyaltyStatusesSamurai: LoyaltyStatusData[] = [
  {
    id: 'Hatamoto',
    name: 'lt.status-1-1',
    image: '/assets/img/statuses/level_1_non_active.png',
    image_active: '/assets/img/statuses/level_1.png',
    points: '4000-9999',
    bonus: 'lt.status-1-1-bonus',
    minLoos: 'lt.status-1-1-min-loss',
    maxCashBack: 'lt.status-1-1-max-cb',
    wager: 'lt.status-1-1-wager',
    subStatuses: [
      {points: 6000},
      {points: 8000}
    ],
    reward: 'r.status-1'
  },
  {
    id: 'Daimyo',
    name: 'lt.status-1-2',
    image: '/assets/img/statuses/level_2_non_active.png',
    image_active: '/assets/img/statuses/level_2.png',
    points: '10000-47999',
    bonus: 'lt.status-1-2-bonus',
    minLoos: 'lt.status-1-2-min-loss',
    maxCashBack: 'lt.status-1-2-max-cb',
    wager: 'lt.status-1-2-wager',
    subStatuses: [
      {points: 15000},
      {points: 20000},
      {points: 25000},
      {points: 30000},
      {points: 35000},
      {points: 40000},
      {points: 45000},
    ],
    reward: 'r.status-2'
  },
  {
    id: 'Shogun',
    name: 'lt.status-1-3',
    image: '/assets/img/statuses/level_3_non_active.png',
    image_active: '/assets/img/statuses/level_3.png',
    points: '48000+',
    bonus: 'lt.status-1-3-bonus',
    minLoos: 'lt.status-1-3-min-loss',
    maxCashBack: 'lt.status-1-3-max-cb',
    wager: 'lt.status-1-3-wager',
    subStatuses: [
      {points: 55000},
      {points: 60000},
      {points: 65000},
      {points: 70000},
      {points: 75000},
      {points: 80000},
      {points: 85000},
    ],
    reward: 'r.status-3'
  }
];

export const LoyaltyStatusesNinja: LoyaltyStatusData[] = [
  {
    id: 'Genin',
    name: 'lt.status-2-1',
    image: '/assets/img/statuses/1-non-active.png',
    image_active: '/assets/img/statuses/1-active.png',
    points: '4000-9999',
    bonus: 'lt.status-2-1-bonus',
    minDep: 'lt.status-2-1-min-dep',
    maxAmount: 'lt.status-2-1-max-am',
    wager: 'lt.status-2-1-wager',
    subStatuses: [
      {points: 6000},
      {points: 8000},
    ],
    reward: 'r.status-1'
  },
  {
    id: 'Chunin',
    name: 'lt.status-2-2',
    image: '/assets/img/statuses/2-non-active.png',
    image_active: '/assets/img/statuses/2-active.png',
    points: '10000-47999',
    bonus: 'lt.status-2-2-bonus',
    minDep: 'lt.status-2-2-min-dep',
    maxAmount: 'lt.status-2-2-max-am',
    wager: 'lt.status-2-2-wager',
    subStatuses: [
      {points: 15000},
      {points: 20000},
      {points: 25000},
      {points: 30000},
      {points: 35000},
      {points: 40000},
      {points: 45000},
    ],
    reward: 'r.status-2'
  },
  {
    id: 'Jonin',
    name: 'lt.status-2-3',
    image: '/assets/img/statuses/3-non-active.png',
    image_active: '/assets/img/statuses/3-active.png',
    points: '48000+',
    bonus: 'lt.status-2-3-bonus',
    minDep: 'lt.status-2-3-min-dep',
    maxAmount: 'lt.status-2-3-max-am',
    wager: 'lt.status-2-3-wager',
    subStatuses: [
      {points: 55000},
      {points: 60000},
      {points: 65000},
      {points: 70000},
      {points: 75000},
      {points: 80000},
      {points: 85000},
    ],
    reward: 'r.status-3'
  }
];
