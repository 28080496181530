@if (buyCryptoList$ | async; as list) {
  @if (list?.length) {
    <div (click)="showBuyCryptoModal(list[0])" class="buy-crypto">
      <div class="buy-crypto__list">
        @for (item of list; track item) {
          <div class="buy-crypto__list--item">
            <div class="buy-crypto__list--item__logo">
              <img src="/assets/img/btc-logo.png" alt="">
            </div>
            <div class="buy-crypto__list--item__text">
              {{'t.buy-crypto-title' | translate}}
            </div>
            <div class="buy-crypto__list--item__arrow">
              <i class="icon-arrow"></i>
            </div>
          </div>
        }
      </div>
    </div>
  }
}
