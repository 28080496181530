<div class="track keen-slider" [class.init]="isSliderInited" [ngClass]="class" #track
  (mouseover)="setPause(true)"
  (mouseout)="setPause(false)"
  >
  <ng-content></ng-content>
</div>

<ng-template #pagination>
  <div class="pagination-container">
    <div>
      @if (slider) {
        <div class="pagination">
          @for (slide of slideList; track slide; let i = $index) {
            <span class="pagination__item" (click)="slider?.moveToIdx(i)"
            [ngClass]="{active: i === currentSlide}"></span>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
