import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NotificationCenterService } from '../notification-center.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { UserService } from '../../../services/user/user.service';
import { InstallAppService } from '../../../services/install-app.service';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';
import { SetImgAltDirective } from '../../../shared/directives/set-img-alt.directive';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-notification-center-drop-down',
  templateUrl: './notification-center-drop-down.component.html',
  styleUrls: ['./notification-center-drop-down.component.scss'],
  standalone: true,
  imports: [ClickOutsideDirective, SetImgAltDirective, NgClass, NotificationCenterComponent, AsyncPipe],
})
export class NotificationCenterDropDownComponent implements OnInit {
  @HostListener('window:popstate') onPopstate() {
    this.notify.close();
  }

  constructor(
    public notify: NotificationCenterService,
    public ws: WebSocketService,
    public user: UserService,
    private _installApp: InstallAppService,
    private _el: ElementRef,
  ) {}

  ngOnInit() {
    this.notify.dropdownElement = this._el.nativeElement;
    this._installApp.nativeAppValueResolved$
      .pipe(
        filter(data => !!data),
        first(),
        tap(() => {
          if (this._installApp.showInstallAppNotificationMessage) {
            this.notify.installAppChannel$.next(null);
          }
        }),
      )
      .subscribe();
  }
}
