import {Component, OnInit} from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../services/google-tag-manager.service';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';

@Component({
    selector: 'app-bonus-cancel-confirmation',
    templateUrl: './bonus-cancel-confirmation.component.html',
    styleUrls: ['./bonus-cancel-confirmation.component.scss'],
    standalone: true,
    imports: [TranslatePipe]
})
export class BonusCancelConfirmationComponent implements OnInit, ModalContent {

  /**`
   * Link to modal window
   */
  public modal: ModalRef;

  constructor(private _gtm: GoogleTagManagerService) { }

  ngOnInit() {
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.BONUS_CANCEL_CONFIRMATION,
      POPUP_EVENT_ACTIONS.BONUS_CANCEL_CONFIRMATION
    );
  }

}
