<div class="crypto">
  @if (field?.fieldName !== 'tag') {
    <p class="crypto__desc">{{'t.private-dep-address' | translate}}</p>
    <div class="crypto__address">
      @if (field?.address | cryptoAddress; as crypto_address) {
        <div #address [innerHTML]="crypto_address | safe : 'html'"></div>
        <button class="btn btn--primary big" (click)="copy(address,  crypto_address)">{{'btn.copy-adress' | translate}}</button>
      }
    </div>
    <div class="crypto__qr-code">
      <div #accordion=accordionDirective Accordion class="crypto__more">{{ 't.qr-code' | translate }}
        <i class="icon-pointer-down" [class.rotated]="accordion.isOpen"></i>
      </div>
      <div class="crypto__image">
        <a>
          <img [src]="field?.address | cryptoAddress | generateQrBase64 | async">
        </a>
      </div>
    </div>
  } @else {
    @if (field?.address | cryptoAddress; as crypto_address) {
      <p #address [innerHTML]="crypto_address | safe : 'html'"></p>
      <button class="btn btn--primary big" (click)="copy(address, crypto_address)">{{'btn.copy-tag' | translate}}</button>
    }
  }

</div>
