@if (depositBonuses$ | async; as bonuses) {
  @if (bonuses?.length) {
    <div class="slider" [ngClass]="{show: showSlider, active: user?.info?.can_issue_bonuses}">
      <app-slider #bonusesSlider [slideList]="bonuses" [config]="sliderConfig">
        @for (bonus of bonuses; track bonus; let i = $index) {
          <div class="slider__bonus">
            <div class="slider__bonus_info">
              <div class="title" [innerHTML]="(bonus?.bonus?.title || bonus?.freespins?.title) | safe: 'html'"></div>
              <div class="description">
                {{ bonus?.bonus?.attributes?.bonus_amount_percent }} @if (bonus?.bonus?.attributes?.bonus_amount_max) {
                <span>&#37;</span>
              }
              @if (bonus?.bonus?.attributes?.bonus_amount_max) {
                {{ 't.up-to' | translate }} {{ user.info?.currency_symbol }}&nbsp;{{ bonus?.bonus?.attributes?.bonus_amount_max[user.info?.currency] }}
              }
              @if (bonus?.freespins) {
                <span
                  > @if (bonus?.bonus?.attributes?.bonus_amount_percent && bonus?.bonus?.attributes?.bonus_amount_max && bonus?.freespins?.attributes?.freespins_count) {
                  <span
                  >+</span>
                }
              {{ bonus?.freespins?.attributes?.freespins_count }} {{ 't.free-spins' | translate }}</span>
            }
          </div>
          @if (bonus?.bonus?.conditions?.amount_min?.[user?.info?.currency] || bonus?.bonus?.conditions?.amount_max?.[user?.info?.currency]) {
            <div class="second-description">
              {{ 'btn.deposit' | translate }} @if (bonus?.bonus?.conditions?.amount_min?.[user?.info?.currency]) {
              <span>
              {{ user.info?.currency_symbol }}{{ bonus?.bonus?.conditions?.amount_min?.[user?.info?.currency] }}</span>
            }
            @if (bonus?.bonus?.conditions?.amount_max?.[user?.info?.currency]) {
              <span> - </span>
            }
            @if (bonus?.bonus?.conditions?.amount_max?.[user?.info?.currency]) {
              <span>{{ user.info?.currency_symbol }}{{ bonus?.bonus?.conditions?.amount_max?.[user?.info?.currency] }}</span>
            }
          </div>
        }
      </div>
      <img [src]="bonus?.bonus?.imageUrl || bonus?.freespins?.imageUrl" alt="" class="slider__bonus_img">
    </div>
  }
</app-slider>
@if (bonuses?.length > 1) {
  <div class="pagination-container">
    @if (bonusesSlider) {
      <ng-container *ngTemplateOutlet="bonusesSlider?.paginationElement"></ng-container>
    }
  </div>
}
</div>
}
} @else {
  <app-preloader></app-preloader>
}


<div class="bonus-issue">
  <app-form-checkbox (change$)="onChangeIssueBonus($event)"
    [label]="user?.info?.can_issue_bonuses ?
    ('labl.want-receive-bonuses' | translate) :
    ('labl.do-not-want-receive-bonuses' | translate)"
    [checked]="user?.info?.can_issue_bonuses"
    >
  </app-form-checkbox>
</div>
<div #bonusAccordion Accordion class="bonuscode-link">
  <span class="link link--secondary">{{'t.have-bonus-code' | translate}}</span>
</div>
<form class="bonus-code-form" [formGroup]="bonuscodeForm" (ngSubmit)="onBonuscodeFormSubmit()">
  <app-form-input class="bonus-code-form__input" [label]="'bonus.code' | translate">
    <input class="input input--simple"
      formControlName="code" type="text">
    </app-form-input>
    @if (!user?.info.deposit_bonus_code) {
      <button
        type="submit"
        [disabled]="!bonuscodeForm.get('code').value"
        class="bonus-code-form__action btn btn--primary">
        {{'btn.send' | translate}}
      </button>
    }

    @if (user?.info.deposit_bonus_code) {
      <button
        type="button"
        class="bonus-code-form__action btn btn--primary"
        (click)="deleteDepositeBonus()">
        {{'btn.clear' | translate}}
      </button>
    }
  </form>

