import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { ReplaySubject } from 'rxjs';
import { UserService } from './user/user.service';
import { map, tap } from 'rxjs/operators';
import { FiltersService } from './filters.service';
import { isProvidersHasPragmatic } from '../helpers/signals';

export interface GameProvider {
  id: number;
  alias: string;
  name: string;
  restrictCountry: [];
  inFooter?: boolean;
  inFilter?: boolean;
  imgAlias?: string;
  customImg?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GameProvidersService {
  /**
   * Game provider list observable
   */
  private _list$: ReplaySubject<Array<GameProvider>> = new ReplaySubject<Array<GameProvider>>(1);

  /**
   * Game provider list
   */
  private _list: Array<GameProvider> = [];
  private _filterList$: ReplaySubject<Array<GameProvider>> = new ReplaySubject<Array<GameProvider>>(1);

  private _filterProviderList: GameProvider[] = [];

  constructor(
    private _api: CmsApiService,
    private _user: UserService,
    private _filter: FiltersService
  ) {
    this.updateList();
  }

  /**
   * Access to provider list observable from outside
   */
  get list$(): ReplaySubject<Array<GameProvider>> {
    return this._list$;
  }

  get filterList$(): ReplaySubject<Array<GameProvider>> {
    return this._filterList$;
  }

  /**
   * Access to provider list from outside
   */
  get list(): Array<GameProvider> {
    return this._list;
  }

  /**
   * Access to filter provider list from outside
   */
  public get filterProviderList() {
    return this._filterProviderList;
  }

  /**
   * Returns provider by id
   *
   * @param id
   */
  getById(id): GameProvider {
    return this._list.find(provider => provider.id === id.toString());
  }

  /**
   * Returns provider by name
   *
   * @param alias
   */
  getByAlias(alias: string): GameProvider {
    alias = alias.toLowerCase().replace(' ', '-').replace('_', '-');
    return this._list.find(provider => provider.alias.toLowerCase() === alias);
  }

  /**
   * Get provider list from CMS and save to local variables
   */
  updateList() {
    let params = {};

    if (this._user.auth) {
      params = {
        country: this._user.info.country
      };
    }

    this._api.gameProviderList(params).pipe(
      map(response => {
        return (response.data || []).map(provider => {
          const resolvedAlias = this.resolveAlias(provider.alias.toLowerCase());
          return {
            ...provider,
            id: provider.id.toString(),
            alias: resolvedAlias,
            imgAlias: this.resolveImgAlias(resolvedAlias),
          };
        });
      }),
      map(list => this._filter.sortByProp(list, 'name')),
      tap(list => {
        this._list = list;
        this._filterProviderList = list.filter(e => e.inFilter);
        isProvidersHasPragmatic.set(this._isHasPragmatic());
        this._filterList$.next(this._filterProviderList);
        this._list$.next(this._list);
      })
    ).subscribe();
  }

  /**
   * Fix aliases to fit SS naming convention (mostly to get correct logos from SS backend)
   * Cover some custom cases when we need to change one game for another
   */
  private resolveAlias(alias: string) {
    switch (alias) {
      case 'playson_infin':
        return 'playson';
      case 'stormgames':
      case 'gamelab':
        return 'quickfire';
      default:
        return alias;
    }
  }

  /**
   * Prepare imgAlias to use as logos src
   * Default return src from SS backend
   * For custom cases use logos from /assets
   */
  private resolveImgAlias(alias: string) {
    switch (alias) {
      case 'quickfire':
      case 'gamelab':
      case 'stormgames':
        return '/assets/svg/provider-logos/quickfire.svg';
      case 'goldcoinstudios':
        return '/assets/svg/provider-logos/goldcoinstudios-custom.svg`';
      case 'wazdan':
        return '/assets/svg/provider-logos/voltent.svg';
      default:
        return 'https://cdn.softswiss.net//logos/providers/white/' + alias + '.svg';
    }
  }

  /**
   * Hide drops category if no pragmatic in filter
   */
  private _isHasPragmatic(): boolean {
    return this._filterProviderList.some(item => item.alias === 'pragmaticplay');
  }
}
