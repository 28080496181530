export const DEFAULT_TRANSLATES: ITranslates = {
  title: 't.lootbox-default-title',
  cardDesc: 't.lootbox-default-desc-card',
  modalTitle: 't.lootbox-default-title-modal',
};

interface ITranslates {
  title: string;
  cardDesc: string;
  modalTitle: string;
}

interface ITranslatesObject {
  [key: string]: ITranslates;
}

export interface ILootboxesTranslatesCMS {
  FieldModifyTranslations: string;
  Translates: ITranslatesObject;
}
