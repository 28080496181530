import { InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export const SsWsConfig = new InjectionToken<SsWebSocketsConfig>('SsWsConfig');

export interface SsWebSocketsConfig {
  url: string;
  user: ReplaySubject<string>;
  timestamp: string;
  token: string;
}

export enum SsWebSocketsPublicChanel {
  LATEST_WINNERS = 'public:wins',
  JACKPOTS = 'public:jackpots_changes'
}

export enum SsWebSocketsPrivateChanel {
  COMP_POINTS = 'comps_award',
  BONUSES_CHANGED = 'bonuses_changes',
  FREESPINS_CHANGED = 'freespins_changes',
  LOOTBOXES_CHANGED = 'lootboxes_changes',
  PAYMENTS_CHANGED = 'payments_changes',
  GAME_LIMITS= 'game_limits',
  TOURNAMENTS_STATUSES= 'tournaments_statuses',
  TOURNAMENTS_STARTED = 'tournament_notifications',
  GROUPS_UPDATES= 'groups_updates',
  JACKPOT_WIN_AWARD = 'jackpot_win_award',
  USER_BALANCE = 'balance',
}
