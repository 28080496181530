export enum EventType {
  First = 'first',
  Second = 'second',
  Third = 'third'
}

export interface WelcomePromoModal {
  Title: string;
  Description: string;
  LogoImage: string;
  MainImage: string;
  Prize: string;
  ButtonUrl: string;
}

/**
 * Interface for main promo page
 */
export interface IPromo {
  Title: string;
  PrizeTitle: string;
  PrizeSubtitle: string;
  Description: string;
  BonusModalPrizeTitle: string;
  BonusModalTitle: string;
  BonusModalDescription: string;
  BonusModalInformer: string;
  BonusCode: string;
  GamesTitle: string;
  GamesDescription: string;
  Terms: string;
  AccordionText: string;
  TopImage: string;
  GroupList: any;
  PrizepoolTitle: string;
  WheelTitle: string;
  WheelDesc: string;
  HuntTitle: string;
  HuntDescription: string;
  HuntSubtitle: string;
  PrizePool: any;
  Steps: any;
  timeLeft: any;
  timeStatus: any;
  CalendarTitle: any;
  HuntSteps: any;
  Bonuses: any;
  ProvidersTitle: any;
  ProvidersDesc: any;
  VoteMode: any;
}

/**
 * Interface for hunt catcher modals
 */
export interface IHunt {
  Title: string;
  Description: string;
  Prize: string;
  Game: string;
  BonusCode: string;
  BonusGroupCatch: string;
  BonusGroupClaimed: string;
  FinalModalTitle: string;
  FinalModalCondition: string;
}
