<div class="container">
  <div class="nav-container">
    <div class="nav">
      @defer (on viewport) {
        @if (ask.linkBottomEnabled) {
          <a class="nav__item" [routerLink]="['/', lang.current, 'promo', 'askgamblers']">{{'link.askgamblers' | translate}}</a>
        }
        <a class="nav__item" [routerLink]="['/', lang.current, 'about']">{{'link.about' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'blog']">{{'link.blog' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'banking']">{{'link.banking' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'faq']">{{'link.faq' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'terms-and-conditions']">{{'link.t&c' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'bonus-terms']">{{'link.bonus-terms' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'privacy-policy']">{{'link.pp' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'cookie-policy']">{{'link.cookie-policy' | translate}}</a>
        <a class="nav__item" [routerLink]="['/', lang.current, 'responsible-gaming']">{{'link.res-game' | translate}}</a>
        <a class="nav__item" target="_blank" href="https://www.samuraipartners.com">{{'link.affiliate' | translate}}</a>
        @if (installApp.isEnableNativeApp) {
          <a class="nav__item" [routerLink]="['/', lang.current, 'mobile-app']">{{'link.instal-app' | translate}}</a>
        }
      } @placeholder {
        <div class="nav__item"></div>
      }
    </div>
  </div>
</div>
