import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Modal } from '../../modal';
import { showHide } from '../../modal-animations';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { UserService } from '../../../services/user/user.service';
import {ScrollService} from '../../../services/scroll.service';

@Component({
    selector: 'app-modal-low-balance',
    templateUrl: './modal-low-balance.component.html',
    styleUrls: ['./modal-low-balance.component.scss'],
    animations: [showHide],
})
export class ModalLowBalanceComponent extends Modal implements OnInit {

  @ViewChild('content', { static: true, read: ViewContainerRef }) private contentRef: ViewContainerRef;

  constructor(
    private _gtm: GoogleTagManagerService,
    private _user: UserService,
    private _scroll: ScrollService,
  ) {
    super();
  }

  ngOnInit() {

  }

  onClose() {
    this._gtm.lowBalancePopupClose('popup_close', this._user.info.id);
    this.close();
    this._scroll.UnblockScroll();
  }

}
