export const LoyaltyGroups = [
  'woodensword',
  'nunchaku',
  'Ronin',
  'Kenin',
  'Hatamoto',
  'Daimyo',
  'Shogun',
  'Genin',
  'Chunin',
  'Jonin'
];
