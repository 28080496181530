"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Form = void 0;
__exportStar(require("./player"), exports);
var Form;
(function (Form) {
  var MethodFieldType;
  (function (MethodFieldType) {
    MethodFieldType["Amount"] = "amount";
    MethodFieldType["Email"] = "email";
    MethodFieldType["CreditCardNumber"] = "credit_card_number";
    MethodFieldType["Text"] = "text";
    MethodFieldType["CardHolder"] = "card_holder";
    MethodFieldType["CardExpiryDate"] = "card_expiry_date";
    MethodFieldType["Tel"] = "tel";
    MethodFieldType["Cvv"] = "cvv";
    MethodFieldType["Textarea"] = "textarea";
    MethodFieldType["Password"] = "password";
    MethodFieldType["CryptoAddress"] = "crypto_address";
    MethodFieldType["MultiProtocolCryptoAddress"] = "multi_protocol_crypto_address";
    MethodFieldType["Metamask"] = "metamask";
    MethodFieldType["DepositCryptoAddress"] = "deposit_crypto_address";
    MethodFieldType["CashoutCryptoAddress"] = "cashout_crypto_address";
    MethodFieldType["Select"] = "select";
    MethodFieldType["CascadeSelect"] = "cascade_select";
    MethodFieldType["Banner"] = "banner";
    MethodFieldType["CryptoCurrencyAccountSelect"] = "crypto_currencies_account_select";
    MethodFieldType["Button"] = "button";
  })(MethodFieldType = Form.MethodFieldType || (Form.MethodFieldType = {}));
})(Form = exports.Form || (exports.Form = {}));