@if (user.auth) {
  <div class="dropdown" ClickOutside (clickOutside)="notify.close()">
    @if (notify.notifications$ | async; as notifications) {
      <div class="dropdown__header" (click)="notify?.toggle()">
        @if (notifications.length) {
          <img class="bell" [ngClass]="{'bell--animate': notify.animateBell}"
            src="/assets/img/notification/bell-active.png" alt="">
          } @else {
            <img class="bell" src="/assets/img/notification/bell.png" alt="">
          }
          <span class="dropdown__count" [ngClass]="{opened:notify?.isNotUnique}">
            {{notifications.length || 0}}
          </span>
        </div>
        <div class="dropdown-menu">
          <app-notification-center [lists]="notifications"></app-notification-center>
        </div>
      }
    </div>
  }
