"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Arguments = void 0;
var Arguments;
(function (Arguments) {
  var OnCloseStatus;
  (function (OnCloseStatus) {
    /**
     * The payment was successful and user closed popup via "back to merchant" button
     */
    OnCloseStatus["Success"] = "success";
    /**
     * The payment was unsuccessful and user closed popup via "back to merchant" button
     * or
     * The payment was cancelled via "cancel" button
     */
    OnCloseStatus["Failure"] = "failure";
    /**
     * The popup was closed on 'x' button click, there is no way to check if payment was successful or not
     */
    OnCloseStatus["Unknown"] = "unknown";
  })(OnCloseStatus = Arguments.OnCloseStatus || (Arguments.OnCloseStatus = {}));
})(Arguments = exports.Arguments || (exports.Arguments = {}));