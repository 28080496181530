import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import { LoginFormComponent } from '../../../../modules/login-form/login-form.component';

@Component({
    selector: 'app-sign-in-modal',
    templateUrl: './sign-in-modal.component.html',
    styleUrls: ['./sign-in-modal.component.scss'],
    standalone: true,
    imports: [LoginFormComponent]
})
export class SignInModalComponent implements OnInit, ModalContent {

  public modal: ModalRef;

  constructor() { }

  ngOnInit() {
  }

}
