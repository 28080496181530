@if (offer$ | async; as offer) {
  <div class="offer">
    <div class="offer__text">
      <div class="offer__title" [innerHTML]="offer?.Title | safe: 'html'"></div>
      <img [src]="offer?.Image" alt="Offer image" class="offer__img mobile">
      <div class="offer__description" [innerHTML]="offer?.Desc | safe: 'html'"></div>
      @if (offer?.game$ | async; as game) {
        <div class="offer__game"
          [innerHTML]="'t.on-game-slot' | translate: {game: game.name} | safe: 'html'"
          (click)="openGame($event, game)"
        ></div>
      }
      <div class="offer__actions">
        <div (click)="onDepositClick(offer?.ButtonRoute)" class="btn btn--filled-primary big">{{ offer?.ButtonAction || ('btn.get-bonus' | translate) }}</div>
        <div class="offer__details" (click)="openPhoneTermsInfoModal(offer)" [ngClass]="{'text-gradient': rtl.isAr}">
          {{ 't.how-it-works' | translate }}
          <div class="offer__details_chevron"></div>
        </div>
      </div>
    </div>
    <img [src]="offer?.Image" alt="Offer image" class="offer__img desktop">
  </div>
}
