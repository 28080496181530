import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { UserService } from './user/user.service';
import { TimeService } from './time.service';
import { LocalstorageService } from './localstorage.service';
import { SsApiService } from './api/ss-api.service';
import { convertDobFormat, encodeToBase64, encodeToHex } from '../helpers/utils';
import { WindowService } from './window.service';
import { PaymentsMethod } from '../vendor/ss-payments-v2/ss-payment-types';
import { UserPhoneService } from './user/user-phone.service';

export const LOCAL_STORAGE_FRAUD_KEY_DEPOSIT = 'deposit';
export const LOCAL_STORAGE_FRAUD_KEY_CASHOUT = 'cashout';

export const BRAND_ID = 'SpinSamurai';

export const FIRST_TIME_DEPOSIT_ACTION = 'first_time_deposit';
export const SECOND_TIME_DEPOSIT_ACTION = 'second_time_deposit';
export const FIRST_TIME_CASHOUT_ACTION = 'first_time_cashout';

declare let seon: any;

@Injectable({
  providedIn: 'root',
})
export class FraudCheckService {

  private _currentIp: string;

  constructor(
    private _api: CmsApiService,
    private _user: UserService,
    private _time: TimeService,
    private _storage: LocalstorageService,
    private _ssApi: SsApiService,
    private _window: WindowService,
    private _phone: UserPhoneService,
  ) {
    this._ssApi.currentIp().subscribe(({ ip }) => {
      this._currentIp = ip;
    });
  }

  /**
   * Check SEON fraud user data
   */
  public postFraudData(dataStr: string, uid?: string) {
    return this._api.postBonusCheck(dataStr, { f: 'b', uid });
  }

  public async setCashoutUserData(method: PaymentsMethod, formData: any, actionType: string, savedAccount: any) {
    try {
      const session = await this._getSeonFingerprintSession();
      const body = {
        ...this._mainSetupFields(formData, actionType, session, method),
        userFullname: `${formData?.first_name || this._user.info?.first_name} ${formData?.last_name || this._user.info?.last_name}`,
        userName: formData?.first_name || this._user.info?.first_name,
        userDob: convertDobFormat(formData?.date_of_birth) || this._user.info?.date_of_birth,
        userCity: formData?.city || this._user.info?.city,
        userZip: formData?.postal_code || this._user.info?.postal_code,
        userStreet: formData?.address || this._user.info?.address,
        userStreet2: formData?.address || this._user.info?.address,
        phoneNumber: formData?.mobile_phone || this._user.info.mobile_phone,
        userBankAccount: formData?.accountNumber || formData?.iban,
        userBankName: formData?.bankName,
        cardFullname: savedAccount?.cardHolder,
        cardBin: savedAccount?.title?.substring(0, 6),
        cardExpire: savedAccount?.expiryDate,
        cardLast: savedAccount?.title?.substring(savedAccount?.title.length - 4),
      };
      this._storage.set(LOCAL_STORAGE_FRAUD_KEY_CASHOUT, encodeToHex(encodeToBase64(JSON.stringify(body))));
    } catch (e) {
      console.error(e);
    }
  }


  public async setDepositUserData(method: PaymentsMethod, formData: any, actionType: string, savedAccount: any) {
    try {
      const session = await this._getSeonFingerprintSession();
      const body = {
        ...this._mainSetupFields(formData, actionType, session, method),
        userFullname: `${formData?.first_name || this._user.info?.first_name} ${formData?.last_name || this._user.info?.last_name}`,
        userName: formData?.first_name || this._user.info?.first_name,
        userDob: this._convertDobFormat(formData?.date_of_birth) || this._user.info?.date_of_birth,
        userCity: formData?.city || this._user.info?.city,
        userZip: formData?.postal_code || this._user.info?.postal_code,
        userStreet: formData?.address || this._user.info?.address,
        userStreet2: formData?.address || this._user.info?.address,
        phoneNumber: formData?.mobile_phone ? `${this._phone.prefix}${formData?.mobile_phone}` : this._user.info.mobile_phone,
        cardFullname: !savedAccount ? formData?.cardHolder ? formData?.cardHolder : null : savedAccount?.cardHolder,
        cardBin: !savedAccount ?
          formData?.encCreditcardNumber ? formData?.encCreditcardNumber.substring(0, 6) : null :
          savedAccount?.title?.substring(0, 6),
        cardExpire: !savedAccount ?
          formData?.expiry_date ? `${formData?.expiry_date?.replace('/', '-')}` : null :
          savedAccount?.expiryDate,
        cardLast: !savedAccount ?
          formData?.encCreditcardNumber ? formData?.encCreditcardNumber.substring(formData?.encCreditcardNumber.length - 4) : null :
          savedAccount?.title?.substring(savedAccount?.title.length - 4),
      };
      this._storage.set(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT, encodeToHex(encodeToBase64(JSON.stringify(body))));
    } catch (e) {
      console.error(e);
    }
  }


  /**
   * Check if body length
   */
  public isBodyFraudStorageLength(dataType: string): boolean {
    return Boolean(this._storage.get(dataType) && this._storage.get(dataType).length);
  }

  private _convertDobFormat(date: string) {
    if (date) {
      const parts = date.split('/');
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      return `${year}-${month}-${day}`;
    }
  }

  private async _getSeonFingerprintSession() {
    seon = this._window.nativeWindow['seon'] || {};
    seon.config({
      session_id: `${BRAND_ID}-${Math.random().toString(36).substring(2, 9)}`,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      silent_mode: false,
      max_proxy_delay: 1000,
    });
    return await seon.getBase64Session();
  }


  /**
   * Main seon fields
   * @param formData
   * @param actionType
   * @param session
   * @param method
   * @private
   */
  private _mainSetupFields(formData: any, actionType: string, session: string, method: PaymentsMethod) {
    return {
      session,
      ip: this._currentIp,
      actionType,
      affiliateId: this._storage.get('stag') ? this._storage.get('stag')?.split('_')[0] : null,
      email: this._user.info?.email,
      emailDomain: this._getEmailDomain(this._user.info?.email),
      userId: `${BRAND_ID}-${this._user.info?.id}`,
      userVerificationLevel: this._user.info?.verified ? 'ID_verified' : null,
      userCreated: this._time.dateToTimestamp(this._user.info.created_at),
      userCountry: this._user.info.country,
      userBalance: this._user.currentCurrency.amount,
      regulation: 'Curacao',
      paymentProvider: method?.provider,
      paymentMode: method?.brand,
      transactionAmount: formData && formData?.amount,
      transactionCurrency: method?.currency,
      brandId: BRAND_ID,
    };
  }


  /**
   * Substring email domain
   * sverevkin25@gmail.com => gmail.com
   * @param email
   * @private
   */
  private _getEmailDomain(email: string) {
    return email.substring(email.indexOf('@') + 1);
  }
}
