"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnumGender = void 0;
var EnumGender;
(function (EnumGender) {
  EnumGender["M"] = "m";
  EnumGender["F"] = "f";
})(EnumGender = exports.EnumGender || (exports.EnumGender = {}));