@if (countTickets$ | async; as tickets) {
  <div class="tickets--container">
    <div class='tickets'>
      <div class='tickets__inner'>
        <div class='tickets__value'>{{lottery.playerTickets}}</div>
        <div class='tickets__title'>
          {{(lottery.playerTickets === 1 ? 't.samurai-lottery-ticket' : 't.samurai-lottery-tickets') | translate}}
        </div>
      </div>
      <app-form-checkbox-toggle [checked]="lotteryStatus" (change)="changeLottery($event)"></app-form-checkbox-toggle>
    </div>
  </div>
}
