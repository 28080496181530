import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformService } from './platform.service';
import { shareReplay } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  /**
   * Storage for script loaders observables by url
   */
  private _scriptLoaders: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  constructor(
    private _platform: PlatformService,
    @Inject(DOCUMENT) private _document: Document,
  ) { }

  /**
   * Load script by url and append to provided HTML element or head if element not provided
   *
   * @param url
   * @param target
   * @param attributes
   */
  load(url: string, target: HTMLElement | string = 'head', attributes?: object): Observable<any> {
    if (!this._platform.isBrowser) {
      return;
    }

    if (this._scriptLoaders.has(url)) {
      return this._scriptLoaders.get(url);
    }

    const loader = new Observable<any>(observer => {
      const script: HTMLScriptElement = this._document.createElement('script');

      if (attributes) {
        Object.keys(attributes).forEach(key => script.setAttribute(key, attributes[key]));
      }

      script.onload = (e: Event) => {
        observer.next(e);
        observer.complete();
      };

      script.onerror = error => {
        observer.error(error);
      };

      script.src = url;

      const targetEl: HTMLElement = typeof target === 'string' ?
        this._document.querySelector(target) :
        target;

      targetEl.appendChild(script);
    }).pipe(
      shareReplay(1)
    );

    this._scriptLoaders.set(url, loader);

    return loader;
  }
}
