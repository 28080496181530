"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LibError = void 0;
var LibError;
(function (LibError) {
  var Type;
  (function (Type) {
    Type["Unknown"] = "unknown_error";
    Type["Request"] = "request_error";
    Type["Params"] = "params_error";
    Type["PopUp"] = "pop_up";
  })(Type = LibError.Type || (LibError.Type = {}));
  var Status;
  (function (Status) {
    // 5**
    Status["NoProviderFound"] = "ERR_DECLINED_NO_PROVIDER_FOUND";
  })(Status = LibError.Status || (LibError.Status = {}));
})(LibError = exports.LibError || (exports.LibError = {}));