<div class="balance" routerLink="/profile/deposit">
  <div class="icons-block">
    <img class="balance__icon" [src]="breakpoints.isMobile ? '/assets/img/new-categories/balance-mobile.png' : '/assets/img/new-categories/balance.png'" alt="">
    <span class="balance__label-header">{{'h.deposit' | translate}}</span>
  </div>
  @if (balance$ | async; as balance) {
    <span class="balance__label">{{ user.currentCurrency?.symbol || balance?.currency }} {{ balance?.amount | currencyFormat:balance?.currency }}</span>
  } @else {
    <span class="balance__label">{{ user.currentCurrency?.symbol || user.currentCurrency?.currency }} {{ user.currentCurrency?.amount | currencyFormat:user.currentCurrency?.currency }}</span>
  }
</div>
<div class="actions">
  <button class="btn btn--filled-primary" (click)="onDepositClick(!motivateWithdrawal.isMotivateWithdrawalAvailable)" routerLink="/profile/deposit">{{(motivateWithdrawal.isMotivateWithdrawalAvailable ? 'btn.cashout' : 'btn.deposit') | translate}}</button>
</div>
<span class="username" routerLink="/profile/account">{{ user.info.nickname }}</span>
@if (userImg) {
  <img routerLink="/profile/account" class="username__icon" [src]="userImg" alt="user-icon">
}

