import { ModalRef } from './modal-ref';
import { ModalTemplateType } from './templates';

export interface ModalOptions {
  template?: ModalTemplateType;
  disableOverlayClosing?: boolean;
  data?: any;
  closeAfterRouteChange?: boolean;
}

export abstract class Modal {
  instance: ModalRef;
  options: ModalOptions;

  close(output?: any): void {
    this.instance.close(output);
  }

  dismiss(output?: any): void {
    this.instance.dismiss(output);
  }
}
