@if (modalInfo$ | async; as modalInfo) {
  <div class="popup__wrapper">
    <div class="popup">
      <div class="deposit">
        <i class="deposit__close icon-close" (click)="modal.close()"></i>
        <div class="deposit__info">
          <h2 class="deposit__info_bonus" [innerHTML]="modalInfo?.Title | safe: 'html'"></h2>
          <div class="deposit__info_desc" [innerHTML]="modalInfo?.Description | safe: 'html'"></div>
          <div class="deposit__info_winners">
            <div [innerHTML]="modalInfo?.WinnersTitle | safe: 'html'"></div>
            <ul>
              @for (winner of modalInfo?.Winners; track winner) {
                <li>
                  <span class="prize">{{ winner?.prize }}</span>
                  @if (winner?.game$ | async; as game) {
                    <img class="game-img"
                      ClassLoaded
                      defaultImage
                      [alternative]="game['bgSrc']"
                      [src]="game['imgSrc']"
                      [alt]="game.name || ''"
                      >
                      <span class="game-name">{{ game?.name }}</span>
                    }
                  </li>
                }
              </ul>
            </div>
            <button class="btn btn--filled-primary" (click)="onDepositBtnCLick()">{{'t.deposit-now' | translate}}</button>
          </div>
          @if (!breakpoints.belowSmallDesktop) {
            <img ClassLoaded class="deposit__img" src="/assets/img/deposit-bonus/deposit-bonus.png" alt="">
          }
          @if (breakpoints.belowSmallDesktop) {
            <img ClassLoaded class="deposit__img" src="/assets/img/deposit-bonus/deposit-bonus-mobile.png" alt="">
          }
        </div>
      </div>
    </div>
  } @else {
    <div class="popup__wrapper">
      <div class="popup">
        <app-preloader></app-preloader>
      </div>
    </div>
  }
