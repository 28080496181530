import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { GlobalEventsService } from '../../core/services/global-events.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export enum AccountPage {
  DEPOSIT = 'deposit',
  CASHOUT = 'cashout',
  BALANCE = 'balance',
  BONUSES = 'bonuses',
  HISTORY = 'history',
  DOCUMENTS = 'documents',
  INFO = 'info',
  RESPONSIBLE_GAMING = 'responsible-gaming',
  ACCOUNT = 'account',
  SECURITY = 'security'
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _cashierLinks = [
    {url: '/profile/deposit', img: '/assets/img/new-categories/balance-mobile.png', tab: 'h.deposit'},
    {url: '/profile/cashout', img: '/assets/img/new-categories/withdraw.png', tab: 'h.cashout'},
    {url: '/profile/history', img: '/assets/img/new-categories/history.png', tab: 'h.history'},
  ];

  private _settingsLinks =  [
    {url: '/profile/deposit', img: '/assets/img/new-categories/balance-mobile.png', tab: 'h.deposit'},
    {url: '/profile/account', img: '/assets/img/new-categories/account.png', tab: 'h.account'},
    {url: '/loyalty-program', img: '/assets/img/new-categories/loyalty-link.png', tab: 'h.loyalty'},
  ];

  /**
   * Data for all account pages
   */
  private _pageData = {
    [AccountPage.DEPOSIT]: {title: 'h.deposit', links: this._cashierLinks},
    [AccountPage.CASHOUT]: {title: 'h.cashout', links: this._cashierLinks},
    [AccountPage.BALANCE]: {title: 'h.balance', links: this._cashierLinks},
    [AccountPage.BONUSES]: {title: 'h.bonuses', links: this._cashierLinks, page: AccountPage.BONUSES},
    [AccountPage.HISTORY]: {title: 'h.history', links: this._cashierLinks},
    [AccountPage.DOCUMENTS]: {title: 'h.documents', links: this._settingsLinks},
    [AccountPage.INFO]: {title: 'h.player-info', links: this._settingsLinks},
    [AccountPage.RESPONSIBLE_GAMING]: {title: 'h.res-gaming', links: this._settingsLinks},
    [AccountPage.ACCOUNT]: {title: 'h.account', links: this._settingsLinks},
  };

  /**
   * Current account page
   */
  private _page: AccountPage;

  constructor(
    private _globalEvent: GlobalEventsService,
  ) {
    this._handleRoute();
    this._resolvePageTitle();
  }

  /**
   * Returns current page data
   */
  get pageData(): {title: string, links: any[], page?: string} {
    return this._pageData[this._page] || null;
  }

  /**
   *
   */
  get settingsLinks() {
    return this._settingsLinks;
  }

  /**
   * Set current page title
   *
   * @param page
   */
  setPage(page: AccountPage) {
    this._page = page;
  }

  /**
   * Handle routes change for detecting current account page
   *
   * @private
   */
  private _handleRoute() {
    this._globalEvent.routerNavigationEnd$.pipe(
      untilDestroyed(this),
      filter(event => event.url.includes('/profile/')),
      tap((event: NavigationEnd) => {
        const page = /\/profile\/(.+?)($|\?)/.exec(event.url)[1];
        this.setPage(page.split('/')[0] as AccountPage);
      })
    ).subscribe();
  }

  /**
   * Resolve page data by ab test
   * @private
   */
  private _resolvePageTitle() {
    Object.entries(this._pageData).forEach(([key, value]) => {

      if ([AccountPage.DEPOSIT, AccountPage.CASHOUT, AccountPage.HISTORY, AccountPage.ACCOUNT].includes(key as AccountPage)) {
        value.title = '';
      }
    });
  }
}
