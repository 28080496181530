import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {BlogCategory} from '../blog-articles-list/blog-articles-list.component';
import { RouterLinkDirective } from '../../../core/shared/directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { SetImgAltDirective } from '../../../core/shared/directives/set-img-alt.directive';
import { NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-blog-article-preview',
    templateUrl: './blog-article-preview.component.html',
    styleUrls: ['./blog-article-preview.component.scss'],
    standalone: true,
    imports: [NgClass, SetImgAltDirective, RouterLink, RouterLinkDirective, DatePipe]
})
export class BlogArticlePreviewComponent implements OnInit {

  @HostBinding('class.promotion') isPromotion = false;

  /**
   * Article to display
   */
  @Input() article: any;

  /**
   * Is current component uses as preloader
   */
  public preloader: boolean;

  constructor() { }

  ngOnInit() {
    if (!this.article) {
      this.preloader = true;
    } else {
      this.isPromotion = this.article?.type?.id === BlogCategory.PROMOTIONS;
    }

  }

}
