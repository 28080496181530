import {Component, Input, OnInit} from '@angular/core';
import {CommonBonusInterface} from '../../account/account-settings/account-tab/interfaces/common-bonus.interface';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { BonusPreviewComponent } from '../../../core/shared/standalone/bonus-preview/bonus-preview.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-welcome-bonuses-list',
    templateUrl: './welcome-bonuses-list.component.html',
    styleUrls: ['./welcome-bonuses-list.component.scss'],
    standalone: true,
    imports: [NgClass, BonusPreviewComponent, SafePipe]
})
export class WelcomeBonusesListComponent implements OnInit {

  @Input() bonuses: CommonBonusInterface[] = [];
  @Input() title: string;

  constructor() {}

  ngOnInit() {
  }
}
