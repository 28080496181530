import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessLinkService {

  constructor() { }

  /**
  * Return href from click event
  * @param e
  */
  public getHrefFromClickEvent(e) {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'A') {
      return element.getAttribute('href');
    }
  }

  /**
   * Get id attribute from click event
   * @param e
   */
  public getIdAttributeFromClickEvent(e) {
    const element: HTMLElement = e.target;
    if (element && element.id) {
      return element.id;
    }
  }
}
