<app-form-input [control]="primary">
  <app-select class="select" [formControl]="primary">
    <app-select-header class="select__header">{{ field.label }}</app-select-header>
    <app-select-dropdown class="select__dropdown">
      @for (value of field.options; track value) {
        <app-select-option class="select__option" [value]="value.value" (click)="updateSecondaryOptions(value.value)">{{ value.label }}</app-select-option>
      }
    </app-select-dropdown>
  </app-select>
</app-form-input>

<app-form-input [control]="secondary">
  <app-select class="select" [formControl]="secondary">
    <app-select-header class="select__header">{{ field.label }}</app-select-header>
    <app-select-dropdown class="select__dropdown">
      @for (value of secondaryOptions; track value) {
        <app-select-option class="select__option" [value]="value.value">{{ value.label }}</app-select-option>
      }
    </app-select-dropdown>
  </app-select>
</app-form-input>
