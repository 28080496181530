import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Router} from '@angular/router';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UserService} from '../../../../services/user/user.service';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../services/google-tag-manager.service';
import {LocalHistoryService} from '../../../../services/local-history.service';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { SelectOptionComponent } from '../../../../modules/select/select-option/select-option.component';
import { SelectDropdownComponent } from '../../../../modules/select/select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from '../../../../modules/select/select-header/select-header.component';
import { SelectComponent } from '../../../../modules/select/select.component';


@Component({
    selector: 'app-game-not-available',
    templateUrl: './game-not-available.component.html',
    styleUrls: ['./game-not-available.component.scss'],
    standalone: true,
    imports: [SelectComponent, FormsModule, ReactiveFormsModule, SelectHeaderComponent, SelectDropdownComponent, SelectOptionComponent, TranslatePipe]
})
export class GameNotAvailableComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  /**
   * For correct select working
   */
  public currencyControl: UntypedFormControl = new UntypedFormControl(null);

  public isSupported: boolean;

  constructor(
    public user: UserService,
    private _gtm: GoogleTagManagerService,
    private _localHistory: LocalHistoryService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.GAME_NOT_AVAILABLE,
      POPUP_EVENT_ACTIONS.GAME_NOT_AVAILABLE
    );
  }

  onOkClick() {
    this._gtm.gameNotAvailableClick('game_not_found', this.modal.options.data.game);
    this.modal.close();
  }

  onPlayClick() {
    this._router.navigateByUrl(`play/${this.modal.options.data.gameSlug}`)
      .then(() => {
        this.modal.close();
    });
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    this.user.changeCurrencyAcc($event);
    if (this._findSupportedCurrency($event)) this.isSupported = true;
  }

  private _findSupportedCurrency(currency: string) {
   return this.modal.options.data.currencyList.some((item) => {
      return item === currency;
    });
  }

}
