<div class="ndfs-card">
  <div class="ndfs-card__info">
    <div class="ndfs-card__info--text">
      <div class="ndfs-card__info--text__status">
        {{translateKeys?.status | translate}}
      </div>
      <div class="ndfs-card__info--text__title">
        {{translateKeys?.title | translate}}
      </div>
      <div class="ndfs-card__info--text__desc">
        {{translateKeys?.desc | translate}}
      </div>
    </div>
    <div class="ndfs-card__info--image">
      <img src="/assets/img/coin.png" alt="">
    </div>
  </div>
  <div class="ndfs-card__confirm">
    <img src="/assets/svg/attention.svg" alt="">
    {{translateKeys?.confirm | translate}}
  </div>
  <div class="ndfs-card__action">
    <button (click)="onConfirm()" class="btn btn--filled-primary">
      {{translateKeys?.confirm_email | translate}}
    </button>
  </div>
</div>
