import { Injectable } from '@angular/core';
import { GameProvidersService } from '../game-providers.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CmsApiService } from '../api/cms-api.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GameAttribute } from './game-attribute';
import { GameCategory } from './game-category';
import { UpdateOnLangChange } from '../../shared/decorators/update-on-lang-change';
import { CmsContentMapperService } from '../cms-content-mapper.service';
import { HostRequestsInterceptor } from '../../interceptors/host-requests.interceptor';

@Injectable({
  providedIn: 'root'
})
export class GamesService {

  /**
   * SS host for game images
   */
  private _ssImageHost = 'https://cdn.softswiss.net/i/s3/';

  /**
   * SS host for game background images
   */
  private _ssBackgroundHost = 'https://cdn2.softswiss.net/b/';

  /**
   * For checked when deposit modal open
   */
  public openDepositModalGame$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Current game state
   */
  public currentGameState = null;

  constructor(
    private _providers: GameProvidersService,
    private _api: CmsApiService,
    private _cmsMapper: CmsContentMapperService,
    private _hostInterceptor: HostRequestsInterceptor
  ) {}

  /**
   * Use this function for loading games from cms
   *
   * @param params
   */
  list(params: object = {}): ReplaySubject<any> {
    return this._providers.list$.pipe(
      switchMap(() => this._api.gameList(params)),
      map(response => this._gameListResponseMapper(response.data))
    ) as ReplaySubject<any>;
  }

  /**
   * Use this function for loading game content from cms
   *
   * @param params
   */
  @UpdateOnLangChange('/game/content/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.gameContent(params).pipe(
      map(res => this._cmsMapper.mapCmsTemplate(res.data.list ? {content: res.data.list[0].content} : null))
    ) as ReplaySubject<any>;
  }

  /**
   * Prepare game list response for using in frontend
   *
   * @param data
   * @private
   */
  private _gameListResponseMapper(data: { gameList: Array<any>, attrList: Array<any> }): { gameList: Array<any>, attrList: Array<any> } {
    const res = {
      gameList: this.gameListMapper(data?.gameList),
      attrList: data?.attrList || [],
    };


    if ('providerList' in data) {
      (res as any).providerList = data.providerList;
    }
    return res;
  }

  /**
   * Prepare game list for using in frontend
   *
   * @param list
   * @private
   */
  public gameListMapper(list: Array<any>): Array<any> {
    if (!list) {
      return [];
    }

    return list
      .filter(game => game.externalData)
      .map(game => {
        game.attrTranslate = game.attrTranslate || [];

        return {
          ...game,
          provider: this._providers.getById(game.providerId),
          imgSrc: this.resolveImageSrc(game),
          bgSrc: this._ssBackgroundHost + game.externalId + '.jpg',
          popular: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.POPULAR),
          prerelease: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.PRE_RELEASE),
          bitcoin: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.BITCOIN),
          new: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.NEW),
          nodemo: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.NODEMO),
          drops: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.DAILY_DROPS),
          flash: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.FLASH),
          live: (game.categoryTranslate || []).some(game => game.categoryId === GameCategory.LIVE_CASINO),
          jackpot: (game.categoryTranslate || []).some(category => category.categoryId === GameAttribute.JACKPOT),
          mystery: (game.attrTranslate || []).some(attribute => attribute.attrId === GameAttribute.MYSTERY_DROP)
        };
      });
  }

  /**
   * Returns image src for provided game
   *
   * @param game
   * @private
   */
  public resolveImageSrc(game: any): string {
    /**
     * Uses for detecting which image use (From CMS or from SS)
     */
    const extensionList = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg'];

    /**
     * Regexp for checking file extension
     */
    const regexp = new RegExp('(' + extensionList.join('|').replace(/\./g, '\\.') + ')');

    /**
     * If has image from CMS - use it, else - use image from SS
     */
    if (game.imgSrc && regexp.test(game.imgSrc)) {
      return this._hostInterceptor.overrideCmsImgUrl( environment.assets_host + game.imgSrc);
    } else {
      return this._ssImageHost + game.externalId + '.png';
    }
  }

  /**
   * Get game by identifier from SS
   * @param identifier
   */
  public getGameByExternalId(identifier: string): Observable<any> {
    return this.list({
      'external_id[]': identifier,
      offset: 0,
      limit: 25
    }).pipe(
      filter(data => data.gameList[0]),
      map(data => data.gameList[0]),
    );
  }

}
