import { Observable } from 'rxjs';
import { ValidatorFn, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CountryList$, CurrencyList$, GenderList$ } from '../services/common-data.service';
import { CustomValidators } from './custom-validators';
import { ValidationPatterns } from './validation-patterns';

export enum UserFieldType {
  TEXT =  'text',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  PHONE = 'phone',
  RADIO = 'radio',
}

export interface UserFieldDescriptor {
  type: UserFieldType;
  label: string;
  validators?: ValidatorFn[];
  options?: Observable<[string, string][]>;
  name?: string;
}

export const userFields: Map<string, UserFieldDescriptor> = new Map<string, UserFieldDescriptor>([
  ['gender', {
    label: 'labl.gender',
    type: UserFieldType.RADIO,
    validators: [Validators.required],
    options: GenderList$
  }],
  ['date_of_birth', {
    label: 'labl.date-b',
    type: UserFieldType.DATE,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.dateOfBirth),
      CustomValidators.eighteenYearsOld],
  }],
  ['first_name', {
    label: 'labl.first-name',
    type: UserFieldType.TEXT,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.letters),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
    ],
  }],
  ['last_name', {
    label: 'labl.last-name',
    type: UserFieldType.TEXT,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.letters),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
    ],
  }],
  ['nickname', {
    label: 'labl.nickname',
    type: UserFieldType.TEXT,
    validators: [Validators.required],
  }],
  ['mobile_phone', {
    label: 'labl.add-ver-phone',
    type: UserFieldType.PHONE,
    validators: [Validators.required],
  }],
  ['city', {
    label: 'labl.city',
    type: UserFieldType.TEXT,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.onlyLettersAndSpacesAndHyphenBracesPoint),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
      Validators.maxLength(255)
    ],
    name: 'city'
  }],
  ['address', {
    label: 'labl.addres',
    type: UserFieldType.TEXT,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.lettersAndNumbersWithSpacesAndSomeSymbols),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
      Validators.maxLength(255)
    ],
    name: 'address'
  }],
  ['postal_code', {
    label: 'labl.postal-code',
    type: UserFieldType.TEXT,
    validators: [
      Validators.required,
      Validators.pattern(ValidationPatterns.lettersAndNumbersWithSpacesAndSomeSymbols),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
      Validators.maxLength(50)
    ],
    name: 'code'
  }],
  ['country', {
    label: 'labl.country',
    type: UserFieldType.SELECT,
    options: CountryList$.pipe(
      map(list => list.map(country => [country.short.toUpperCase(), country.name]))
    ),
    validators: [Validators.required],
  }],
  ['currency', {
    label: 'labl.currency',
    type: UserFieldType.SELECT,
    options: CurrencyList$.pipe(
      map(list => list.map(currency => [currency.code, currency.code]))
    ),
    validators: [Validators.required],
  }],
  ['state', {
    label: 'labl.state',
    type: UserFieldType.SELECT,
    validators: [Validators.required],
  }],
]);
