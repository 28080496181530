"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommissionType = exports.ProcessingTimeType = void 0;
var ProcessingTimeType;
(function (ProcessingTimeType) {
  /** Transaction is instant  */
  ProcessingTimeType["Instant"] = "instant";
  /** The transaction will be processed within a certain time range */
  ProcessingTimeType["Range"] = "range";
  /** Transaction will be processed in fixed time  */
  ProcessingTimeType["Static"] = "static";
  /** Transaction process time is not defined */
  ProcessingTimeType["None"] = "none";
})(ProcessingTimeType = exports.ProcessingTimeType || (exports.ProcessingTimeType = {}));
var CommissionType;
(function (CommissionType) {
  /** Fixed percentage of the amount */
  CommissionType["Percent"] = "percent";
  /** Range percentage of the amount (ex. min 5% max 10%)  */
  CommissionType["RangePercent"] = "range-percent";
  /** Comissions are fixed value */
  CommissionType["Fixed"] = "fixed";
  /** Without comissions */
  CommissionType["None"] = "none";
})(CommissionType = exports.CommissionType || (exports.CommissionType = {}));