import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DEFAULT_QUICK_SUM,
  DEFAULT_STANDART_SUM_CASHOUT,
  DEFAULT_STANDART_SUM_DEPOSIT,
  QUICK_SUM,
  QUICK_SUM_CURRENCY,
  STANDART_SUM_CASHOUT,
  STANDART_SUM_CURRENCY_CASHOUT,
  STANDART_SUM_CURRENCY_DEPOSIT,
  STANDART_SUM_DEPOSIT,
} from '../../../../../../core/services/user/data/quick-sum.data';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FIRST_DEP, SECOND_DEP, UserService } from '../../../../../../core/services/user/user.service';
import { FormsErrorHandlerService } from '../../../../../../core/services/forms-error-handler.service';
import { CustomLabelForSomeMethod, FieldsConfig, ICustomFieldsConfig } from './fields-config';
import { first, tap } from 'rxjs/operators';
import { CryptoCurrenciesConverterService } from '../../../../../../core/services/crypto-currencies-converter.service';
import { PlatformService } from '../../../../../../core/services/platform.service';
import { CommonDataService } from '../../../../../../core/services/common-data.service';
import { isNullOrUndefined } from '../../../../../../core/helpers/utils';
import { ScrollService } from '../../../../../../core/services/scroll.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { LocalstorageService } from '../../../../../../core/services/localstorage.service';
import { DEP_AMOUNT_STR } from '../../../../../../core/services/lottery.service';
import { ArabicService } from '../../../../../../core/services/arabic.service';
import {
  PaymentsMethod,
  PaymentsMethodField,
  SavedProfile,
} from '../../../../../../core/vendor/ss-payments-v2/ss-payment-types';
import { Form, Payment, Results } from 'payments-lib-types';
import { SsPaymentsV2Service } from '../../../../../../core/vendor/ss-payments-v2/ss-payment.service';
import { UserPaymentSsV2Service } from '../../../../../../core/services/user/user-payment-ss-v2.service';
import { GroupsService } from '../../../../../../core/services/groups.service';
import { FIRST_TIME_DEPOSIT_ACTION, FraudCheckService } from '../../../../../../core/services/fraud-check.service';
import { GeoApifyService } from '../../../../../../core/services/geoapify.service';
import { Observable, of } from 'rxjs';
import { UserFieldType } from '../../../../../../core/helpers/user-fields.data';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AmountSelectorComponent } from './amount-selector/amount-selector.component';
import { TranslatePipe } from '../../../../../../core/shared/translation/translate.pipe';
import {
  CurrencySelectorStandaloneComponent,
} from '../../../../../../core/shared/standalone/currency-selector-standalone/currency-selector-standalone.component';
import { ProcessingTimePipe } from '../../../../../../core/shared/pipes/processing-time.pipe';
import { SafePipe } from '../../../../../../core/shared/pipes/safe.pipe';
import { FormInputComponent } from '../../../../../../core/modules/form-controls/form-input/form-input.component';
import { SelectComponent } from '../../../../../../core/modules/select/select.component';
import { SelectHeaderComponent } from '../../../../../../core/modules/select/select-header/select-header.component';
import { SelectOptionComponent } from '../../../../../../core/modules/select/select-option/select-option.component';
import {
  SelectDropdownComponent,
} from '../../../../../../core/modules/select/select-dropdown/select-dropdown.component';
import { PhoneFormComponent } from '../../../../../../core/shared/components/phone-form/phone-form.component';
import { CascadeSelectComponent } from './cascade-select/cascade-select.component';
import { NgxMaskDirective } from 'ngx-mask';
import { InputNumberDirective } from '../../../../../../core/shared/directives/input-number.directive';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { DepositCryptoAddressComponent } from './deposit-crypto-address/deposit-crypto-address.component';
import { DefaultImage } from '../../../../../../core/shared/directives/default-image.directive';
import { PreloaderComponent } from '../../../../../../core/shared/components/preloader/preloader.component';
import { TabsComponent } from '../../../../../../core/modules/tabs/tabs.component';
import { TabItemComponent } from '../../../../../../core/modules/tabs/tab-item.component';
import { ToastMessageService } from '../../../../../../core/modules/toast-message/toast-message.service';
import { DobMaskDirective } from '../../../../../../core/shared/directives/dob-mask.directive';
import { HtmlElementOnloadDirective } from '../../../../../../core/shared/directives/html-element-onload.directive';
import { ModalService } from '../../../../../../core/modal-v2/modal.service';
import { AbTestNewService } from '../../../../../../core/ab-test/ab-test.service';
import { CreditCardTypeCardBrandName } from 'ngx-unificator/services';
import { AB_TEST_LIST } from '../../../../../../core/ab-test/ab-test.data';

const ASIAN_CURRENCIES = ['VND', 'IDR', 'MYR', 'THB', 'PHP', 'KRW'];

@UntilDestroy()
@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AmountSelectorComponent,
    TranslatePipe,
    CurrencySelectorStandaloneComponent,
    ProcessingTimePipe,
    NgTemplateOutlet,
    SafePipe,
    FormInputComponent,
    SelectComponent,
    SelectHeaderComponent,
    SelectOptionComponent,
    SelectDropdownComponent,
    PhoneFormComponent,
    CascadeSelectComponent,
    NgxMaskDirective,
    InputNumberDirective,
    NgClass,
    ExchangeRatesComponent,
    DepositCryptoAddressComponent,
    DefaultImage,
    PreloaderComponent,
    TabsComponent,
    TabItemComponent,
    DobMaskDirective,
    HtmlElementOnloadDirective,
  ],
})
export class PaymentFormComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly Payment = Payment;
  protected readonly Form = Form;
  protected readonly FieldType = UserFieldType;

  constructor(
    private _formErrors: FormsErrorHandlerService,
    private _ssPayments: SsPaymentsV2Service,
    private _scroll: ScrollService,
    private _platform: PlatformService,
    private _common: CommonDataService,
    private _route: ActivatedRoute,
    private _storage: LocalstorageService,
    private _groups: GroupsService,
    private _fraud: FraudCheckService,
    private _geoApify: GeoApifyService,
    private _toastMessage: ToastMessageService,
    private _abTest: AbTestNewService,
    private _modal: ModalService,
    public converter: CryptoCurrenciesConverterService,
    public user: UserService,
    public rtl: ArabicService,
    public payment: UserPaymentSsV2Service,
  ) {
  }



  /**
   * Active payment account
   */
  get selectedSavedAccount(): SavedProfile {
    return this._selectedSavedAccount;
  }

  /**
   *  List of fields to render
   */
  get fieldsToRender(): Form.MethodField[] {
    return this._fieldsToRender;
  }

  /**
   *  List of fields to render
   */
  get fieldsToRenderProfile(): Form.MethodField[] {
    return this._fieldsToRenderProfile;
  }

  get isCrypto(): boolean {
    return this.method.type === Payment.MethodType.Crypto;
  }

  /**
   * Access to quick sum list
   */
  get sumList(): number[] {
    return this._quickSumList;
  }

  get Config() {
    return FieldsConfig;
  }

  /**
   * Use for hidden some fields
   */
  public get hideBankTransferSelect() {
    return this.action === Payment.Action.Cashout &&  this.method.brand === 'bank_transfer' && ['EUR', 'NOK'].includes(this.user.currentCurrency.currency);
  }

  @ViewChild('amountEl') amountEl: ElementRef;
  @ViewChild('cvv') cvv: HTMLInputElement;

  /**
   * Selected saved account
   */
  private _selectedSavedAccount: SavedProfile;

  /**
   * Selected amount value
   */
  private _selectedAmountValue: any;

  /**
   * List of fields that needs to be filled for payment
   */
  private _fieldsToRender: any[] = [];

  /**
   * List of fields that needs to be filled for payment
   */
  private _fieldsToRenderProfile: any[] = [];

  /**
   * Quick sum list for current payment
   */
  private _quickSumList: number[] = [];

  /**
   * Payment form controller
   */
  public form: UntypedFormGroup;

  /**
   * CVV field info
   */
  public cvvField: any = null;

  private _eventOnClickSavedAcc: any;

  private _amountValue: number | string;

  /**
   * Payment fields
   */
  @Input() method: PaymentsMethod;

  /**
   * Current payment action
   */
  @Input() action: Payment.Action;

  @Input() isModal: boolean;
  /**
   * Form submit event
   */
  @Output() submit$: EventEmitter<any> = new EventEmitter<any>();

  @Output() switchView$: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Selected sum by country
   * @private
   */
  private _selectedSumByCountry = null;

  ngOnInit() {
    this._resolveQueryParams();
  }

  ngOnChanges(): void {
    this._resolveFields$().pipe(
      first(null),
    ).subscribe();
  }

  ngOnDestroy() {
    this._storage.clearItem(DEP_AMOUNT_STR);
    this.payment.citiesSuggestion = [];
    this.payment.addressesSuggestion = [];
    this.payment.zipCodeSuggestion = [];
  }

  showAllMethods() {
    this.isModal = false;
    this.switchView$.emit(this.isModal);
  }

  private _resolveFields$(): Observable<any> {
    if (this.method && this.action) {
      this.form = null;
      this._selectedSavedAccount = this.method?.savedProfiles[0];
      return this._ssPayments.getCurrentMethodFields(
        this.method.id,
        this.method.currency,
        this.payment.currentPaymentAction,
        this._selectedSavedAccount?.id,
      ).pipe(
        first(),
        tap((fields: Results.GetMethodFieldsResult) => {
          this._quickSumList = this._resolveQuickSum(
            this.action, this.method.brand, this.user.currentCurrency.currency);
          this._initForm(fields, this.form?.value);
          this._setCustomDepositAmountFromStorage();
        }),
      );
    } else {
      return of(null);
    }
  }


  submitPayment() {

    if (this.form?.invalid) {
      this._formErrors.applyFormErrors(this.form, null, true);
      if (this.amountEl?.nativeElement) {
        this._scroll.scrollToElement(this.amountEl.nativeElement, 500);
      }
      return;
    }

    const formData = { ...this.form.value };

    if (this.action === Payment.Action.Deposit) {
      this._storage.set('LAST_DEP_DATA', JSON.stringify({
        amount: (formData?.amount),
        currency: this.method?.currency ,
        isFirst: !this._groups.isExistGroup(FIRST_DEP) && !this._groups.isExistGroup(SECOND_DEP)
      }));
    }

    if (this.action === Payment.Action.Cashout && formData.amount > this.user.currentCurrencyCompatibility.cashout) {
      this._toastMessage.info('t.cashout-locked');
      return;
    }

    if (!this._groups.isExistGroup(FIRST_DEP) &&
      this.action === Payment.Action.Deposit) {
      this._fraud.setDepositUserData(this.method, formData, FIRST_TIME_DEPOSIT_ACTION, this._selectedSavedAccount);
    }

    if (formData.expiryYear && formData.expiryMonth) {
      formData.expiry_date = `20${formData.expiryYear}-${formData.expiryMonth}`;
      delete formData.expiryYear;
      delete formData.expiryMonth;
    }

    const playerFieldsData = {};
    const playerFieldsList = this._fieldsToRenderProfile.map(e => e.fieldName);

    Object.keys(formData).forEach(e => {
      if (playerFieldsList.includes(e)) {
        playerFieldsData[e] = formData[e];
      }
    });

    if (playerFieldsData['mobile_phone']) {
      playerFieldsData['mobile_phone'] = '+' + playerFieldsData['mobile_phone'];
    }

    this.submit$.next({
      formData,
      playerFieldsData,
      savedProfileId: this._selectedSavedAccount,
      form: this.form,
    });
  }

  /**
   * Add new saved account
   * @param account
   */
  /**
   * Select payment account
   *
   * @param account
   */
  selectPaymentAccount(account: SavedProfile) {
    if (!isNullOrUndefined(account) && account?.id === this._selectedSavedAccount?.id) {
      return;
    }

    this.cvvField = null;
    this._selectedSavedAccount = account;

    if (this.form?.get('amount')) {
      this._selectedAmountValue = this.form?.get('amount').value; // Save and set the selected value after form init
    }

    this._ssPayments.getCurrentMethodFields(
      this.method.id,
      this.method.currency,
      this.action,
      (this._selectedSavedAccount?.id) || null
    ).pipe(
      first(),
      tap(result => {
        this._initForm(result, this.form?.value);
      })
    ).subscribe();
  }


  getAmountValue(value: number | string) {
    this._amountValue = value;
  }

  /**
   * Init payment form fields
   *
   * @private
   */
  private _initForm(fields: Results.GetMethodFieldsResult, prevFormValue: any = {}): void {

    this.form = new FormGroup({});

    this._fieldsToRenderProfile = [];
    this._fieldsToRender = [];

    if (fields?.amountField) {
      const amountField = new FormControl(null, Validators.required);
      this.form.addControl('amount', amountField);
      this.form.get('amount').setValue(
        prevFormValue?.amount ||
        this._resolveDefaultSum(this.action, this.method.brand, this.user.currentCurrency.currency),
      );
    }

    if (fields?.playerFields?.length) {
      fields?.playerFields?.forEach((field: any) => {
        if (FieldsConfig[field?.fieldName]?.type) {
          field.type = FieldsConfig[field?.fieldName]?.type;
        }
        if (field?.inclusion?.in && field.field !== 'gender') {
          field.type = this.FieldType.SELECT;
          field.options = field?.inclusion?.in?.map(op => {
            return {
              value: op,
              label: op,
            };
          });
        }
        const control: AbstractControl = new FormControl(
          this._resolveFieldValue(field) || null,
          FieldsConfig[field.fieldName]?.validator
            ? [Validators.required].concat(FieldsConfig[field.fieldName].validator)
            : Validators.required,
        );
        this.form.addControl(field.fieldName, control);
        this._fieldsToRenderProfile.push(this._resolveSomeFieldsForRender(field));
      });

      this.payment.listenSuggestion(this.form, this._geoApify);
    }

    if (!fields.methodFields.length) {
      return;
    }

    this.cvvField = fields.methodFields.find(field => field.fieldName === 'encCvv');

    fields.methodFields.forEach(field => {

      if (!field.fieldName) {
        return;
      }

      switch (field.type) {

        /**
         * Credit card exp date
         */
        case Form.MethodFieldType.CardExpiryDate:
          const monthControl = new FormControl(null, FieldsConfig.month.validator);
          const yearControl = new FormControl(null, FieldsConfig.year.validator);
          this.form.addControl('expiryMonth', monthControl);
          this.form.addControl('expiryYear', yearControl);

          break;

        /**
         * Cascade select
         */
        case Form.MethodFieldType.CascadeSelect:
          const primary = new FormControl(null, Validators.required);
          const secondary = new FormControl(null, Validators.required);
          this.form.addControl('primary', primary);
          this.form.addControl('secondary', secondary);
          break;

        /**
         * Default field
         */
        default:
          const control: AbstractControl = new FormControl(
            this._resolveFieldValue(field) || null,
            FieldsConfig[field.fieldName]?.getValidatorPattern
              ? FieldsConfig[field.fieldName].getValidatorPattern(
                (this.method.brand && this.method.brand.toUpperCase()) as CreditCardTypeCardBrandName
              )
              : FieldsConfig[field.fieldName]?.validator
                ? [Validators.required].concat(FieldsConfig[field.fieldName].validator)
                : Validators.required
          );

          this.form.addControl(field.fieldName, control);
          break;
      }
    });

    /**
     * Exclude cvv, amount, account_type from fields to render because it requires specific rendering
     */
    this._fieldsToRender = fields?.methodFields
      .filter(field => !['encCvv']
        .includes(field.fieldName))
      .map(field => this._resolveSomeFieldsForRender(field))
    .filter((field) => field.identifier !== 'user_warning');

  }

  private _resolveSomeFieldsForRender(field: PaymentsMethodField) {
    if ((this.action === Payment.Action.Cashout && this.method.brand === 'inpay' && this.user.currentCurrency.currency === 'JPY' && field.fieldName === 'clearingNumber')) {
      field = {...field, ...CustomLabelForSomeMethod[this.user.currentCurrency.currency][this.method.brand]};
    }

    if ((field.type && field.type === 'select' || this.Config[field.fieldName] && this.Config[field.fieldName].type && this.Config[field.fieldName].type === 'select') && field.fieldName === 'countryCode' || field.fieldName === 'country_code') {
      let options = this._common.countryList;
      options = options.map(item => [`${item.short.toUpperCase()} - ${item.name}`, item.short.toUpperCase()]);
      field = {...field, options};
    }


    if (Boolean(this.Config[field.fieldName] && this.Config[field.fieldName].label_by_options)) {
      const labelOptions = this._getCustomOptions(this.Config[field.fieldName].label_by_options);
      field = {...field, custom_label: labelOptions && labelOptions[0] && labelOptions[0].label};
    }

    if (Boolean(this.Config[field.fieldName] && this.Config[field.fieldName].description_by_options)) {
      const labelOptions = this._getCustomOptions(this.Config[field.fieldName].description_by_options);
      field = {
        ...field,
        bottomDescription: labelOptions && labelOptions[0] && labelOptions[0].bottomDescription,
        topDescription: labelOptions && labelOptions[0] && labelOptions[0].topDescription
      };
    }

    /**
     * Filter protocols for USDT
     */
    if (field?.type === Form.MethodFieldType.MultiProtocolCryptoAddress) {
      if (field?.protocols?.length === 2 && field?.protocols?.includes('ERC20') && field?.protocols?.includes('TRC20')) {
        field.protocols = field?.protocols?.sort((a, b) => a === 'TRC20' || b === 'TRC20' ? -1 : 1);
      }
      return field;
    }

    return field;
  }

  /**
   *
   * @param options
   * @param defaultUserInfo
   * @private
   */
  private _getCustomOptions(options: ICustomFieldsConfig[], defaultUserInfo = {
    brand: this.method.brand,
    action: this.action,
    currencies: this.user.currentCurrency.currency
  }) {
    return options.filter(options => {
      return Object.entries(options)
        .filter(([key, value]) => Object.keys(defaultUserInfo).includes(key))
        .every(([key, value]) => Array.isArray(value) ? value.includes(defaultUserInfo[key]) : defaultUserInfo[key] === value);
    });
  }

  /**
   * Returns predefined field value
   *
   * @private
   * @param field
   */
  private _resolveFieldValue(field: PaymentsMethodField): any {

    if (field?.proposedValue) {
      return field.proposedValue;
    }

    switch (field.fieldName) {
      case 'amount':
        return this._selectedAmountValue || !!this._storage.get(DEP_AMOUNT_STR) ?
          this._setCustomDepositAmountFromStorage() :
          this._resolveDefaultSumOnClickSavedAcc(this._amountValue, this._eventOnClickSavedAcc);
      case 'email':
      case 'destinationAccount':
        return this.user.info.email;
      case 'account_owner_name':
      case 'cardHolder':
      case 'customer_name':
      case 'beneficiaryName':
        return (this.user.info.first_name && this.user.info.last_name) ? `${this.user.info.first_name} ${this.user.info.last_name}` : '';
      case 'account_type':
        if (this.method.brand === 'bank_transfer' && this.action === Payment.Action.Cashout &&
          ['NOK', 'EUR'].includes(this.user.currentCurrency.currency)) {
          const iban = field.options.find((f) => f.value.includes('iban'));
          return iban ? iban.value : null;
        } else {
          return field.options[0]?.value;
        }
      case 'country_code':
      case 'countryCode':
        return this.user.info.country;
      default:
        return null;
    }
  }

  /**
   * Resolve default sum if user clicked on saved account
   * @param amountValue
   * @param event
   * @private
   */
  private _resolveDefaultSumOnClickSavedAcc(amountValue: number | string, event) {
    if (event && !amountValue) {
      return this._resolveDefaultSum(this.action, this.method.brand, this.user.currentCurrency.currency);
    } else if (event && amountValue) {
      return amountValue;
    } else {
      return this._resolveDefaultSum(this.action, this.method.brand, this.user.currentCurrency.currency);
    }
  }

  /**
   * Returns quick sum list for current payment method, action and currency
   *
   * @param action
   * @param brand
   * @param currency
   * @private
   */
  private _resolveQuickSum(action: Payment.Action, brand: string, currency: string): number[] {
    currency = currency || 'default';

    switch (true) {
      case ASIAN_CURRENCIES.includes(currency) && action === Payment.Action.Deposit:
        return QUICK_SUM_CURRENCY[currency];
      case action === Payment.Action.Cashout:
      case action === Payment.Action.Deposit:
        return this._selectedSumByCountry || this._normalizeQuickSum(action, brand, currency);
      default:
        return DEFAULT_QUICK_SUM;
    }
  }

  /**
   * Returns default sum for current payment method, action and currency
   *
   * @param action
   * @param brand
   * @param currency
   * @private
   */
  private _resolveDefaultSum(action: Payment.Action, brand: string, currency: string): number {
    currency = currency || 'default';

    if (this._selectedSumByCountry) {
      return this._selectedSumByCountry[0];
    }

    const defaultQuickSum = (QUICK_SUM[brand] || null) && (QUICK_SUM[brand][currency] || QUICK_SUM[brand].default) || QUICK_SUM_CURRENCY[currency] || DEFAULT_QUICK_SUM;
    const minAmount = this.method?.termsOfService?.restrictions?.minAmountValue;

    if (+minAmount < defaultQuickSum[0]) {
      switch (action) {
        case Payment.Action.Cashout:
          return (STANDART_SUM_CASHOUT[brand] || null) && (STANDART_SUM_CASHOUT[brand][currency] || STANDART_SUM_CASHOUT[brand].default) || STANDART_SUM_CURRENCY_CASHOUT[currency] || DEFAULT_STANDART_SUM_CASHOUT;
        case Payment.Action.Deposit:
          return (STANDART_SUM_DEPOSIT[brand] || null) && (STANDART_SUM_DEPOSIT[brand][currency] || STANDART_SUM_DEPOSIT[brand].default) || STANDART_SUM_CURRENCY_DEPOSIT[currency] || DEFAULT_STANDART_SUM_DEPOSIT;
        default:
          return 0;
      }
    } else {
      return this._quickSumList[0];
    }
  }

  /**
   * Normalize quick sum. When min value method bigger than first and second element quick sum array
   *
   * @param action
   * @param brand
   * @param currency
   * @private
   */
  private _normalizeQuickSum(action: Payment.Action, brand: string, currency: string): number[] {
    let quickSum = (QUICK_SUM[brand] || null) && (QUICK_SUM[brand][currency] || QUICK_SUM[brand].default) || QUICK_SUM_CURRENCY[currency] || DEFAULT_QUICK_SUM;
    const minAmount = this.method?.termsOfService?.restrictions?.minAmountValue;
    const maxAmount = this.method?.termsOfService?.restrictions?.maxAmountValue;

    if (!isNullOrUndefined(minAmount)) {
      if (+minAmount <= quickSum[1]) {
        quickSum[0] = +minAmount;
      } else {
        quickSum = quickSum.map((s, index) => index === 0 ? +minAmount : s * Math.ceil(+minAmount / quickSum[1] + 2));
      }
    }
    return quickSum;
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    if (!$event || $event === this.user.currentCurrency.currency) {
      return;
    }
    this.user.changeCurrencyAcc($event);
  }

  /**
   * To fix overlap label if input is empty and in input only + character.
   * @param field
   */
  hideEmptyPhone(field) {
    const fieldControl = this.form.get(field);
    if (fieldControl.value === '') {
      fieldControl.setValue('');
    } else {
      return;
    }
  }

  public replaceMonthValue() {
    const monthField = this.form.get('expiryMonth');

    if (monthField.value && monthField.value.length === 2) {
      return;
    }
    monthField.setValue(monthField.value < 10 && monthField.value > 0
      ? ('0' + monthField.value).slice(-2) : monthField.value, {emitEvent: false});
  }

  public replaceYearValue() {
    const yearField = this.form.get('expiryYear');

    if (yearField.value && yearField.value.length === 2) {
      return;
    }
    yearField.setValue(yearField.value < 10 && yearField.value > 0
      ? (yearField.value + '0').slice(-2) : yearField.value, {emitEvent: false});
  }
  /**
   * Making focus to other input field when maxLengthTillSwitch will reach limit
   * @param event
   * @param field
   * @param switchToElem
   * @param maxLengthTillSwitch
   * @public
   */
  handleAutoSwitch(event: any, field: string, switchToElem: any, maxLengthTillSwitch: number) {
    if (!this._platform.isBrowser) {
      return;
    }
    const fieldControl = this.form.get(field);

    if (event.target.value && event.target.value.length === maxLengthTillSwitch && fieldControl.valid) {
      switchToElem.focus ? switchToElem.focus() : switchToElem.nativeElement.focus();
    } else if (fieldControl.invalid) {
      fieldControl.markAsTouched();
    }
  }

  /**
   * Resolve query params
   * @private
   */
  private _resolveQueryParams() {
    this._route.queryParams.pipe(
      untilDestroyed(this),
      first(),
      tap((data) => {
        switch (true) {
          case Boolean(data?.amount && data?.currency):
            this.changeAccountCurrency(data?.currency);
            setTimeout(() => this.form?.get('amount')?.setValue(data?.amount));
            break;
          case Boolean(data?.amount):
            setTimeout(() => this.form?.get('amount')?.setValue(data?.amount));
            break;
          case Boolean(data?.currency):
            this.changeAccountCurrency(data?.currency);
            break;
        }
      })

    ).subscribe();
  }

  private _setCustomDepositAmountFromStorage() {
    const value = +this._storage.get(DEP_AMOUNT_STR);

    return isFinite(value) ? value : '';
  }

  /**
   * On select protocol
   * @param $event
   * @param field
   */
  public onSelectProtocol($event, field) {
    this.form?.get(field?.fieldName)?.setValue($event?.title, { emitEvent: false });
  }


  public onNextDepositStep() {
    if (this.payment.hidePlayerFieldsInPaymentForm) {
      if (this.payment.hidePlayerFieldsInPaymentForm) {
        const profileFields = this._fieldsToRenderProfile.map((e) => e.fieldName);
        profileFields.forEach((name) => {
          this.form.get(name).setErrors(null);
        })
      }
      this.submitPayment();
      return;
    }

    const controlsToCheck = [
      ...this._fieldsToRender.map(item => item.fieldName),
      'amount',
      'encCvv',
      'cardHolder',
      'expiryMonth',
      'expiryYear',
      'expiryDate',
    ];

    if (this._fieldsToRenderProfile.length) {
      let isFormValid = true;

      controlsToCheck.forEach(controlName => {
        const control = this.form.get(controlName);
        if (control && control.invalid) {
          control.markAsTouched();
          isFormValid = false;
        }
      });

      if (this.payment.isSecondDepositStep) {
        this.form.markAllAsTouched()
      }

      if (!isFormValid) {
        this.payment.hidePlayerFieldsInPaymentForm = false;
        return;
      }

      if (isFormValid && this.payment.isSecondDepositStep) {
        this.submitPayment();
        return;
      }

      this.payment.isSecondDepositStep = true;
    } else {
      this.submitPayment();
    }
  }

}
