import {Component, OnInit} from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {FlashPlayerService} from '../../../../services/flash-player.service';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../services/google-tag-manager.service';

@Component({
    selector: 'app-game-requires-flash',
    templateUrl: './game-requires-flash.component.html',
    styleUrls: ['./game-requires-flash.component.scss'],
    standalone: true
})
export class GameRequiresFlashComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  constructor(
    public flash: FlashPlayerService,
    private _gtm: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.GAME_REQUIRES_FLASH,
      POPUP_EVENT_ACTIONS.GAME_REQUIRES_FLASH
    );
  }

}
