import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cryptoAddress',
  standalone: true
})
export class CryptoAddressPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.replace('bitcoincash:', '');
  }

}
