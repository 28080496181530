import { Inject, Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { ScrollService } from './scroll.service';
import { DOCUMENT } from '@angular/common';
import { WindowService } from './window.service';


@Injectable({
  providedIn: 'root'
})
export class BodyService {

  private _documentEl: HTMLElement;
  private _bodyEl: HTMLElement;
  private _windowEl;
  private _isIOS: boolean = null;
  private _isAndroid: boolean = null;

  constructor(
    private _platform: PlatformService,
    private _scroll: ScrollService,
    private _window: WindowService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._windowEl = {};
    this._isIOS = this._isDetectOs(/ipad|iphone|ipod/i);
    this._isAndroid = this._isDetectOs(/Mobi|Android/i);

    if (this._platform.isBrowser) {
      this._documentEl = this._document.documentElement;
      this._bodyEl = this._document.body;
      this._windowEl = this._window.nativeWindow;
    }
  }

  get IOS() {
    return this._isIOS;
  }

  get window() {
    return this._windowEl;
  }

  /**
   * Return true if application runs on specific OS
   *
   * @private
   */
  private _isDetectOs(regexp: RegExp): boolean {
    return regexp.test(this._window.nativeWindow.navigator.userAgent) || (!!this._window.nativeWindow.navigator.platform && regexp.test(this._window.nativeWindow.navigator.platform));
  }
}
