@if (converter.rate$ | async; as rate) {
  @if (rate !== converter.notAvailable) {
    <div class="exchange" [formGroup]="form">
      <div class="exchange__text">{{'t.send-any-amount-btc #currency# #min#' | translate:{currency: method?.termsOfService?.restrictions?.amountCurrencyCode, min: method?.termsOfService?.restrictions?.minAmountValue} }}</div>
      <div class="exchange__flex">
        <div class="exchange__control">
          <app-input-currencies-selector
            [control]="form.get('give')"
            [label]="'t.you-give' | translate"
            [selectedCurrency]="method?.termsOfService?.restrictions?.amountCurrencyCode"
            [disableSelect]="true"
            class="give"
          ></app-input-currencies-selector>
        </div>
        <div class="exchange__control">
          <app-input-currencies-selector
            [control]="form.get('get')"
            [label]="'t.you-get' | translate"
            (changeCurrency)="changeAccountCurrency($event)"
          ></app-input-currencies-selector>
        </div>
      </div>
      <div class="exchange__info">
        <span>1 {{ method?.termsOfService?.restrictions?.amountCurrencyCode }} = {{ rate | currencyFormat:user.currentCurrency?.currency  }} {{ user.currentCurrency?.currency }}</span>
        @if (method?.termsOfService?.commissions) {
          <span>{{(+method?.termsOfService?.commissions?.cents === 0 ? 't.fee-free' : 'labl.fee') | translate}}
            {{method?.termsOfService?.commissions?.cents ? (user.currentCurrency?.symbol + ' ' + +method?.termsOfService?.commissions.cents) : ( ('t.free' | translate) + ' ' + user.currentCurrency?.symbol + ' 0')}}</span>
        }
      </div>
      <div class="exchange__inaccurate">{{ 't.exchange-rate-inaccurate' | translate }}</div>
    </div>
  } @else {
    <div class="exchange__not-available">{{converter.notAvailable | translate}}</div>
  }
} @else {
  <app-preloader></app-preloader>
}

