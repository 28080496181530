import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class FlashPlayerService {

  private readonly FLASHPLAYER_ACTIVATION_URL = 'https://get.adobe.com/flashplayer/';

  constructor(
    private _platform: PlatformService
  ) { }

  /**
   * Access to activation link from outside
   */
  get activationUrl(): string {
    return this.FLASHPLAYER_ACTIVATION_URL;
  }

  /**
   * Returns true if flash player installed
   */
  flashPlayerEnabled(): boolean {
    return this._platform.isBrowser && !!navigator.plugins.namedItem('Shockwave Flash');
  }
}
