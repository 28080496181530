/**
 * Default quick sum for payment methods
 */
export const DEFAULT_QUICK_SUM = [20, 50, 100, 200];

/**
 * Quick sum by payment method and currency
 *
 * Format:
 * {
 *   coinspaid: {
 *     default: [10, 20, 30]
 *     BTC: [1, 2, 3]
 *   }
 * }
 */
export const QUICK_SUM = {
  coinspaid: {
    BTC: [0.0024, 0.006, 0.012, 0.024],
    ETH: [0.1, 0.24, 0.5, 1],
    BCH: [0.1, 0.24, 0.47, 0.92],
    LTC: [0.5, 1.3, 2.5, 5.2],
    DOG: [1400, 2800, 4200, 5600, 8500],
    USDT: [28, 55, 82, 110, 164],
  }
};

/**
 * Quick sum by currency
 *
 * Format:
 * {
 *    NOK: [500, 1000, 5000]
 *    CAD: [50, 100, 500]
 * }
 */
export const QUICK_SUM_CURRENCY = {
  AUD: [30, 80, 150, 300],
  NOK: [200, 500, 1000, 2000],
  CAD: [30, 80, 150, 300],
  ZAR: [400, 1000, 2000, 4000],
  JPY: [2500, 6000, 12000, 24000],
  HUF: [15000, 30000, 150000],
  PLN: [90, 200, 500, 900],
  BRL: [50, 100, 200, 500],
  VND: [100000, 200000, 500000, 1000000],
  IDR: [100000, 200000, 500000, 1000000],
  MYR: [50, 100, 500, 1000],
  THB: [400, 500, 1000, 5000],
  PHP: [500, 1000, 2000, 4000],
  KRW: [10000, 50000, 100000, 500000]
};

/**
 * Default sum for payment methods, select on page load
 */
export const DEFAULT_STANDART_SUM_DEPOSIT = 100;
export const DEFAULT_STANDART_SUM_CASHOUT = 20;


/**
 * Default sum by payment method and currency, select on page load
 *
 * Format:
 * {
 *   coinspaid: {
 *     default: 10
 *     BTC: 1
 *   }
 * }
 */
export const STANDART_SUM_DEPOSIT = {
  coinspaid: {
    BTC: 0.012,
    ETH: 0.5,
    BCH: 0.47,
    LTC: 2.5,
    DOG: 8500,
    USDT: 164,
  }
};

export const STANDART_SUM_CASHOUT = {
  coinspaid: {
    BTC: 0.0024,
    ETH: 0.1,
    BCH: 0.1,
    LTC: 0.5,
    DOG: 1400,
    USDT: 28,
  }
};

/**
 * Default sum by currency, select on page load
 *
 * Format:
 * {
 *    NOK: 1000
 *    CAD: 100
 * }
 */
export const STANDART_SUM_CURRENCY_DEPOSIT = {
  AUD: 150,
  NOK: 1000,
  CAD: 150,
  ZAR: 2000,
  JPY: 12000,
  HUF : 30000,
  PLN: 500
};

export const STANDART_SUM_CURRENCY_CASHOUT = {
  AUD: 30,
  NOK: 200,
  CAD: 30,
  ZAR: 400,
  JPY: 2500,
  HUF : 15000,
  PLN: 90
};
/**
 * Quick sum by country, payment method and currency
 *
 * Format:
 */
export const QUICK_SUM_COUNTRY = {
  ca: {
    mastercard: {
      CAD: [50, 100, 150, 300]
    },
    visa: {
      CAD: [50, 100, 150, 300]
    },
    creditcard: {
      CAD: [50, 100, 150, 300]
    }
  },
  au: {
    mastercard: {
      AUD: [20, 50, 100, 300]
    },
    visa: {
      AUD: [20, 50, 100, 300]
    },
    creditcard: {
      AUD: [20, 50, 100, 300]
    }
  },
  fi: {
    siru: {
      EUR: [15, 100, 200, 500, 1000],
    }
  }
};

export const SELECT_QUICK_SUM_BY_COUNTRY = (brand, country, currency) => (QUICK_SUM_COUNTRY[country] || null)
  && (QUICK_SUM_COUNTRY[country]?.[brand]
  || null) && (QUICK_SUM_COUNTRY[country]?.[brand]?.[currency] || null) || QUICK_SUM_CURRENCY[currency]?.default;
