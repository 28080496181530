<header class="header" [ngClass]="{
  'header--with-title-links': links?.length || title, auth: user?.auth
}">
  <div class="container">
    <div class="content-container">
      <div class="left">
        <div class="menu" (click)="sidebar.open()">
          <span></span>
          <span></span>
          <span></span>
          @if (!sidebar.isOpenedCasinoToday && badge?.gameBadge) {
            <i class="badge-notif"></i>
          }
        </div>

        @if (!title) {
          <div class="nav">
            <a class="nav__item" [routerLink]="['/', lang.current, 'games']">{{'link.games' | translate}}</a>
            @if (user.auth) {
              <a class="nav__item"
              [routerLink]="['/', lang.current, 'games', 'favorite']">{{'link.favorite-games' | translate}}</a>
              <a class="nav__item"
              [routerLink]="['/', lang.current, 'games', 'last-played']">{{'link.last-played' | translate}}</a>
              <a class="nav__item"
              [routerLink]="['/', lang.current, 'tournaments']">{{'link.tournaments' | translate}}</a>
            }
          </div>
        } @else {
          <h1 class="header__title">{{ title | translate }}</h1>
        }

        <div class="notify mobile">
          <app-notification-center-drop-down *appShellNoSSR></app-notification-center-drop-down>
        </div>
      </div>

      <div class="center"
        [ngClass]="{auth: user.auth}"
        >
        @if (halloween.isPromoLogo) {
          <a class="logo promo" *appShellNoSSR [href]="seo.originalLocationUrl + '/' + lang.current" [routerLink]="['/', lang.current]">
            <img src="/assets/img/promo/halloween/logo.gif" alt="">
          </a>
        } @else {
          <a class="logo" *appShellNoSSR [href]="seo.originalLocationUrl + '/' + lang.current" [routerLink]="['/', lang.current]">
            <ng-container *ngTemplateOutlet="selectLogo"></ng-container>
          </a>
          <a class="logo" *appShellRenderSSR [href]="seo.originalLocationUrl + '/' + lang.current">
            <ng-container *ngTemplateOutlet="selectLogo"></ng-container>
          </a>
        }
      </div>

      <div class="right">
        @if (!user.auth) {
          <app-header-login-form></app-header-login-form>
        }
        @if (user.auth) {
          @if (!links?.length) {
            <app-header-balance></app-header-balance>
          } @else {
            <div class="right-nav">
              @for (link of links; track link) {
                <a class="right-nav__item" routerLinkActive="active"
                  [title]="link.tab | translate" [routerLink]="'/' + lang.current + link.url">
                  <img [src]="link.img" alt="">
                  <div class="right-nav__item-label">{{link.tab | translate}}</div>
                </a>
              }
            </div>
          }
          <div class="notify desktop">
            <app-notification-center-drop-down *appShellNoSSR></app-notification-center-drop-down>
          </div>
        }

      </div>
    </div>
  </div>
</header>

<ng-template #selectLogo>
  <img class="logo__img" src="/assets/img/logo.png" alt="Spin Samurai Casino">
  <img class="logo__img desktop" src="/assets/img/logo.png" alt="Spin Samurai Casino">
</ng-template>
