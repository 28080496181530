import {Directive, ElementRef} from '@angular/core';
import {PlatformService} from '../../services/platform.service';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[setValueAutofill]',
    standalone: true
})
export class SetValueAutofillDirective {

  constructor(
    private _el: ElementRef,
    private _control: NgControl,
    private _platform: PlatformService,
  ) {
    this._setValueAfterAutofill();
  }

  /**
   * Set value after autofill
   *
   * @private
   */
  private _setValueAfterAutofill() {
    if(this._platform.isBrowser && this._el.nativeElement) {
      this._el.nativeElement.addEventListener('change', () => {
        setTimeout(() => {
          if(this._control && !this._control.control.value) {
            this._control.control.setValue(this._el.nativeElement.value);
          }
        }, 250);
      });
    }
  }
}
