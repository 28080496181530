<div class="timer">
  @if (progress) {
    <div class="progress-bar" [style]="{'--progress': progress}"></div>
  }

  @if (timeLive) {
    <div class="timer__values">
      <div class="timer__label">{{ 'labl.finish-at' | translate }}</div>
      <div class="timer__value text-gradient">{{timeLive?.days}}</div>
      <div class="timer__label">{{timeLive?.daysLabel | translate}} :</div>
      <div class="timer__value text-gradient">{{timeLive?.hours}}</div>
      <div class="timer__label">{{timeLive?.hoursLabel | translate}} :</div>
      <div class="timer__value text-gradient">{{timeLive?.minutes}}</div>
      <div class="timer__label">{{timeLive?.minLabel | translate}}</div>
    </div>
  }
</div>
