import { DOCUMENT } from '@angular/common';
import { Inject, inject, Injectable, InjectionToken, NgZone } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export const CONFETTI$ = new InjectionToken<Observable<any>>(
  'A stream with confetti object',
  {
    factory: () => {
      const documentRef = inject(DOCUMENT);
      const zone = inject(NgZone);
      const windowRef: any = documentRef.defaultView;
      const script = documentRef.createElement('script');
      const loaded$ = new ReplaySubject(1);
      script.src = 'https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js';
      documentRef.body.appendChild(script);
      script.onload = (e: Event) => {
        zone.run(() => {
          loaded$.next(windowRef.confetti);
        });
      };
      script.onerror = (error) => {
        zone.run(() => loaded$.next(null));
      };
      return loaded$;
    },
  }
);

declare var confetti;

@Injectable({
  providedIn: 'root'
})
export class  ConfettiService {

  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(CONFETTI$) readonly confetti$: Observable<any>,
  ) {
    this.confetti$.subscribe(() => {
      this._ready$.next(true);
    });
  }

  public createConfetti(callback, options: any = {particleCount: 100, spread: 60}, timeOut: number = 300) {
    this._ready$.pipe(
      first()
    ).subscribe(() => {
      confetti({...options});
    });
    setTimeout(() => {
      return callback();
    }, timeOut);
  }

}
