import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ValidationPatterns } from '../validation-patterns';
import { SsApiService } from '../../services/api/ss-api.service';
import {catchError, tap} from 'rxjs/operators';
import {forkJoin, of, Subscription} from 'rxjs';
import {STORAGE_LESS_MODAL_SHOWED, UserService} from '../../services/user/user.service';
import { Router } from '@angular/router';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { CacheService } from '../../services/cache/cache.service';
import { ContentUpdaterService } from '../../services/content-updater/content-updater.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { TimeService } from '../../services/time.service';
import { LocalHistoryService } from '../../services/local-history.service';
import {WrSocketService} from '../../services/wr-socket.service';
import {DataPreloaderService} from '../../services/data-preloader.service';
import {GlobalEventsService} from '../../services/global-events.service';
import { inject } from '@angular/core';
import {ModalsFromUrlService} from '../../modal-v2/modals-from-url.service';
import {LocalstorageService} from '../../services/localstorage.service';
import { UbidexService } from '../../services/ubidex.service';
import {registrationStorageName} from "./register-form-controller";

/**
 * For handling login forms
 */
export class LoginFormController {

  /**
   * Access to global services
   */
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _ssApi: SsApiService = inject(SsApiService);
  private _user: UserService = inject(UserService);
  private _router: Router = inject(Router);
  private _toastMessage: ToastMessageService = inject(ToastMessageService);
  private _cache: CacheService = inject(CacheService);
  private _contentUpdater: ContentUpdaterService = inject(ContentUpdaterService);
  private _formErrors: FormsErrorHandlerService = inject(FormsErrorHandlerService);
  private _gtm: GoogleTagManagerService = inject(GoogleTagManagerService);
  private _time: TimeService = inject(TimeService);
  private _history: LocalHistoryService = inject(LocalHistoryService);
  private _modalsFromUrl: ModalsFromUrlService = inject(ModalsFromUrlService);
  private _wrSocket: WrSocketService = inject(WrSocketService);
  private _preload: DataPreloaderService = inject(DataPreloaderService);
  private _events: GlobalEventsService = inject(GlobalEventsService);
  private _storage: LocalstorageService = inject(LocalstorageService);
  private _ubidex: UbidexService = inject(UbidexService);


  /**
   * Is loading state
   */
  private _loading: boolean;

  /**
   * Login for FormGroup
   */
  private _form: UntypedFormGroup = this._fb.group({
    email: [null, [Validators.required, Validators.pattern(ValidationPatterns.email)]],
    password: [null, [Validators.required]]
  });

  private navigationEndSubs: Subscription;

  constructor() {}

  /**
   * Access to private properties from outside
   */
  get form(): UntypedFormGroup { return this._form; }
  get loading(): boolean { return this._loading; }

  /**
   * Returns FormControl by key
   *
   * @param key
   */
  input(key: string): AbstractControl {
    return this._form.get(key);
  }

  /**
   * Submit form handler
   */
  submitForm() {
    this._formErrors.applyFormErrors(this._form, null, true);

    if (this._form.invalid) {
      return;
    }
    this._loading = true;
    this._preload.loaded = false;
    this._storage.clearItem(STORAGE_LESS_MODAL_SHOWED);

    this._ssApi.usersSignIn({
      user: this._form.value
    }).pipe(
      tap(response => {
        this._wrSocket.sendEventLogin();
        this._cache.clear();
        this._user.applyUserInfo(response);
        this._ubidex.onLogin(response);

        forkJoin([
          this._user.getUserAccounts(),
          this._user.getUserAccountsCompatibility()
        ]).subscribe(() => {
          this._modalsFromUrl.controller.close();
          this._loading = false;
          this._processRedirectAfterLogin();
          this.navigationEndSubs = this._events.routerNavigationEnd$.pipe(
            tap((e) => {
              if (this.navigationEndSubs && !e.url.includes('login')) {
                this._preload.loaded = true;
                this.navigationEndSubs.unsubscribe();
              }
            })
          ).subscribe();
        });

        this._contentUpdater.updateAll();

        this._gtm.signInSuccess(response.id);

      }),
      catchError(error => {
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.invalid-email-password');
        this._changesFieldErrorsIfSSError(error.error);
        this._formErrors.applyFormErrors(this._form, error.error, true);
        this._preload.loaded = true;
        this._loading = false;
        return of(error);
      })
    ).subscribe();
  }

  /**
   * Resolve correct url fore redirect after login and navigate
   *
   * @private
   */
  private async _processRedirectAfterLogin() {
    const beforeLoginUrl = this._history.getUrl('promo-auth');

    let urlToRedirect: any[] = beforeLoginUrl ? [beforeLoginUrl.url] : ['/'];

    if (this._user.currentCurrency.amount <= this._user.currentCurrencyToEquivalent && !beforeLoginUrl) {
      urlToRedirect = ['/profile/deposit', {data: {context: 'deposit'}}];
    }

    this._router.navigate(urlToRedirect).then(() => {
      this._storage.clearItem(registrationStorageName);
      this._toastMessage.success('t.auth-success');
    });
  }

  /**
   * If error from SS reset errors for some fields if valueChanges
   * @private
   */
  private _changesFieldErrorsIfSSError(errors) {
    const controls = Object.keys(errors.errors);

    if (controls.length) {
      this.form.valueChanges.pipe(
        tap(() => {
          controls.forEach(control => this.input(control).setErrors(null));
          this._formErrors.applyFormErrors(this._form, null, true);
        }),
      ).subscribe();
    }

  }
}
