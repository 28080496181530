import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import { RegisterFormComponent } from '../../../../modules/register-form/register-form.component';

@Component({
    selector: 'app-sign-up-modal',
    templateUrl: './sign-up-modal.component.html',
    styleUrls: ['./sign-up-modal.component.scss'],
    standalone: true,
    imports: [RegisterFormComponent]
})
export class SignUpModalComponent implements OnInit, ModalContent {

  public modal: ModalRef;

  constructor() { }

  ngOnInit() {
  }

  onClose(event) {
    if (event) {
      this.modal.close();
    } else {
      return;
    }
  }

}
