import {AuthFieldsMissedUpdateComponent} from './auth-fields-missed-update/auth-fields-missed-update.component';
import {BonusCancelConfirmationComponent} from './bonus-cancel-confirmation/bonus-cancel-confirmation.component';
import {GameNotAvailableComponent} from './game-not-available/game-not-available.component';
import {GameRequiresFlashComponent} from './game-requires-flash/game-requires-flash.component';
import {SignInModalComponent} from './sign-in-modal/sign-in-modal.component';
import {SignUpModalComponent} from './sign-up-modal/sign-up-modal.component';
import {DepositBonusInfoComponent} from './deposit-bonus-info/deposit-bonus-info.component';
import { DepositModalComponent } from '../lazy/deposit-modal/deposit-modal.component';

export const ModalRegular = {
  AUTH_FIELD_MISSED_UPDATE: AuthFieldsMissedUpdateComponent,
  BONUS_CANCEL_CONFIRM: BonusCancelConfirmationComponent,
  DEPOSIT: DepositModalComponent,
  GAME_NOT_AVAILABLE: GameNotAvailableComponent,
  GAME_REQUIRES_FLASH: GameRequiresFlashComponent,
  SIGN_IN: SignInModalComponent,
  SIGN_UP: SignUpModalComponent,
  DEPOSIT_BONUS_INFO: DepositBonusInfoComponent
};

export type ModalRegularType = keyof typeof ModalRegular;
