<form class="bonus" [formGroup]="bonusCodeForm" (ngSubmit)="onBonusCodeFormSubmit()">
  <div class="bonus__image">
    <img [src]="arabic.isAr ? '/assets/img/deposit-bonus/no-deposit-bonus-arabic.png' : '/assets/img/deposit-bonus/no-deposit-bonus.png'" alt="">
  </div>
  <div class="bonus__text">
    <div class="bonus__text_bigger">{{ 't.do-you-have-bonus' | translate }}</div>
    @if (!arabic.isAr) {
      <div class="bonus__text_smaller">({{ 't.no-deposit-bonuscode' | translate}})</div>
    }
  </div>
  <div class="bonus__input">
    <app-form-input [label]="'t.bonus-code' | translate">
      <input formControlName="code" type="text">
    </app-form-input>
    <button type="submit" [disabled]="!bonusCodeForm.get('code').value" class="bonus__input_action btn btn--primary">{{'t.send' | translate}}</button>
  </div>
</form>

