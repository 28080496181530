<div class="sign-up-container">
  <i #close class="close icon-close" (click)="onClose()"></i>
  <div class="form-container">
    <ng-container>
      <div class="form__title mobile" id="sign-up-title-mobile">{{ 't.create-account' | translate }}</div>
      @if (banner) {
        <div class="bonus">
          <div class="bonus__text">
            <div class="bonus__title">{{ banner.Title }}</div>
            <div class="bonus__description" [innerHTML]="banner?.Description | safe:'html'"></div>
          </div>
          <img class="bonus__image desktop" ClassLoaded [src]="banner?.ImageRedesign" alt="">
          <img class="bonus__image mobile" [src]="banner?.ImageRedesign" alt="">
          <!--        <app-special-partner-badge></app-special-partner-badge>-->
        </div>
      } @else {
        <div class="bonus"></div>
      }
      <ng-template #bonus>
        <div class="bonus"></div>
      </ng-template>
    </ng-container>

    <div class="form">
      <div class="form__title desktop" id="sign-up-title">{{ 't.create-account' | translate }}</div>
      <!--        <div class="form__description">{{ 't.all-your-data-protected' | translate }}</div>-->

      <form [formGroup]="controller.form" (ngSubmit)="controller.submitForm(controller.form)">
        <div>
          <app-form-input class="input-item" [control]="controller.input('email')" [label]="'labl.email' | translate">
            <input class="input input--simple input--email" (focus)="onFocus($event)" type="text" name="email"
              id="email-input"
              formControlName="email">
            </app-form-input>

            <app-form-input class="input-item has-icon" [control]="controller.input('password')" [label]="'labl.password' | translate">
              <i class="icon icon-password password-toggle" [ngClass]="{'icon--ios': body.IOS}"></i>
              <input (keydown.space)="$event.preventDefault()" class="input input--simple" (focus)="onFocus($event)" type="password" name="password" PasswordShow formControlName="password"  id="password-input">
            </app-form-input>

            <app-form-input class="input-item has-icon" [errors]="controller.countryAndNicknameErrors" [control]="controller.input('nickname')" [label]="'labl.nickname' | translate">
              <input class="input input--simple input-country" (focus)="onFocus($event)" type="text" name="nickname"
                id="nickname-input"
                formControlName="nickname">
                <app-country-selector (focus)="onFocus($event, 'country')" [countryName]="true" [showArrow]="controller.showArrow" [control]="controller.input('country')" class="country-selector" id="country"></app-country-selector>
              </app-form-input>
            </div>

            @if (!controller.isPtag) {
              <div  #bonusAccordion Accordion class="bonuscode-link" (click)="focusCode(inputElement)">
                <span class="link ">{{'t.have-bonus-code' | translate}}</span>
              </div>
              <div class="bonus-code-form">
                <app-form-input class="bonus-code-form__input" [control]="controller.input('bonus_code')" [label]="'bonus.code' | translate">
                  <input #inputElement formControlName="bonus_code" name="bonus_code" type="text" id="bonus-code-input">
                </app-form-input>
                @if (!isSubmitedBonusCode) {
                  <button
                    type="button"
                    (click)="onBonuscodeFormSubmit()"
                    [disabled]="!controller.input('bonus_code').value || controller.input('bonus_code').invalid"
                    class="bonus-code-form__action btn btn--primary">
                    {{'btn.send' | translate}}
                  </button>
                }
                @if (isSubmitedBonusCode) {
                  <button
                    type="button"
                    class="bonus-code-form__action btn btn--primary"
                    (click)="deleteDepositeBonus()">
                    {{'btn.clear' | translate}}
                  </button>
                }
              </div>
            }
            <div class="checkbox-container" [style.margin-top]="controller.isPtag ? '15px' : ''">
              <div class="checkbox-container__checkbox">
                <app-form-checkbox [control]="controller.input('terms_acceptance')" id="terms-checkbox"
                  (change$)="controller.toggleRegistrationDataToStorage(false); $event.stopPropagation()"
                  [label]="'t.agree-terms' | translate"
                  [checked]="controller.input('terms_acceptance')?.value"
                  [borderColor]="'#8C8C8C'"
                  [styles]="{'align-items': 'flex-start'}"
                  >
                </app-form-checkbox>
              </div>

              <div class="checkbox-container__checkbox">
                <app-form-checkbox [control]="controller.input('receive_promos')" id="receive-email-checkbox"
                  (change$)="controller.toggleRegistrationDataToStorage(false); $event.stopPropagation()"
                  [label]="'labl.recive-email' | translate"
                  [checked]="controller.input('receive_promos')?.value"
                  [borderColor]="'#8C8C8C'"
                  >
                </app-form-checkbox>
              </div>
            </div>

            <div class="submit-container">
              <button
                class="btn btn--filled-primary"
                id="submit-register-button"
                [loading]='controller.loading'
              >{{'btn.signup' | translate}}</button>
            </div>
          </form>

          <app-social-auth></app-social-auth>

          <div class="bottom-links">
            <div class="bottom-links__item">{{'t.already-have-acc' | translate}} <a (click)="onLoginClick()"><span class="link ">{{'t.log-in' | translate}}</span></a></div>
          </div>

          <!--      <app-special-partner-badge *ngIf="breakPoint.isMobile"></app-special-partner-badge>-->
        </div>
      </div>

    </div>
