import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user/user.service';
import { GamesLauncherService } from '../../../../services/games/games-launcher.service';
import { GoogleTagManagerService } from '../../../../services/google-tag-manager.service';
import { GroupsService, UserGroup } from '../../../../services/groups.service';
import { RouterLink } from '@angular/router';
import { MotivateWithdrawalService } from '../../../../services/motivate-withdrawal.service';
import { BreakpointsDetectorService } from '../../../../services/breakpoints-detector.service';
import { WebSocketService } from '../../../../services/web-socket.service';
import { CommonDataService } from '../../../../services/common-data.service';
import { map } from 'rxjs/operators';
import { TranslatePipe } from '../../../translation/translate.pipe';
import { CurrencyFormatPipe } from '../../../pipes/currency-format.pipe';
import { AsyncPipe } from '@angular/common';
import { SetImgAltDirective } from '../../../directives/set-img-alt.directive';
import { RouterLinkDirective } from '../../../directives/router-link.directive';

@Component({
    selector: 'app-header-balance',
    templateUrl: './header-balance.component.html',
    styleUrls: ['./header-balance.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkDirective, SetImgAltDirective, AsyncPipe, CurrencyFormatPipe, TranslatePipe]
})
export class HeaderBalanceComponent implements OnInit {

  baseImg = '/assets/img/user/';
  userImg: string;

  public isMobile = this.breakpoints.isMobile;

  public balance$ = this._ws.userBalance$.pipe(
    map(balance => {
      return {
        ...balance,
        amount: this._commonData.subunitsToUnits(balance.amount_cents, balance.currency),
      };
    }),
  );

  constructor(
    public user: UserService,
    public launcher: GamesLauncherService,
    public groups: GroupsService,
    public motivateWithdrawal: MotivateWithdrawalService,
    public breakpoints: BreakpointsDetectorService,
    private _gtm: GoogleTagManagerService,
    private _ws: WebSocketService,
    private _commonData: CommonDataService
  ) { }

  ngOnInit() {
    this.checkIconImg();
  }

  checkIconImg() {
    if (this.groups.loyaltyGroup === UserGroup.NINJA) {
      this.userImg = `${this.baseImg}user-ninja-redesign.png`;
    } else if (this.groups.loyaltyGroup === UserGroup.SAMURAI) {
      this.userImg = `${this.baseImg}user-samurai-redesign.png`;
    }
  }

  onDepositClick(isDepositClick = true) {
    if (isDepositClick) {
      this._gtm.depositHeaderButton('deposit_header', 'header', this.user.info.id);
    }
  }

}
