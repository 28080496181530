<div class="social-auth">
  @if (isSocialEnabled) {
    <div class="social-auth__or">{{ 't.or' | translate }}</div>
  }

  <div class="social-auth__list">
    @if (telegram$ | async) {
      <div
        (click)="onAuth(socialAuthData[socialAuthType.TELEGRAM].apiUrl, socialAuthType.TELEGRAM)"
        class="social-auth__item social-auth__telegram">
        <img src="/assets/img/auth/telegram.svg" alt="">
      </div>
    }

    @if (google$ | async) {
      <div (click)="onAuth(socialAuthData[socialAuthType.GOOGLE].apiUrl, socialAuthType.GOOGLE)"
        class="social-auth__item social-auth__google">
        <img src="/assets/img/auth/google.svg" alt="google">
      </div>
    }
  </div>

</div>
