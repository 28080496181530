import { Router } from '@angular/router';
import { Injectable, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '../../../express.tokens';
import { isPlatformServer } from '@angular/common';

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  MOVED_PERMANENTLY = 301,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505
}

export enum HttpStatusText {
  FORBIDDEN = 'Forbidden',
  NOT_FOUND = 'Not found',
  MOVED_PERMANENTLY = 'Redirect',
  OK = 'OK'
}

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {

  constructor(
    @Optional() @Inject(RESPONSE) private _response: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private _router: Router
  ) {

  }

  /**
   * Set response status
   * @param code
   * @param message
   */
  public setStatus(code: number, message: string): void {
    if (isPlatformServer(this.platformId) && this._response) {
      this._response.statusCode = code;
      this._response.statusMessage = message;
    }
  }

  /**
   * Handle response status from CMS and set response status from Universal
   * @param status
   * @param redirectUrl
   */
  public handleResponseStatus(status: any, redirectUrl = '/') {
    if (status === HttpStatus.MOVED_PERMANENTLY) {
      this.setStatus(status, HttpStatusText.MOVED_PERMANENTLY);
      this._router.navigateByUrl(redirectUrl, {
        replaceUrl: true
      });
    }
    if (status === HttpStatus.NOT_FOUND) {
      this.setStatus(status, HttpStatusText.NOT_FOUND);
      this._router.navigateByUrl('/404', {
        replaceUrl: true
      });
    }
    if (status === HttpStatus.FORBIDDEN) {
      this.setStatus(status, HttpStatusText.FORBIDDEN);
      this._router.navigateByUrl('/403', {
        replaceUrl: true
      });
    }
  }

}
